<div>
    <div class="flex flex-col" *ngIf="deploy$ | async as deploy">
        <div class="bg-gray-50 overflow-hidden border border-gray-200 rounded-lg з-">
            <table class="table-fixed divide-y divide-gray-200">
                <thead class="bg-gray-50">
                <tr>
                    <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        ID
                    </th>
                    <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Redeems
                    </th>
                    <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Players
                    </th>
                    <th *ngIf="!isStateInitial(deploy.state)" scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Applied Players
                    </th>
                    <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Query
                    </th>
                    <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        State
                    </th>
                    <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Is Active Time
                    </th>
                    <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Created By
                    </th>
                    <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Created At
                    </th>
                    <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Updated At
                    </th>
                </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                <tr>
                    <td class="px-6 py-2.5 text-sm text-gray-900">
                        {{deploy.id}}
                    </td>
                    <td class="px-6 py-2.5 text-sm text-gray-900">
                        {{deploy.redeems ? deploy.redeems.length : 0}}
                    </td>
                    <td class="px-6 py-2.5 text-sm text-gray-900">
                        <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-{{getBackgroundColorForPlayersCount(deploy.players)}}-100 text-{{getBackgroundColorForPlayersCount(deploy.players)}}-800">{{deploy.players === null ? 'Усі гравці' : deploy.players.length}}</span>
                    </td>
                    <td *ngIf="!isStateInitial(deploy.state)" class="px-6 py-2.5 text-sm text-gray-900">
                        {{deploy.appliedPlayersCount}}
                    </td>
                    <td class="px-6 py-2.5 text-sm text-gray-900">
                        <div *ngIf="deploy.query !== null" [cdkCopyToClipboard]="copyQuery(deploy)" (cdkCopyToClipboardCopied)="onCopiedQuery($event)"  class="cursor-pointer hover:text-blue-500">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                            </svg>
                        </div>
                        {{deploy.query === null ? '-' : ''}}
                    </td>
                    <td class="max-w-xs px-6 py-2.5 text-sm text-gray-900">
                        <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-{{getBackgroundColorForDeployState(deploy.state)}}-100 text-{{getBackgroundColorForDeployState(deploy.state)}}-800">{{deploy.state}}</span>
                    </td>
                    <td class="px-6 py-2.5 text-sm text-gray-900">
                        <span *ngIf="deploy.state === 'applied'" class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-{{deploy.isActiveTimeNow ? 'green' : 'yellow'}}-100 text-{{deploy.isActiveTimeNow ? 'green' : 'yellow'}}-800">{{deploy.isActiveTimeNow ? 'active' : 'pending'}}</span>
                    </td>
                    <td class="px-6 py-2.5 text-sm text-gray-900">
                        {{deploy.username}}
                    </td>
                    <td class="px-6 py-2.5 text-sm text-gray-900">
                        {{deploy.createdAt}}
                    </td>
                    <td class="px-6 py-2.5 text-sm text-gray-900">
                        {{deploy.updatedAt}}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="bg-white border border-gray-200 rounded-lg p-4 mt-10">
            <form [formGroup]="deployDetailsForm" (submit)="changeDeployDetails(deploy)">
                <div class="flex flex-row text-gray-500 px-4 pb-4">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                        <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-xs pl-2 pt-0.5 mr-8">Редагування деталей деплоя</span>
                </div>
                    <table class="w-full table-fixed text-sm text-gray-500">
                        <thead>
                        <tr>
                            <th class="w-1/2" style="display: none"></th>
                            <th class="w-1/2" style="display: none"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td class="border-t border-gray-200 additional-params pl-4">Name</td>
                            <td class="border-t border-gray-200 additional-params bg-gray-50" *ngIf="!isEditModeEnabledDeployDetails">
                                {{deploy.name}}
                            </td>
                            <td class="border-t border-gray-200 {{deployDetailsForm.controls.name.valid ? 'bg-green-50': 'bg-red-50'}}" *ngIf="isEditModeEnabledDeployDetails">
                                <input formControlName="name"
                                       id="deployName"
                                       type="text"
                                       class="additional-params-input {{deployDetailsForm.controls.name.valid ? 'bg-green-50': 'bg-red-50'}} block w-full text-sm border-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                >
                            </td>
                        </tr>
                        <tr>
                            <td class="border-t border-gray-200 additional-params pl-4">Description</td>
                            <td class="border-t border-gray-200 additional-params bg-gray-50" *ngIf="!isEditModeEnabledDeployDetails">
                                {{deploy.description}}
                            </td>
                            <td class="border-t border-gray-200 {{deployDetailsForm.controls.description.valid ? 'bg-green-50': 'bg-red-50'}}" *ngIf="isEditModeEnabledDeployDetails">
                                <input formControlName="description"
                                       id="description"
                                       type="text"
                                       class="additional-params-input {{deployDetailsForm.controls.description.valid ? 'bg-green-50': 'bg-red-50'}} block w-full text-sm border-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                >
                            </td>
                        </tr>
                        <tr>
                            <td class="border-t border-gray-200 additional-params pl-4">Apply Text</td>
                            <td class="border-t border-gray-200 additional-params bg-gray-50" *ngIf="!isEditModeEnabledDeployDetails">
                                {{deploy.applyText}}
                            </td>
                            <td class="border-t border-gray-200 {{deployDetailsForm.controls.applyText.valid ? 'bg-green-50': 'bg-red-50'}}" *ngIf="isEditModeEnabledDeployDetails">
                                <input formControlName="applyText"
                                       id="applyText"
                                       type="text"
                                       class="additional-params-input {{deployDetailsForm.controls.applyText.valid ? 'bg-green-50': 'bg-red-50'}} block w-full text-sm border-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                >
                            </td>
                        </tr>
                        <tr>
                            <td class="border-t border-gray-200 additional-params pl-4">Hyper Link</td>
                            <td class="border-t border-gray-200 additional-params bg-gray-50" *ngIf="!isEditModeEnabledDeployDetails">
                                {{deploy.hyperLink}}
                            </td>
                            <td class="border-t border-gray-200 {{deployDetailsForm.controls.hyperLink.valid ? 'bg-green-50': 'bg-red-50'}}" *ngIf="isEditModeEnabledDeployDetails">
                                <input formControlName="hyperLink"
                                       id="hyperLink"
                                       type="text"
                                       class="additional-params-input {{deployDetailsForm.controls.hyperLink.valid ? 'bg-green-50': 'bg-red-50'}} block w-full text-sm border-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                >
                            </td>
                        </tr>
                        <tr>
                            <td class="border-t border-b border-gray-200 additional-params pl-4">Template ID</td>
                            <td class="border-t border-b border-gray-200 additional-params bg-gray-50" *ngIf="!isEditModeEnabledDeployDetails">
                                {{deploy.templateId}}
                            </td>
                            <td class="border-t border-b border-gray-200 {{deployDetailsForm.valid ? 'bg-green-50': ''}}" *ngIf="isEditModeEnabledDeployDetails">
                                <select formControlName="templateId" id="templateId" class="block w-full {{deployDetailsForm.valid ? 'bg-green-50': ''}} text-sm text-gray-500 border-none focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                                  <option selected value="{{this.deployDetailsForm.controls.templateId.value}}">{{this.deployDetailsForm.controls.templateId.value}}</option>
                                  <option *ngFor="let templateId of templatesIds">{{ templateId }}</option>
                              </select>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <button class="mt-4 ml-4 py-2 px-4 bg-blue-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-blue-500" *ngIf="!isEditModeEnabledDeployDetails && isApplicable(deploy.state)" (click)="isEditModeEnabledDeployDetails=!isEditModeEnabledDeployDetails">Змінити</button>
                    <button class="mt-4 ml-4 py-2 px-4 bg-green-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-green-500" *ngIf="isEditModeEnabledDeployDetails && isApplicable(deploy.state) && deployDetailsForm.valid && isChangedDeployDetailsForm(deploy)">Зберегти</button>
                    <button class="mt-4 ml-4 py-2 px-4 bg-red-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-red-500" *ngIf="isEditModeEnabledDeployDetails" (click)="isEditModeEnabledDeployDetails=!isEditModeEnabledDeployDetails">Відмінити</button>
            </form>
        </div>
        <div class="bg-white border border-gray-200 rounded-lg p-4 mt-10">
            <form [formGroup]="deployActiveTimeForm" (submit)="changeDeployActiveTime(deploy)" (input)="isValidActiveTime()">
                <div class="grid grid-cols-2">
                  <div class="flex flex-row text-gray-500 px-4 pb-4">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                          <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd" />
                      </svg>
                      <p class="text-xs pl-2 pt-0.5">Редагування періоду роботи деплоя</p>
                  </div>
                  <div class="p-3 text-sm text-yellow-500">{{currentDatetimeUtc | date: "yyyy-MM-dd HH:mm:ss" : 'UTC' }} - Час серверу UTC±0:00</div>
                </div>
                <div>
                    <table class="w-full table-fixed text-sm text-gray-500">
                        <thead>
                        <tr>
                            <th class="w-1/2" style="display: none"></th>
                            <th class="w-1/2" style="display: none"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td class="border-t border-gray-200 additional-params pl-4">Активно від</td>
                            <td class="border-t border-gray-200 additional-params bg-gray-50" *ngIf="!isEditModeEnabledDeployActiveTime">
                                {{deploy.activeTimeFrom | date: "yyyy-MM-dd HH:mm:ss" }}
                            </td>
                            <td class="border-t border-gray-200 {{deployActiveTimeForm.controls.activeTimeFrom.valid ? 'bg-green-50': 'bg-red-50'}}" *ngIf="isEditModeEnabledDeployActiveTime">
                                <input formControlName="activeTimeFrom"
                                       id='activeTimeFrom'
                                       name='activeTimeFrom'
                                       type='datetime-local'
                                       value='{{deploy.activeTimeFrom | date: "yyyy-MM-ddTHH:mm:ss"}}'
                                       min='2022-01-01T00:00:01'
                                       max='2099-12-31T00:00:01'
                                       step=1
                                       class="small-datetime-input {{deployActiveTimeForm.controls.activeTimeFrom.valid ? 'bg-green-50': 'bg-red-50'}} block w-full text-sm border-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                >
                            </td>
                        </tr>
                        <tr>
                            <td class="border-t border-b border-gray-200 additional-params pl-4">Активно до</td>
                            <td class="border-t border-b border-gray-200 additional-params bg-gray-50" *ngIf="!isEditModeEnabledDeployActiveTime">
                                {{deploy.activeTimeTo | date: "yyyy-MM-dd HH:mm:ss"}}
                            </td>
                            <td class="border-t border-b border-gray-200 {{deployActiveTimeForm.controls.activeTimeTo.valid ? 'bg-green-50': 'bg-red-50'}}" *ngIf="isEditModeEnabledDeployActiveTime">
                                <input formControlName="activeTimeTo"
                                       id='activeTimeTo'
                                       name='activeTimeTo'
                                       type='datetime-local'
                                       value='{{deploy.activeTimeTo | date: "yyyy-MM-ddTHH:mm:ss"}}'
                                       min='2022-01-01T00:00:01'
                                       max='2099-12-31T00:00:01'
                                       step=1
                                       class="small-datetime-input {{deployActiveTimeForm.controls.activeTimeTo.valid ? 'bg-green-50': 'bg-red-50'}} block w-full text-sm border-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                >
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <button class="mt-4 ml-4 py-2 px-4 bg-blue-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-blue-500" *ngIf="!isEditModeEnabledDeployActiveTime && isApplicable(deploy.state)" (click)="isEditModeEnabledDeployActiveTime=!isEditModeEnabledDeployActiveTime">Змінити</button>
                    <button class="mt-4 ml-4 py-2 px-4 bg-green-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-green-500" *ngIf="isEditModeEnabledDeployActiveTime && isApplicable(deploy.state) && deployActiveTimeForm.valid">Зберегти</button>
                    <button class="mt-4 ml-4 py-2 px-4 bg-red-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-red-500" *ngIf="isEditModeEnabledDeployActiveTime" (click)="isEditModeEnabledDeployActiveTime=!isEditModeEnabledDeployActiveTime">Відмінити</button>
                </div>
            </form>
        </div>
        <div *ngIf="isApplicable(deploy.state)" class="bg-white border border-gray-200 rounded-lg p-4 mt-10">
          <form [formGroup]="gameValuesForm" (submit)="addRedeemToDeploy(deploy)">
            <div class="flex flex-row text-gray-500 px-4 pb-4">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd" />
              </svg>
              <span class="text-xs pl-2 pt-0.5 mr-8">Редагування редімів</span>
            </div>
            <table class="w-full table-fixed text-sm text-gray-500">
              <thead>
              <tr>
                <th class="w-1/3" style="display: none"></th>
                <th class="w-1/3" style="display: none"></th>
                <th class="w-1/3" style="display: none"></th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td class="border-t border-b border-gray-200">
                  <ng-select
                    [clearable]=false
                    formControlName="name"
                    id="name"
                    class="custom block w-full text-sm text-gray-500 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                    <ng-option *ngFor="let name of redeemNameTypeMap | keyvalue"
                               [value]="name.key">{{name.key}}
                      <span class="text-xs text-blue-600 ml-4">{{name.value}}</span>
                    </ng-option>
                  </ng-select>
                </td>
                <td class="border-t border-b border-gray-200">
                  <input *ngIf="isSelectedItem() && inputType !== ''"
                         formControlName="value"
                         id="value"
                         type="{{inputType}}"
                         class="{{isValidValue() ? 'bg-red-100' : ''}} additional-params-input block w-full text-sm border-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                         placeholder="{{inputPlaceholder}}" [value]="this.gameValuesForm.controls['value'].value">
                </td>
                <td class="border-t border-b border-gray-200">
                  <div class="grid grid-cols-2">
                    <button *ngIf="isShowingSaveButton()" type="submit" class="py-2 bg-green-300 text-sm text-white font-semibold hover:bg-green-500">Додати</button>
                    <button *ngIf="isShowingDeclineButton()" (click)="resetForm()" class="py-2 bg-red-300 text-sm text-white font-semibold hover:bg-red-500">Відмінити</button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </form>
        </div>
        <div class="bg-white border border-gray-200 rounded-lg p-4 mt-10">
        <div class="flex flex-row text-gray-500 px-4 pb-4">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
          </svg>
          <span class="text-xs pl-2 pt-0.5">Список редімів</span>
        </div>
        <div class="-my-2 overflow-x-auto">
          <div class="py-2 align-middle inline-block min-w-full">
            <div class="overflow-hidden">
              <table class="table-fixed divide-y divide-gray-200">
                <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    ID
                  </th>
                  <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                  <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Type ID
                  </th>
                  <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Value
                  </th>
                  <!--                                <th *ngIf="conflictedSectionsToReverting" scope="col" class="{{isAllConflictedSectionsValuesResolved() ? 'bg-green-100' : 'bg-yellow-100'}} w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">-->
                  <!--                                    Section value (Before)-->
                  <!--                                </th>-->
                  <!--                                <th *ngIf="conflictedSectionsToReverting" scope="col" class="{{isAllConflictedSectionsValuesResolved() ? 'bg-green-100' : 'bg-yellow-100'}} w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">-->
                  <!--                                    Section value (Actual)-->
                  <!--                                </th>-->
                  <th scope="col" class="w-2/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">

                  </th>
                </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                <tr *ngFor="let redeem of deploy.redeems; index as i" class="{{ getSectionsValueStatusBackgroundColor(redeem, i) }} {{ isRedeemCanBeRemoved(deploy.state) ? 'cursor-pointer' : '' }}" (click)="removeSelectedRedeem(deploy.state, i)">
                  <td class="max-w-xs px-6 py-2.5 text-sm text-gray-900">
                    <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">{{ redeem.id }}</span>
                  </td>
                  <td class="max-w-xs px-6 py-2.5 text-sm text-gray-900">
                    {{ redeem.name }}
                  </td>
                  <td class="px-6 py-2.5 text-xs text-blue-600">
                    {{ redeem.type }}
                  </td>
                  <td class="px-6 py-2.5 text-sm text-gray-900">
                    {{ redeem.value }}
                  </td>
                  <!--                                <td *ngIf="conflictedSectionsToReverting" (click)="resolveConflictedSections()" class="{{ isResolvedConflictedSections() ? 'bg-green-100' : '' }} {{ isConflictedSectionsExists() ? 'cursor-pointer' : '' }} px-6 py-2.5 text-sm text-gray-900 border-l border-gray-200">-->
                  <!--                                    {{ getConflictedSectionsValue() }}-->
                  <!--                                </td>-->
                  <!--                                <td *ngIf="conflictedSectionsToReverting" (click)="resolveConflictedSections()" class="{{ isResolvedConflictedSections() ? 'bg-green-100' : '' }} {{ isConflictedSectionsExists() ? 'cursor-pointer' : '' }} px-6 py-2.5 text-sm text-gray-900 border-l border-r border-gray-200">-->
                  <!--                                    {{ getConflictedSectionsValue() }}-->
                  <!--                                </td>-->
                  <td class="px-6 py-2 text-sm text-red-800">
                    {{ getRedeemStatus(redeem, i) }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
        <div class="bg-white border border-gray-200 rounded-lg px-4 mt-10">
            <div class="flex flex-row">
                <div class="w-1/2">
                    <div class="flex flex-row text-gray-500 py-4 pl-3 border-b border-gray-200">
                        <p class="text-xs pt-0.5">Операції деплоя</p>
                    </div>
                    <div class="py-4">
                        <button class="ml-4 py-2 px-4 bg-green-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-green-500" *ngIf="isApplicable(deploy.state) && deployDetailsForm.valid && removedRedeemsFromDeploy.length === 0 && !redeemsContainsDuplicates() && !isEditModeEnabledDeployDetails" (click)="applyDeploy(deploy)">Задеплоїти</button>
                        <button class="ml-4 py-2 px-4 bg-red-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-red-500" *ngIf="isDeclinable(deploy.state)" (click)="declineDeploy(deploy)">Відмінити</button>
                        <button class="ml-4 py-2 px-4 bg-red-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-red-500" *ngIf="isDeletable(deploy.state)" (click)="deleteDeploy(deploy)">Видалити</button>
<!--                            <button class="ml-4 py-2 px-4 bg-blue-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-blue-500" *ngIf="isEditable(deploy.state)" (click)="applyDeploy(deploy)">Редагувати</button>-->

                        <!--                        <button class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-1.5 bg-green-300 text-base font-medium text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm">Задеплоїти</button>-->
                        <!--                        <button *ngIf="isDeclinable(deploy.state)" class="py-2 ml-4 px-4 bg-red-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-red-500">Отменить</button>-->
                        <!--                        <button *ngIf="isRevertable(deploy.state) && isAllConflictedSectionsValuesResolved()" (click)="revertDeploy(deploy)" class="py-2 ml-4 px-4 bg-red-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-red-500">Откатить</button>-->
                        <!--                        <button *ngIf="isRevertable(deploy.state)" (click)="revertDeploy(deploy)" class="py-2 ml-4 px-4 bg-red-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-red-500">Откатить</button>-->
                        <button *ngIf="removedRedeemsFromDeploy.length > 0" (click)="removeSelectedRedeemsFromDeploy(deploy)" class="py-2 ml-4 px-4 bg-red-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-red-500">Видалити вибрані редіми</button>
                        <div class="mt-2 flex flex-row pl-2" *ngIf="redeemsContainsDuplicates() && removedRedeemsFromDeploy.length === 0">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mt-0.5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                            </svg>
                            <span class="text-xs pl-2 pt-1 text-yellow-500">Деплой неможливий при наявності дублікатів редімів</span>
                        </div>
                    </div>
                </div>
                <div class="w-1/2">
                    <div class="flex flex-row text-gray-500 py-4 pl-3  border-b border-gray-200">
                        <p class="text-xs pt-0.5">Тестові гравці</p>
                    </div>
                    <form [formGroup]="addTestPlayerForm" (submit)="addTestPlayerToDeploy(deploy)" class="pb-4">
                        <table class="w-full table-fixed text-sm text-gray-500">
                            <thead>
                            <tr>
                                <th class="w-1/2" style="display: none"></th>
                                <th class="w-1/2" style="display: none"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td class="border-b border-gray-200 {{this.addTestPlayerForm.dirty ? 'border-r' : ''}}">
                                    <input formControlName="playerId"
                                           id="addPlayer"
                                           type="text"
                                           class="{{this.addTestPlayerForm.invalid && this.addTestPlayerForm.dirty ? 'bg-red-100' : ''}} additional-params block w-full text-sm border-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                           placeholder="Додати гравця по id" [value]="this.addTestPlayerForm.controls.playerId.value">
                                </td>
                                <td class="border-b border-gray-200">
                                    <div class="grid grid-cols-2">
                                        <button *ngIf="this.addTestPlayerForm.dirty" (click)="this.addTestPlayerForm.reset()" class="additional-params bg-red-300 text-sm text-white font-semibold hover:bg-red-500">Відмінити</button>
                                        <button *ngIf="this.addTestPlayerForm.valid && this.addTestPlayerForm.dirty" type="submit" class="additional-params bg-green-300 text-sm text-white font-semibold hover:bg-green-500">Додати</button>
                                    </div>
                                </td>
                            </tr>
                            <tr class="border-b border-gray-200 bg-gray-50" *ngIf="deploy.testPlayers == null">
                                <td class="border-t border-gray-200 additional-params" style="padding-left: 0.750rem">Немає тестових гравців</td>
                                <td class="border-t border-gray-200 additional-params bg-gray-50"></td>
                            </tr>
                            <tr *ngFor="let testPlayer of deploy.testPlayers ?? [];" class="border-b border-gray-200 bg-gray-50">
                                <td class="border-t border-r border-gray-200 additional-params" style="padding-left: 0.750rem">
                                    <a routerLink="/profile/{{testPlayer.playerId}}" routerLinkActive="active" class="text-blue-500 hover:text-blue-700">{{testPlayer.playerId}}</a>
                                </td>
                                <td class="border-t border-gray-200 additional-params text-sm text-center" *ngIf="!testPlayer.isApplied">Деплой не підтверджений</td>
                                <td class="border-gray-200 grid grid-cols-2" *ngIf="testPlayer.isApplied">
                                    <div class="additional-params text-sm text-center">Деплой застосовано</div>
                                    <button (click)="retestPlayer(deploy, testPlayer)" class="bg-green-300 additional-params text-sm text-white font-semibold hover:bg-green-500">Відправити заново</button>
                                </td>
                            </tr>
                          </tbody>
                      </table>
                    </form>
                    <div *ngIf="deploy.appliedPlayers.length > 0">
                      <div class="flex flex-row text-gray-500 py-4 pl-3  border-b border-gray-200">
                        <p *ngIf="!deploy.players" class="text-xs pt-0.5">Гравці які отримали редім: {{deploy.appliedPlayersCount}}</p>
                        <p *ngIf="deploy.players" class="text-xs pt-0.5">Гравці які мають отримати редім: {{deploy.appliedPlayersCount}} / {{deploy.players.length}}</p>
                      </div>
                      <div class="max-h-80 overflow-y-auto">
                        <table class="w-full table-fixed text-sm text-gray-500">
                          <thead>
                          <tr>
                            <th class="w-1/2" style="display: none"></th>
                            <th class="w-1/2" style="display: none"></th>
                          </tr>
                          </thead>
                          <tbody *ngIf="!deploy.players">
                              <tr *ngFor="let appliedPlayer of deploy.appliedPlayers ?? [];" class="border-b border-gray-200 bg-gray-50">
                                <td class="border-t border-r border-gray-200 additional-params" style="padding-left: 0.750rem">
                                  <a routerLink="/profile/{{appliedPlayer}}" routerLinkActive="active" class="text-blue-500 hover:text-blue-700">{{appliedPlayer}}</a>
                                </td>
                                <td class="border-gray-200 align-middle py-1.5 px-2.5">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6 text-green-500">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                  </svg>
                                </td>
                              </tr>
                          </tbody>
                          <tbody *ngIf="deploy.players">
                            <tr *ngFor="let playerId of deploy.appliedPlayers ?? [];" class="border-b border-gray-200 bg-gray-50">
                              <td class="border-t border-r border-gray-200 additional-params" style="padding-left: 0.750rem">
                                <a routerLink="/profile/{{playerId}}" routerLinkActive="active" class="text-blue-500 hover:text-blue-700">{{playerId}}</a>
                              </td>
                              <td class="border-gray-200 align-middle py-1.5 px-2.5">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6 text-green-500">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>
                              </td>
                            </tr>
                            <tr *ngFor="let playerId of getNotAppliedPlayersListByDeploy(deploy);" class="border-b border-gray-200 bg-gray-50">
                              <td class="border-t border-r border-gray-200 additional-params" style="padding-left: 0.750rem">
                                <a routerLink="/profile/{{playerId}}" routerLinkActive="active" class="text-blue-500 hover:text-blue-700">{{playerId}}</a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
