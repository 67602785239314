import {Save} from "../save-list/save";

export interface Profile {
  saves: Save[]           // TODO Если поля поменяются то вынести рядом новую сущность сейва
}

export enum OperationType {
  ADD = 'add',
  SET = 'set'
}

export enum ValueType {
  BINARY = 'binary',
  BOOL = 'bool',
  DATETIME = 'datetime',
  FLOAT = 'float',
  INT64 = 'int64',
  INT = 'int',
  STRING = 'string',
  NULL = 'null'
}

