import {Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormGroupDirective,
  NG_VALUE_ACCESSOR
} from "@angular/forms";

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadComponent,
      multi: true
    }
  ],
  viewProviders:[{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class FileUploadComponent implements ControlValueAccessor, OnInit, OnChanges {
  //https://netbasal.com/how-to-implement-file-uploading-in-angular-reactive-forms-89a3fffa1a03

  @Input() isValid: boolean|null = null;

  public parentForm: AbstractControl|null = null;
  private _file: File | null = null;

  @HostListener('change', ['$event.target.files']) emitFiles( event: FileList ) {
    const file = event && event.item(0);
    this._file = file;
    this._onChange(file);
    this._onTouched();
  }

  constructor(
    private host: ElementRef<HTMLInputElement>,
    private controlContainer: ControlContainer
  ) {}

  writeValue( value: File | null ) {
    // console.log('file-input:', (<HTMLInputElement>document.getElementById('uploadFile')).value);
    // clear file input
    this.host.nativeElement.value = '';
    (<HTMLInputElement>document.getElementById('uploadFile')).value = "";
    this._file = null;
    this.isValid = null;
  }

  private _onChange = (value: File | null) => undefined;
  registerOnChange(fn: (value: File | null) => undefined): void {
    this._onChange = fn;
  }

  private _onTouched = () => undefined;
  registerOnTouched(fn: () => undefined): void {
    this._onTouched = fn;
  }

  get file(): File | null {
    return this._file;
  }

  getColor(): string {
    switch (this.isValid) {
      case null: return 'blue'
      case true: return 'green'
      case false: return 'red'
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isValid.currentValue === null && changes.isValid.previousValue !== null) {
      this.writeValue(null);
    }
  }

  ngOnInit() {
    // this.parentForm = this.controlContainer.control;
    // console.log('value: ', this.parentForm?.value);
  }
}
