import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs";
import {Dashboard} from "./dashboard";
import {NotificationsService} from "angular2-notifications";
import {DashboardService} from "./dashboard.service";
import {HttpErrorResponse} from "@angular/common/http";
import {PageTypeEnum} from "../common/page";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  dashboard$: Observable<Dashboard>;

  constructor(
    private dashboardService: DashboardService,
    private notificationsService: NotificationsService,
  ) {
    this.dashboard$ = this.dashboardService.dashboard$;
  }

  ngOnInit(): void {
    this.fetchDashboardData()
  }

  fetchDashboardData(): void {
    this.dashboardService.getDashboardData().subscribe(
      (data) => {
        if (data.body !== null) {
          this.dashboardService.setDashboard(data.body);
        }
      }, (data) => {
        if (data instanceof HttpErrorResponse) {
          if (data.status < 500) {
            this.notificationsService.error('Помилка!', data.error, {
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            });
          } else {
            this.notificationsService.error('Помилка!', data.error.detail, {
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            });
          }
        } else {
          this.notificationsService.error('Помилка!', "Невдалося отримати загальні дані", {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
          });
        }
      });
  }
}
