import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {NotificationsService} from "angular2-notifications";
import {cloneDeep} from "lodash";
import {Deploy} from "./deploy";
import {DeployListModalService} from "./deploy-list-modal.service";
import {SaveListService} from "../save-list/save-list.service";
import {QueryBuilderService} from "../query-builder/query-builder.service";

@Component({
  selector: 'app-deploy-list-modal',
  templateUrl: './deploy-list-modal.component.html',
  styleUrls: ['./deploy-list-modal.component.scss']
})
export class DeployListModalComponent implements OnInit {

  deploys: Deploy[] = [];

  isShowingDeployModal = true;
  deploysCopy: Deploy[] = [];
  newRedeems: any;
  selectedDeploy: Deploy|null = null;
  isShowingTextInputForNewDeploy: boolean = false;

  newDeployForm = new FormGroup({
    'name': new FormControl(null, [Validators.required, Validators.pattern('[\\s\\dа-яёА-ЯЁґҐєЄіІїЇa-zA-Z._-]*')]),
  });

  constructor(
    private deployListModalService: DeployListModalService,
    private saveListService: SaveListService,
    private queryBuilderService: QueryBuilderService,
    private notificationsService: NotificationsService
  ) { }

  ngOnInit(): void {
    this.deploysCopy = cloneDeep(this.deploys);
  }

  addDeployListModal(deployModalList: Deploy[]) {
    this.deploys = deployModalList;
  }

  addRedeemsToExistedDeploy(deployId: number): any {
    const selectedDeploy = this.deploys.find(deploy => deploy.id === deployId);

    if (!selectedDeploy) {
      return;
    }

    this.selectedDeploy = selectedDeploy;
    this.hideTextInputForNewDeploy();
  }

  isInvalidDeployName(): boolean {
    return this.newDeployForm.controls['name'].dirty && this.newDeployForm.controls['name'].invalid;
  }

  closeModal() {
    this.isShowingDeployModal = false;
  }

  showTextInputForNewDeploy() {
    this.selectedDeploy = null;
    this.isShowingTextInputForNewDeploy = true;
  }

  hideTextInputForNewDeploy() {
    this.isShowingTextInputForNewDeploy = false;

    if (this.isInvalidDeployName()) {
      this.newDeployForm.controls['name'].reset();
    }
  }

  createDeployWithSelectedPlayers() {
    console.log("Query Builder: ", this.queryBuilderService.queryBuilderQuery)
    console.log("Total: ", this.saveListService.total)

    if (!this.newDeployForm.invalid && !this.selectedDeploy) {
      let players: string[] | null;

      if (this.saveListService.csvPlayerIds.length > 0) {
        if (this.saveListService.total < this.saveListService.csvPlayerIds.length) {
          console.warn("CSV contains player Ids which doesn't exists")
        }

        players = this.saveListService.isAllPlayersSelected
          ? this.saveListService.csvPlayerIds
          : this.saveListService.getSelectedPlayers();
      } else {
        players = this.saveListService.isAllPlayersSelected
          ? null
          : this.saveListService.getSelectedPlayers();
      }

      this.deployListModalService.createDeploy({
        'name': this.newDeployForm.controls['name'].value,
        'query': this.queryBuilderService.queryBuilderQuery,
        'players': players
      }).subscribe(
        (data) => {
          if (data.status === 201 && data.body !== null) {
            this.closeModal();
            this.notificationsService.success('Успіх!', 'Деплой створено', {
              timeOut: 1500,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            });
          }
        }
      );
    }

    if (this.selectedDeploy) {
      this.deployListModalService.appendDeployRedeems(this.selectedDeploy.id, {
        'redeems': [this.newRedeems]
      }).subscribe(
        (data) => {
          if (data.status === 200) {
            this.closeModal();
            this.notificationsService.success('Успіх!', 'Замінено у вибраному деплої', {
              timeOut: 1500,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            });
          }
        }
      );
    }
  }

  getBackgroundColorForNewDeployInput(): string {
    if (this.isInvalidDeployName() && !this.selectedDeploy) {
      return 'bg-red-50';
    }

    if (this.isShowingTextInputForNewDeploy) {
      return '';
    }

    return 'hover:bg-green-50'
  }
}
