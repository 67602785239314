import {Injectable} from '@angular/core';
import {Set} from "../set/set";
import {forIn} from "lodash";
import {RenovationStatus} from "../renovation/renovation";
import {PaginationService} from "../../../common/pagination-service";

@Injectable({
  providedIn: 'root'
})
export class SetListService extends PaginationService {

  isActiveSetList: boolean = false;
  isAllSetsSelected: boolean = false;

  selectedSets: Set[] = []
  pendingSets: Set[] = []
  savedSets: Set[] = []
  confirmedSets: Set[] = []
  notSelectedSetsWarning: string[] = [];

  addPendingSet(set: Set) {
    this.pendingSets.push(set)
    this.isActiveSetList = true
  }

  getSetById(setId: string): Set {
    return <Set>this.pendingSets.find(set => set.id === setId)
      ?? this.savedSets.find(set => set.id === setId)
      ?? this.confirmedSets.find(set => set.id === setId)
  }

  selectSet(set: Set): void {
    if (this.isSetSelected(set)) {
      this.deselectSet(set)
      return
    }

    if (this.notSelectedSetsWarning.includes(set.id)) {
      this.notSelectedSetsWarning = this.notSelectedSetsWarning.filter((id) => id !== set.id);
    }

    console.log("SSSS", this.notSelectedSetsWarning);
    this.selectedSets.push(set)
  }

  isSetSelected(set: Set): boolean {
    return this.selectedSets.includes(set)
  }

  deselectSet(set: Set): void {
    this.selectedSets = this.selectedSets.filter((element) => element !== set);
  }

  selectAllSets(): void {
    if (this.isAllSetsSelected) {
      this.deselectAllSets()

      return
    }

    this.isAllSetsSelected = true
    this.pendingSets.forEach((element) => this.selectedSets.push(element))
  }

  deselectAllSets(): void {
    this.isAllSetsSelected = false
    this.selectedSets = []
  }

  updateRenovationStatusInSelectedSets(status: RenovationStatus) {
    this.selectedSets.forEach(set => {
      set.status = status;
    });
  }
}
