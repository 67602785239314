<div [formGroup]="chunkForm" (submit)="save()">
  <div>
    <h3 class="font-medium text-gray-900 p-2.5">Чанк</h3>
    <div>
      <div class="block w-full">
        <input
          type="text"
          formControlName="uniqueId"
          placeholder="Унікальний ID"
          class="input-text block w-full text-sm text-gray-500 border-none {{getInputStyles(this.chunkForm.controls.uniqueId)}} focus:ring focus:ring-opacity-50">
      </div>
      <div class="block w-full mb-2">
        <input
          type="number"
          formControlName="expForAccessibility"
          placeholder="Рівень відкриття / Required level"
          class="input-text block w-full text-sm text-gray-500 border-none {{getInputStyles(this.chunkForm.controls.expForAccessibility)}} focus:ring focus:ring-opacity-50">
      </div>
    </div>
  </div>
  <div class="flex">
    <button *ngIf="!chunkService.editableChunk" type="submit" (click)="save()" class="flex-1 mt-2 py-2 px-4 text-sm font-semibold rounded-lg {{this.chunkForm.valid ? 'bg-yellow-100 text-yellow-700 hover:bg-yellow-200' : 'bg-gray-300 text-white'}}">В очікування</button>
    <button *ngIf="chunkService.editableChunk" type="submit" (click)="update()" class="flex-1 mt-2 py-2 px-4 text-sm font-semibold rounded-lg {{this.chunkForm.valid ? 'bg-yellow-100 text-yellow-700 hover:bg-yellow-200' : 'bg-gray-300 text-white'}}">Змінити</button>
    <button type="button" (click)="reset()" class="flex-1 mt-2 ml-3 py-2 px-4 text-sm text-white font-semibold rounded-lg {{this.chunkForm.dirty ? 'bg-red-300 hover:bg-red-400' : 'bg-gray-300'}}">Відмінити</button>
  </div>
</div>
