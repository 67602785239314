import {Injectable} from '@angular/core';
import {Skin} from "../skin/skin";
import {RenovationStatus} from "../renovation/renovation";
import {PaginationService} from "../../../common/pagination-service";

@Injectable({
  providedIn: 'root'
})
export class SkinListService extends PaginationService {

  isActiveSkinList: boolean = false
  isAllSkinsSelected: boolean = false;

  selectedSkins: Skin[] = []
  pendingSkins: Skin[] = []
  savedSkins: Skin[] = []
  confirmedSkins: Skin[] = []

  addPendingSkin(skin: Skin) {
    this.pendingSkins.push(skin)
    this.isActiveSkinList = true;
  }

  selectSkin(skin: Skin): void {
    if (this.isSkinSelected(skin)) {
      this.deselectSkin(skin);
      return;
    }

    this.selectedSkins.push(skin);
  }

  isSkinSelected(skin: Skin): boolean {
    return this.selectedSkins.includes(skin);
  }

  deselectSkin(skin: Skin): void {
    this.selectedSkins = this.selectedSkins.filter((sk) => sk !== skin);
  }

  selectAllSkins(): void {
    if (this.isAllSkinsSelected) {
      this.deselectAllSkins();
      return;
    }

    this.isAllSkinsSelected = true;
    this.pendingSkins.forEach((sk) => this.selectedSkins.push(sk));
  }

  deselectAllSkins(): void {
    this.isAllSkinsSelected = false;
    this.selectedSkins = [];
  }

  updateRenovationStatusInSelectedSkins(status: RenovationStatus) {
    this.selectedSkins.forEach(skin => {
      skin.status = status;
    });
  }
}
