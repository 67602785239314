<!--sectionsKeyNamesList: sectionsKeyNamesList$ | async-->

<div *ngIf="
      {
        player: player
      } as data">
    <div class="flex flex-col" *ngIf="data.player as player">
        <div class="bg-white border border-gray-200 rounded-lg p-4">
            <div class="flex flex-col">
                <div class="border-b border-gray-200">
                    <div class="grid grid-cols-8 gap-4 px-4 pb-4">
                        <div>
                            <div class="">User ID</div>
                            <div class="has-tooltip">
                                <span class='tooltip-profile'>{{ player.id}}</span>
                                <div class="text-gray-500 text-xs truncate">{{ player.id}}</div>
                            </div>
                        </div>
                        <div>
                            <div class="{{getPlayerStatusTitlePaddingLeft(player.status)}}">Status</div>
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-{{getPlayerStatusColor(player.status)}}-100 text-{{getPlayerStatusColor(player.status)}}-800">{{player.status}}</span>
                        </div>
                        <div>
                            <div class="">Username</div>
                            <div class="has-tooltip">
                                <span class='tooltip-profile'>{{ player.username}}</span>
                                <div class="text-gray-500 text-xs truncate">{{ player.username}}</div>
                            </div>
                        </div>
                        <div>
                            <div class="">Email</div>
                            <div class="has-tooltip">
                                <span class='tooltip-profile'>{{ player.email}}</span>
                                <div class="text-gray-500 text-xs truncate">{{ player.email}}</div>
                            </div>
                        </div>
                        <div>
                            <div class="">First Seen</div>
                            <div class="text-gray-500 text-xs">{{ player.firstSeen}}</div>
                        </div>
                        <div>
                            <div class="">Last Seen</div>
                            <div class="text-gray-500 text-xs">{{ player.lastSeen}}</div>
                        </div>
                        <div>
                            <div class="">Usage Days</div>
                            <div class="text-gray-500 text-xs">{{ player.usageDays}}</div>
                        </div>
                        <div>
                            <div class="">Usage Time</div>
                            <div class="text-gray-500 text-xs">{{ player.usageTime}}</div>
                        </div>
                    </div>
                </div>
                <div class="border-b border-gray-200">
                    <div class="grid grid-cols-8 gap-4 p-4">
                        <div>
                            <div class="">App Name</div>
                            <div class="text-gray-500 text-xs">Victorian Quest</div>
                        </div>
                        <div>
                            <div class="">Max Level</div>
                            <div class="text-gray-500 text-xs">9000</div>
                        </div>
                        <div>
                            <div class="">Platform</div>
                            <div class="text-gray-500 text-xs">{{ player.gameSessions[0]?.platform }}</div>
                        </div>
                    </div>
                </div>
                <div class="border-b border-gray-200">
                    <div class="grid grid-cols-8 gap-4 p-4">
                        <div>
                            <div class="">Country</div>
                            <div class="text-gray-500 text-xs">{{ player.gameSessions[0]?.countryCode }}</div>
                        </div>
                        <div>
                            <div class="">Language</div>
                            <div class="text-gray-500 text-xs">{{ player.gameSessions[0]?.languageCode }}</div>
                        </div>
                        <div>
                            <div class="">Connection Type</div>
                            <div class="text-gray-500 text-xs">{{ player.gameSessions[0]?.connectionType}}</div>
                        </div>
                    </div>
                </div>
                <div class="border-b border-gray-200">
                    <div class="grid grid-cols-8 gap-4 p-4">
                        <div>
                            <div class="">Device Type</div>
                            <div class="text-gray-500 text-xs">{{ player.gameSessions[0]?.deviceName }}</div>
                        </div>
                        <div>
                            <div class="">OS Version</div>
                            <div class="text-gray-500 text-xs">{{ player.gameSessions[0]?.osVersion }}</div>
                        </div>
                        <div>
                            <div class="">FW Version</div>
                            <div class="text-gray-500 text-xs">{{ player.gameSessions[0]?.fwVersion }}</div>
                        </div>
                    </div>
                </div>

                <div class="border-b border-gray-200">
                    <div class="grid grid-cols-8 gap-4 p-4">
                        <div>
                            <div class="">Payment Profile</div>
                            <div class="text-gray-500 text-xs"> - </div>
                        </div>
                        <div>
                            <div class="">Total Spent</div>
                            <div class="text-gray-500 text-xs"> - </div>
                        </div>
                        <div>
                            <div class="">Purchases</div>
                            <div class="text-gray-500 text-xs"> - </div>
                        </div>
                        <div>
                            <div class="">Median Purchase</div>
                            <div class="text-gray-500 text-xs"> - </div>
                        </div>
                        <div>
                            <div class="">Last Purchase</div>
                            <div class="text-gray-500 text-xs"> - </div>
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="grid grid-cols-8 gap-4 px-4 pt-4">
                        <div>
                            <div class="">Avatar</div>
                            <div class="text-gray-500 text-xs"> jpg </div>
                        </div>
                        <div>
                            <div class="">Nickname</div>
                            <div class="text-gray-500 text-xs"> - </div>
                        </div>
                        <div>
                            <div class="">Sex</div>
                            <div class="text-gray-500 text-xs">{{ player?.sex }}</div>
                        </div>
                        <div>
                            <div class="">Age</div>
                            <div class="text-gray-500 text-xs">{{ player?.age }}</div>
                        </div>
                    </div>
                </div>
                <!--            <div class="grid grid-cols-2">-->
                <!--                <div>-->
                <!--                    <div class="flex flex-row text-gray-500 hover:text-blue-500 p-4">-->
                <!--                        <svg *ngIf="!isShowingAdditionalProfileParams" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">-->
                <!--                            <path fill-rule="evenodd" d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z" clip-rule="evenodd" />-->
                <!--                        </svg>-->
                <!--                        <svg *ngIf="isShowingAdditionalProfileParams" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">-->
                <!--                            <path fill-rule="evenodd" d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />-->
                <!--                        </svg>-->
                <!--                        <button class="text-xs pl-2 pt-0.5" (click)="isShowingAdditionalProfileParams=!isShowingAdditionalProfileParams">Дополнительные параметры профиля</button>-->
                <!--                    </div>-->
                <!--                    <div *ngIf="isShowingAdditionalProfileParams" class="border-t border-gray-200 px-4 pb-4">-->


                <!--                    </div>-->
                <!--                </div>-->
                <!--            </div>-->
            </div>
        </div>
        <div class="bg-white border border-gray-200 rounded-lg p-4 mt-10">
            <div class="flex flex-row text-gray-500 hover:text-blue-500 px-4 pb-4">
                <svg *ngIf="!isShowingAllPlayerDevices" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <svg *ngIf="isShowingAllPlayerDevices" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                </svg>
                <button *ngIf="!isShowingAllPlayerDevices" class="text-xs pl-2 pt-0.5" (click)="isShowingAllPlayerDevices=!isShowingAllPlayerDevices">Показать все девайсы игрока</button>
                <button *ngIf="isShowingAllPlayerDevices" class="text-xs pl-2 pt-0.5" (click)="isShowingAllPlayerDevices=!isShowingAllPlayerDevices">Показать последний используемый девайс игрока</button>
            </div>
            <div class="-my-2 overflow-x-auto">
                <div class="py-2 align-middle inline-block min-w-full">
                    <div class="overflow-hidden">
                        <table class="table-fixed divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                            <tr>
                                <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Device ID
                                </th>
                                <th scope="col" class="w-1/6 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Device Type
                                </th>
                                <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Platform
                                </th>
                                <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    First Seen
                                </th>
                                <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Last Seen
                                </th>
                                <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    OS Version
                                </th>
                                <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    FW Version
                                </th>
                                <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Connection Type
                                </th>
                                <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Payment Profile
                                </th>
                            </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                            <tr *ngFor="let gameSession of player.devices; index as i" [ngClass]="{'hidden': !isShowingAllPlayerDevices && i > 0}" >
                                <td class="px-6 py-4 text-sm text-gray-900">
                                    {{ gameSession.deviceId }}
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-900">
                                    {{ gameSession.deviceName }}
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-900">
                                    {{ gameSession.platform }}
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-900">
                                    {{ gameSession.firstSeen }}
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-900">
                                    {{ gameSession.lastSeen }}
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-900">
                                    {{ gameSession.osVersion }}
                                </td>
                                <td class="max-w-xs px-6 py-4 text-sm text-gray-900">
                                    {{ gameSession.fwVersion }}
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-900">
                                    {{ gameSession.connectionType }}
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-900">
                                    -
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-white border border-gray-200 rounded-lg p-4 mt-10">
            <div class="flex flex-row text-gray-500 px-4 pb-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
                </svg>
                <span class="text-xs pl-2 pt-0.5">Активные приложения игрока</span>
            </div>
            <div class="-my-2 overflow-x-auto">
                <div class="py-2 align-middle inline-block min-w-full">
                    <div class="overflow-hidden">
                        <table class="table-fixed divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                            <tr>
                                <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    App Name
                                </th>
                                <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    First Seen
                                </th>
                                <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Last Seen
                                </th>
                                <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Language
                                </th>
                                <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Max Level
                                </th>
                                <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Profiles
                                </th>
                                <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Connection Type
                                </th>
                                <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Payment Profile
                                </th>
                            </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                            <tr *ngFor="let gameSession of player.apps; index as i">
                                <td class="px-6 py-4 text-sm text-gray-900">
                                    Victorian Quest
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-900">
                                    {{ gameSession.firstSeen }}
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-900">
                                    {{ gameSession.lastSeen }}
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-900">
                                    {{ gameSession.languageCode }}
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-900">
                                    9000
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-900">
                                    -
                                </td>
                                <td class="max-w-xs px-6 py-4 text-sm text-gray-900">
                                    {{ gameSession.connectionType }}
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-900">
                                    -
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-white border border-gray-200 rounded-lg p-4 mt-10">
            <div class="grid grid-cols-2 gap-4">
                <div>
                    <div class="flex flex-row text-gray-500 px-4 pb-4">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                            <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd" />
                        </svg>
                        <span class="text-xs pl-2 pt-0.5">Редактирование A/B групп</span>
                    </div>
                    <table class="w-full table-fixed text-sm text-gray-500">
                        <thead>
                        <tr>
                            <th class="w-1/2" style="display: none"></th>
                            <th class="w-1/2" style="display: none"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td class="border-t border-gray-200 pl-4">A/B_test_group 1</td>
                            <td class="border-t border-gray-200 additional-params bg-gray-50" *ngIf="!isEditModeEnabledForABGroups">
                                -
                            </td>
                            <td class="border-t border-gray-200" *ngIf="isEditModeEnabledForABGroups">
                                <input formControlName="1"
                                       id="1"
                                       type="text"
                                       class="additional-params-input block w-full text-sm border-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                       placeholder="Login Group">
                            </td>
                        </tr>
                        <tr>
                            <td class="border-t border-b border-gray-200 pl-4">A/B_test_group 2</td>
                            <td class="border-t border-b border-gray-200 additional-params bg-gray-50" *ngIf="!isEditModeEnabledForABGroups">
                                -
                            </td>
                            <td class="border-t border-gray-200 bg-green-50" *ngIf="isEditModeEnabledForABGroups">
                                <input formControlName="2"
                                       id="2"
                                       type="text"
                                       class="additional-params-input bg-green-50 block w-full text-sm border-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                       placeholder="18">
                            </td>
                        </tr>
                        <!--                            <tr>-->
                        <!--                                <td class="border-t border-gray-200">Communication_joined</td>-->
                        <!--                                <td class="border-t border-gray-200 additional-params bg-gray-50" *ngIf="!isEditModeEnabledForAdditionalProfileParams">297</td>-->
                        <!--                                <td class="border-t border-gray-200 bg-red-50" *ngIf="isEditModeEnabledForAdditionalProfileParams">-->
                        <!--                                    <input formControlName="3"-->
                        <!--                                           id="3"-->
                        <!--                                           type="text"-->
                        <!--                                           class="additional-params-input bg-red-50 block w-full text-sm border-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"-->
                        <!--                                           placeholder="297">-->
                        <!--                                </td>-->
                        <!--                            </tr>-->
                        <!--                            <tr>-->
                        <!--                                <td class="border-t border-gray-200">Gender</td>-->
                        <!--                                <td class="border-t border-gray-200 additional-params bg-gray-50" *ngIf="!isEditModeEnabledForAdditionalProfileParams">Female</td>-->
                        <!--                                <td class="border-t border-gray-200" *ngIf="isEditModeEnabledForAdditionalProfileParams">-->
                        <!--                                    <select formControlName="4" id="4" class="block w-full text-sm text-gray-500 border-none focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">-->
                        <!--                                        <option selected>Female</option>-->
                        <!--                                        <option>Male</option>-->
                        <!--                                    </select>-->
                        <!--                                </td>-->
                        <!--                            </tr>-->
                        </tbody>
                    </table>
                    <button class="mt-4 ml-4 py-2 px-4 bg-blue-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-blue-500" *ngIf="!isEditModeEnabledForABGroups" (click)="isEditModeEnabledForABGroups=!isEditModeEnabledForABGroups">Edit</button>
                    <button class="mt-4 ml-4 py-2 px-4 bg-green-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-green-500" *ngIf="isEditModeEnabledForABGroups">Save</button>
                    <button class="mt-4 ml-4 py-2 px-4 bg-red-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-red-500" *ngIf="isEditModeEnabledForABGroups" (click)="isEditModeEnabledForABGroups=!isEditModeEnabledForABGroups">Decline</button>
                </div>
                <div>
                    <div class="flex flex-row text-gray-500 px-4 pb-4">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                            <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd" />
                        </svg>
                        <span class="text-xs pl-2 pt-0.5">Редактирование других групп</span>
                    </div>
                    <table class="w-full table-fixed text-sm text-gray-500">
                        <thead>
                        <tr>
                            <th class="w-1/2" style="display: none"></th>
                            <th class="w-1/2" style="display: none"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td class="border-t border-gray-200 pl-4">Login Group</td>
                            <td class="border-t border-gray-200 additional-params bg-gray-50" *ngIf="!isEditModeEnabledForAnotherGroups">
                                -
                            </td>
                            <td class="border-t border-gray-200" *ngIf="isEditModeEnabledForAnotherGroups">
                                <input formControlName="1"
                                       id="3"
                                       type="text"
                                       class="additional-params-input block w-full text-sm border-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50">
                            </td>
                        </tr>
                        <tr>
                            <td class="border-t border-gray-200 pl-4">Age</td>
                            <td class="border-t border-gray-200 additional-params bg-gray-50" *ngIf="!isEditModeEnabledForAnotherGroups">
                                18
                            </td>
                            <td class="border-t border-gray-200 bg-green-50" *ngIf="isEditModeEnabledForAnotherGroups">
                                <input formControlName="2"
                                       id="6"
                                       type="text"
                                       class="additional-params-input bg-green-50 block w-full text-sm border-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                       placeholder="18">
                            </td>
                        </tr>
                        <tr>
                            <td class="border-t border-gray-200 pl-4">Communication_joined</td>
                            <td class="border-t border-gray-200 additional-params bg-gray-50" *ngIf="!isEditModeEnabledForAnotherGroups">297</td>
                            <td class="border-t border-gray-200 bg-red-50" *ngIf="isEditModeEnabledForAnotherGroups">
                                <input formControlName="3"
                                       id="5"
                                       type="text"
                                       class="additional-params-input bg-red-50 block w-full text-sm border-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                       placeholder="297">
                            </td>
                        </tr>
                        <tr>
                            <td class="border-t border-b border-gray-200 pl-4">Gender</td>
                            <td class="border-t border-b border-gray-200 additional-params bg-gray-50" *ngIf="!isEditModeEnabledForAnotherGroups">Female</td>
                            <td class="border-t border-b border-gray-200" *ngIf="isEditModeEnabledForAnotherGroups">
                                <select formControlName="4" id="4" class="block w-full text-sm text-gray-500 border-none focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                                    <option selected>Female</option>
                                    <option>Male</option>
                                </select>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <button class="mt-4 ml-4 py-2 px-4 bg-blue-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-blue-500" *ngIf="!isEditModeEnabledForAnotherGroups" (click)="isEditModeEnabledForAnotherGroups=!isEditModeEnabledForAnotherGroups">Edit</button>
                    <button class="mt-4 ml-4 py-2 px-4 bg-green-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-green-500" *ngIf="isEditModeEnabledForAnotherGroups">Save</button>
                    <button class="mt-4 ml-4 py-2 px-4 bg-red-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-red-500" *ngIf="isEditModeEnabledForAnotherGroups" (click)="isEditModeEnabledForAnotherGroups=!isEditModeEnabledForAnotherGroups">Decline</button>
                </div>
            </div>
        </div>
        <div class="bg-white border border-gray-200 rounded-lg p-4 mt-10">
            <form [formGroup]="sectionsValuesForm" (submit)="add(sectionsValuesForm)">
                <div class="flex flex-row text-gray-500 px-4 pb-4">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                        <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-xs pl-2 pt-0.5 mr-8">Редактирование параметров игрока</span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mt-0.5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-xs pl-2 pt-1 text-yellow-500">Бинарные секции недоступны для изменений</span>
                </div>
                <table class="w-full table-fixed text-sm text-gray-500">
                    <thead>
                    <tr>
                        <th class="w-1/2" style="display: none"></th>
                        <th class="w-1/2" style="display: none"></th>
                        <th class="w-1/2" style="display: none"></th>
                        <th class="w-1/2" style="display: none"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <!--                <td class="border-t border-gray-200">Gender</td>-->
                        <!--                <td class="border-t border-gray-200 additional-params bg-gray-50" *ngIf="!isEditModeEnabledForAdditionalProfileParams">Female</td>-->
                        <td class="border-t border-b border-gray-200">
                            <select (change)="onChangeForm(player)"
                                    id="50"
                                    formControlName="operationType"
                                    class="block w-full text-sm text-gray-500 border-none focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                                <option selected value="{{this.playerFormControlsDefaults.operationType}}">{{this.playerFormControlsDefaults.operationType}}</option>
                                <option *ngFor="let operation of operations" value="{{operation.id}}">{{operation.name}}</option>
                            </select>
                        </td>
                        <!--                <td class="border-t border-gray-200">Gender</td>-->
                        <!--                <td class="border-t border-gray-200 additional-params bg-gray-50" *ngIf="!isEditModeEnabledForAdditionalProfileParams">Female</td>-->
                        <td class="border-t border-b border-gray-200">
                            <ng-select
                                    [clearable]=false
                                    (search)="searchSectionsValues($event, player)"
                                    (change)="onChangeForm(player)"
                                    formControlName="sectionsEntity"
                                    id="60"
                                    class="custom block w-full text-sm text-gray-500 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                                <ng-option *ngFor="let sectionsValue of sectionsValues"
                                           [value]="sectionsValue">{{sectionsValue.name}}
                                    <span class="text-xs text-blue-600 ml-4">{{sectionsValue.type}}</span>
                                </ng-option>
                            </ng-select>
                            <!--&lt;!&ndash;                            <ng-select (change)="onChangeForm(player)" (change)="onChangeSectionsValuesForm(player)" class="custom block w-full text-sm text-gray-500 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"&ndash;&gt;-->
                            <!--&lt;!&ndash;                            <ng-select (change)="onChangeForm(player)" class="custom block w-full text-sm text-gray-500 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"&ndash;&gt;-->
                            <!--&lt;!&ndash;                                       id="60"&ndash;&gt;-->
                            <!--&lt;!&ndash;                                       formControlName="sectionsValueId"&ndash;&gt;-->
                            <!--&lt;!&ndash;                                       bindLabel="name"&ndash;&gt;-->
                            <!--&lt;!&ndash;                                       autofocus&ndash;&gt;-->
                            <!--&lt;!&ndash;                                       bindValue="id"&ndash;&gt;-->
                            <!--&lt;!&ndash;                                       [items]="sectionsValues"&ndash;&gt;-->
                            <!--&lt;!&ndash;                            >&ndash;&gt;-->
                            <!--&lt;!&ndash;                                <ng-option selected value="{{this.playerFormControlsDefaults.operationType}}">{{this.playerFormControlsDefaults.operationType}}</ng-option>&ndash;&gt;-->
                            <!--&lt;!&ndash;                            </ng-select>&ndash;&gt;-->
                            <!--&lt;!&ndash;                            <select id="60" formControlName="sectionsValueId" class="block w-full text-sm text-gray-500 border-none focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">&ndash;&gt;-->
                            <!--&lt;!&ndash;                                <option selected value="{{this.playerFormControlsDefaults.sectionsValueId}}">{{this.playerFormControlsDefaults.sectionsValueId}}</option>&ndash;&gt;-->
                            <!--&lt;!&ndash;                                <option  *ngFor="let item of sectionsValues" value="{{item.id}}">{{item.name}}</option>&ndash;&gt;-->
                            <!--&lt;!&ndash;                            </select>&ndash;&gt;-->
                        </td>
                        <!--                <td class="border-t border-gray-200 pl-4">A/B_test_group 2</td>-->
                        <!--                <td class="border-t border-gray-200 additional-params bg-gray-50" *ngIf="!isEditModeEnabledForAdditionalProfileParams">-->
                        <!--                    - -->
                        <!--                </td>-->
                        <!--                        <td class="border-t border-b border-gray-200 {{isValidInputValue() ? '' : 'bg-red-100'}}">-->
                        <td class="border-t border-b border-gray-200">
                            <!--                            pattern="[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}"-->
                            <!--                            class="{{(playerItemsForm.controls['sectionsValue'].invalid && (playerItemsForm.controls['sectionsValue'].dirty || playerItemsForm.controls['sectionsValue'].touched)) ? 'bg-red-100' : ''}} additional-params-input block w-full text-sm border-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"-->
                            <input (change)="onChangeForm(player)" *ngIf="isSelectedOperationAndItem() && inputType !== ''"
                                   formControlName="sectionsValue"
                                   id="70"
                                   type="{{inputType}}"
                                   class="{{isValidSectionsValue() ? 'bg-red-100' : ''}} additional-params-input block w-full text-sm border-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                   placeholder="{{inputPlaceholder}}" [value]="this.sectionsValuesForm.controls['sectionsValue'].value">
                            <button (click)="this.sectionsValuesForm.controls['sectionsValue'].patchValue(!this.sectionsValuesForm.controls['sectionsValue'].value)" *ngIf="isSelectedOperationAndItem() && inputType === ''"
                                    id="71"
                                    type="button"
                                    role="switch"
                                    aria-labelledby="annual-billing-label"
                                    class="{{this.sectionsValuesForm.controls['sectionsValue'].value ? 'bg-blue-400' : 'bg-gray-200'}} my-1.5 ml-8 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400">
                            <span aria-hidden="true"
                                  class="{{this.sectionsValuesForm.controls['sectionsValue'].value ? 'translate-x-5' : 'translate-x-0'}} pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
                            </button>
                        </td>
                        <td class="border-t border-b border-gray-200">
                            <div class="grid grid-cols-2">
                                <button *ngIf="isShowingSaveButton(player)" type="submit" class="py-2 bg-green-300 text-sm text-white font-semibold hover:bg-green-500">Save</button>
                                <button *ngIf="isShowingDeclineButton()" (click)="resetForm()" class="py-2 bg-red-300 text-sm text-white font-semibold hover:bg-red-500">Decline</button>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </form>
            <div class="sections-values">
                <div class="flex flex-row text-gray-500 px-4 pb-4">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-xs pl-2 pt-0.5">Список параметров игрока</span>
                </div>
                <table class="w-full table-fixed text-sm text-gray-500">
                    <thead>
                    <tr>
                        <th class="w-1/2" style="display: none"></th>
                        <th class="w-1/2" style="display: none"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of getPlayerSectionsForList(player); index as i">
                        <td class="{{i === 0 ? 'border-b border-t' : 'border-b'}} border-gray-200 pl-4 h-10">{{item.name}}<span class="text-xs text-blue-600 ml-4">{{item.type}}</span></td>
                        <td [ngSwitch]="true" class="{{i === 0 ? 'border-b border-t' : 'border-b'}} border-gray-200 additional-params bg-gray-50 h-10">
                            <p *ngSwitchCase="item.type === 'datetime'">{{item.value | date:'Y-MM-d H:mm:s'}}</p>
                            <p *ngSwitchCase="item.value == null" class="text-xs text-blue-600">null</p>
                            <p *ngSwitchDefault>{{item.value}}</p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="bg-white border border-gray-200 rounded-lg px-4 mt-10">
            <div>
                <div class="flex flex-row text-gray-500 hover:text-blue-500 p-4">
                    <svg *ngIf="!isShowingProfileActions" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                    <svg *ngIf="isShowingProfileActions" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                    </svg>
                    <button class="text-xs pl-2 pt-0.5" (click)="isShowingProfileActions=!isShowingProfileActions">Операции с аккаунтом игрока</button>
                </div>
                <div *ngIf="isShowingProfileActions">
<!--                <p class="pl-5 pt-1 text-sm text-gray-500" *ngFor="let log of player.logsPlayerStatusChanges">{{log.createdAt}}" *ngIf="log" &nbsp; &nbsp;  Пользовать <a routerLink="/user/{{log.userId}}/profile" routerLinkActive="active" class="text-sm text-blue-500 hover:text-blue-700">{{log.username}}</a> сменил статус игрока на: <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-{{getPlayerStatusColor(log.status)}}-100 text-{{getPlayerStatusColor(log.status)}}-800">{{log.status}}</span> с причиной: "{{log.reason}}"</p>-->
                <p class="pl-5 pt-1 text-sm text-gray-500" *ngFor="let log of player.logsPlayerStatusChanges">
                    <span>{{log.createdAt}} &nbsp; &nbsp;  </span>
                    <span *ngIf="log.status"         >Пользовать <a routerLink="/user/{{log.userId}}/profile" routerLinkActive="active" class="text-sm text-blue-500 hover:text-blue-700">{{log.username}}</a> сменил статус игрока на: <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-{{getPlayerStatusColor(log.status)}}-100 text-{{getPlayerStatusColor(log.status)}}-800">{{log.status}}</span> с причиной: "{{log.reason}}"</span>
                    <span *ngIf="log.status === null">Пользовать <a routerLink="/user/{{log.userId}}/profile" routerLinkActive="active" class="text-sm text-blue-500 hover:text-blue-700">{{log.username}}</a> оставил примечание: "{{log.reason}}"</span>
                </p>
                    <div class="border-t border-gray-200 p-4 {{player.logsPlayerStatusChanges.length > 0 ? 'mt-4' : ''}}">
                        <button (click)="isShowingProfileDeactivationModal=!isShowingProfileDeactivationModal" class="py-2 px-4 bg-red-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-red-500">Заблокировать аккаунт</button>
                        <button (click)="isShowingProfileEraseModal=!isShowingProfileEraseModal" class="py-2 ml-4 px-4 bg-red-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-red-500">Вайпнуть аккаунт</button>
                        <button (click)="isShowingProfileAddNoteModal=!isShowingProfileAddNoteModal" class="py-2 ml-4 px-4 bg-blue-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-blue-500">Добавить примечание</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<!-- MODALS -->
<template #deploy></template>

<div *ngIf="isShowingProfileDeactivationModal" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <!--
          Background overlay, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <!--
          Modal panel, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        -->
        <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button (click)="isShowingProfileDeactivationModal=!isShowingProfileDeactivationModal" type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span class="sr-only">Закрыть</span>
                    <!-- Heroicon name: outline/x -->
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            <form [formGroup]="changeStatusForm" (submit)="deactivatePlayer()">
                <div class="sm:flex sm:items-start">
                    <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <!-- Heroicon name: outline/exclamation -->
                        <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                    </div>
                    <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 class="text-lg leading-6 font-medium text-gray-900">
                            Заблокировать аккаунт
                        </h3>
                        <div class="mt-2">
                            <p class="text-sm text-gray-500">
                                Вы уверены что хотите заблокировать аккаунт?
                                Пользователь потеряет доступ к играм.
                            </p>
                        </div>
                        <div class="mt-4 border-b {{this.changeStatusForm.invalid ? 'border-red-400' : 'border-green-400'}}">
                            <textarea id="deactivateTextForm" autofocus [focusField]="1" formControlName="reason" rows="2" name="comment" class="block w-full border-0 border-b border-transparent p-0 pb-2 resize-none focus:ring-0 {{this.changeStatusForm.invalid ? 'focus:border-red-400' : 'focus:border-green-400'}} sm:text-sm" placeholder=""></textarea>
                        </div>
                    </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button type="submit" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                        Заблокировать
                    </button>
                    <button (click)="isShowingProfileDeactivationModal=!isShowingProfileDeactivationModal" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
                        Отменить
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<div *ngIf="isShowingProfileEraseModal" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <!--
          Background overlay, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <!--
          Modal panel, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        -->
        <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button (click)="isShowingProfileEraseModal=!isShowingProfileEraseModal" type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span class="sr-only">Закрыть</span>
                    <!-- Heroicon name: outline/x -->
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            <form [formGroup]="changeStatusForm" (submit)="erasePlayer()">
                <div class="sm:flex sm:items-start">
                    <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <!-- Heroicon name: outline/exclamation -->
                        <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                    </div>
                    <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 class="text-lg leading-6 font-medium text-gray-900">
                            Стереть аккаунт
                        </h3>
                        <div class="mt-2">
                            <p class="text-sm text-gray-500">
                                Вы уверены что хотите стереть персональные данные игрока?
                                Пользователь потеряет доступ к играм без возможности возврата.
                            </p>
                        </div>
                        <div class="mt-4 border-b {{this.changeStatusForm.invalid ? 'border-red-400' : 'border-green-400'}}">
                            <textarea id="eraseTextForm" autofocus [focusField]="1" formControlName="reason" rows="2" name="comment" class="block w-full border-0 border-b border-transparent p-0 pb-2 resize-none focus:ring-0 {{this.changeStatusForm.invalid ? 'focus:border-red-400' : 'focus:border-green-400'}} sm:text-sm" placeholder=""></textarea>
                        </div>
                    </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button type="submit" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                        Стереть
                    </button>
                    <button (click)="isShowingProfileEraseModal=!isShowingProfileEraseModal" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
                        Отменить
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<div *ngIf="isShowingProfileAddNoteModal" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <!--
          Background overlay, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <!--
          Modal panel, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        -->
        <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button (click)="isShowingProfileAddNoteModal=!isShowingProfileAddNoteModal" type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span class="sr-only">Закрыть</span>
                    <!-- Heroicon name: outline/x -->
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            <form [formGroup]="changeStatusForm" (submit)="addNoteToPlayer()">
                <div class="sm:flex sm:items-start">
                    <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 class="mb-2 text-lg leading-6 font-medium text-gray-900">
                            Добавить примечание к аккаунту
                        </h3>
<!--                        <div class="mt-2">-->
<!--                            <p class="text-sm text-gray-500">-->
<!--                                Комментарий к данному игроку-->
<!--                            </p>-->
<!--                        </div>-->
                        <div class="mt-4 border-b {{this.changeStatusForm.invalid ? 'border-red-400' : 'border-green-400'}}">
                            <textarea id="addNoteTextForm" autofocus [focusField]="1" formControlName="reason" rows="2" name="comment" class="block w-full border-0 border-b border-transparent p-0 pb-2 resize-none focus:ring-0 {{this.changeStatusForm.invalid ? 'focus:border-red-400' : 'focus:border-green-400'}} sm:text-sm" placeholder=""></textarea>
                        </div>
                    </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button type="submit" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-300 text-base font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm">
                        Добавить
                    </button>
                    <button (click)="isShowingProfileAddNoteModal=!isShowingProfileAddNoteModal" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
                        Отменить
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
