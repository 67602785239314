import {Component, Input, OnInit} from '@angular/core';
import {Chunk} from "../chunk/chunk";
import { v4 as uuidv4 } from 'uuid';
import {ChunkListService} from "./chunk-list.service";
import {RenovationStatus} from "../renovation/renovation";
import {Skin} from "../skin/skin";
import {HttpErrorResponse} from "@angular/common/http";
import {ChunkService} from "../chunk/chunk.service";
import {NotificationsService} from "angular2-notifications";
import {RenovationListService} from "../renovation-list/renovation-list.service";
import {RenovationService} from "../renovation/renovation.service";
import {RenovationOrderList} from "../renovation-list/renovation-list";

@Component({
  selector: 'app-chunk-list',
  templateUrl: './chunk-list.component.html',
  styleUrls: ['./chunk-list.component.scss']
})
export class ChunkListComponent implements OnInit {

  public readonly RenovationStatusPending = RenovationStatus.PENDING;
  public readonly RenovationStatusSaved = RenovationStatus.SAVED;
  public readonly RenovationStatusConfirmed = RenovationStatus.CONFIRMED;

  @Input() renovationId!: number;
  @Input() listType!: RenovationStatus;
  @Input() loadedChunks: Chunk[] = [];

  isActiveChunkList: boolean = false;

  constructor(
    private notificationsService: NotificationsService,
    public renovationService: RenovationService,
    public renovationListService: RenovationListService,
    public chunkListService: ChunkListService,
    public chunkService: ChunkService,
  ) {}

  ngOnInit(): void {}

  getIsActiveChunkList() {
    return this.isActiveChunkList
  }

  setIsActiveChunkList() {
    this.isActiveChunkList = !this.isActiveChunkList
  }

  changeOrderBy(columnName: string) {
    this.chunkListService.changeOrderBy(columnName);
    this.renovationListService.renovationOrderMap.set(this.renovationId.toString() + "-chunks", {
      skinRenovationId: this.renovationId,
      element: 'chunks',
      orderBy: this.chunkListService.orderByColumn,
      orderType: this.chunkListService.orderDirection,
    } as RenovationOrderList)
    this.renovationListService.fetchAndLoadSkinRenovations()
  }

  deleteChunk(chunk: Chunk) {
    if (!confirm("Ви впевнені що видалити чанк: " + chunk.uniqueId + ' та елементи мапи зі скінами що його використовують?')) {
      return;
    }

    this.chunkService.deleteChunk(chunk.id).subscribe(
      (data) => {
        if (data.status === 200) {
          this.notificationsService.success('Успіх!', 'Чанк видалено', {
            timeOut: 1500,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
          });
          this.renovationListService.fetchAndLoadSkinRenovations()
        }
      }, (data) => {
        if (data instanceof HttpErrorResponse) {
          if (data.status < 500) {
            this.notificationsService.error('Помилка!', data.error, {
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            });
          } else {
            this.notificationsService.error('Помилка!', data.error.detail, {
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            });
          }
        } else {
          this.notificationsService.error('Помилка!', "Невдалося видалити чанк з очікування", {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
          });
        }
      }
    );
  }

  editChunk(chunk: Chunk) {
    this.renovationService.activateChunkForm();
    this.chunkService.editableChunk = chunk
  }
}
