<div *ngIf="isShowingDeployModal" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <!--
          Background overlay, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <!--
          Modal panel, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        -->
        <form [formGroup]="newDeployForm" (submit)="createDeployWithSelectedPlayers()" class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
            <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button (click)="closeModal()" type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span class="sr-only">Закрыть</span>
                    <!-- Heroicon name: outline/x -->
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            <div>
              <div class="flow-root mt-12">
                <ul role="list" class="-my-5 divide-y divide-gray-200">
                  <li class="{{getBackgroundColorForNewDeployInput()}}">
                    <div (click)="showTextInputForNewDeploy()" class="flex flex-row gap-1 w-full {{isShowingTextInputForNewDeploy ? '' : 'cursor-pointer'}}">
                      <div class="{{isInvalidDeployName() ? 'text-red-500' : 'text-green-500'}} p-3">
                        <svg *ngIf="!isShowingTextInputForNewDeploy" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <svg *ngIf="isShowingTextInputForNewDeploy" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                      </div>
                      <div *ngIf="!isShowingTextInputForNewDeploy" style="padding: 9px 0;">
                        <span class="text-sm text-gray-900">Створити новий деплой</span>
                      </div>
                      <div *ngIf="isShowingTextInputForNewDeploy" class="w-full" style="padding: 4px 0;">
                          <input formControlName="name"
                                 type="text"
                                 class="disabled:opacity-20 {{isShowingTextInputForNewDeploy ? '' : 'cursor-pointer'}} block w-full text-sm border-none focus:ring-0 pl-0 {{isInvalidDeployName() ? 'bg-red-50' : ''}}"
                                 placeholder="Додайте назву"
                                 autofocus [focusField]="1">
                      </div>
                    </div>
                  </li>
                  <li *ngFor="let deploy of deploys" class="hover:bg-green-50">
                    <button type="button" class="flex flex-row w-full" (click)="addRedeemsToExistedDeploy(deploy.id)">
                      <div *ngIf="selectedDeploy && selectedDeploy.id === deploy.id" class="text-green-500 p-3">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                      </div>
                      <div class="focus-within:ring-2 focus-within:ring-indigo-500 py-2.5 {{selectedDeploy && selectedDeploy.id === deploy.id ? 'px-1' : 'pl-12'}}">
                        <span class="text-sm text-gray-900">{{deploy.name}}</span>
                      </div>
                    </button>
                  </li>
                </ul>
              </div>
              <div class="mt-6">
                <a href="#" class="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                  View all
                </a>
              </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button *ngIf="selectedDeploy || (!isInvalidDeployName() && this.newDeployForm.controls['name'].value !== null)" type="submit" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-300 text-base font-medium text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm">
                    Створити
                </button>
                <button (click)="closeModal()" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
                    Закрити
                </button>
            </div>
            <!--            <div class="relative rounded-xl overflow-auto p-8">-->
            <!--              <div class="flex items-center justify-center">-->
            <!--                <button type="button"-->
            <!--                        class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-indigo-500"-->
            <!--                        disabled="">-->
            <!--                  <svg class="motion-reduce:hidden animate-spin -ml-1 mr-3 h-5 w-5 text-white"-->
            <!--                       xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">-->
            <!--                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>-->
            <!--                    <path class="opacity-75" fill="currentColor"-->
            <!--                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>-->
            <!--                  </svg>-->
            <!--                  Processing...-->
            <!--                </button>-->
            <!--              </div>-->
            <!--            </div>-->
        </form>
    </div>
</div>
