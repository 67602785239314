import { Injectable } from '@angular/core';
import {Observable, Subject} from "rxjs";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {DeployName} from "../deploy";

@Injectable({
  providedIn: 'root'
})
export class DeployModalService {

  private _deployModalList: DeployName[] = [];

  constructor(private http: HttpClient) { }

  createDeploy(changes: any): Observable<HttpResponse<any>> {
    return this.http.post<any>('/api/v1/deploy-create', changes, {
      observe: 'response',
      withCredentials: true
    });
  }

  appendDeployChanges(id: number, changes: any): Observable<HttpResponse<any>> {
    return this.http.patch<any>('/api/v1/deploy/' + id + '/append-changes', changes, {
      observe: 'response',
      withCredentials: true
    });
  }

  getDeployModalList(): Observable<HttpResponse<DeployName[]>> {
    return this.http.get<DeployName[]>('/api/v1/deploy-modal-list', {
      observe: 'response',
      withCredentials: true
    });
  }

  setDeployModalList(deployModalList: DeployName[]) {
    this._deployModalList = deployModalList;
  }

  get deployModalList(): DeployName[] {
    return this._deployModalList;
  }
}
