<div class="ml-4 inline-flex items-center px-3.5 py-2 cursor-pointer border border-{{getColor()}}-{{isValid === true ? '300' : '200'}} hover:border-{{getColor()}}-400 text-{{getColor()}}-500 hover:text-{{getColor()}}-700 text-sm leading-4 rounded-md bg-white focus:outline-none">
    <label class="group inline-flex">
        <span class="relative top-0.5">{{isValid === null && file === null ? 'Завантажити CSV' : file?.name}}</span>
        <span class="ml-2 flex-none rounded text-{{getColor()}}-400">
          <svg class="w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
          </svg>
<!--          <svg *ngIf="isValid !== null" class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">-->
<!--            <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />-->
<!--          </svg>-->
        </span>
        <input id="uploadFile" type='file' class="hidden"/>
    </label>
</div>

