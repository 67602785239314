<!--<div class="flex flex-col" *ngxPermissionsOnly="['canSeeDeployPage']">-->
<div class="flex flex-col">
    <div class="bg-gray-50 overflow-auto border border-gray-200 rounded-lg з-">
        <table style="width: max-content;" class="w-full table-fixed divide-y divide-gray-200">
            <thead class="bg-gray-50">
            <tr>
                <th (click)="changeOrderBy('id')" scope="col" class="pl-10 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <ng-container *ngIf="remoteConfigListService.orderByColumn === 'id'"
                                  [ngTemplateOutlet]="remoteConfigListService.orderDirection == 'DESC' ? orderDirectionDesc : orderDirectionAsc"
                                  [ngTemplateOutletContext]="{columnName:'Id'}">
                    </ng-container>
                    {{remoteConfigListService.orderByColumn !== 'id' ? "Id" : ""}}
                </th>
                <th (click)="changeOrderBy('name')" scope="col" class="pl-10 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <ng-container *ngIf="remoteConfigListService.orderByColumn === 'name'"
                                  [ngTemplateOutlet]="remoteConfigListService.orderDirection == 'DESC' ? orderDirectionDesc : orderDirectionAsc"
                                  [ngTemplateOutletContext]="{columnName:'Name'}">
                    </ng-container>
                    {{remoteConfigListService.orderByColumn !== 'name' ? "Name" : ""}}
                </th>
                <th (click)="changeOrderBy('condition')" scope="col" class="pl-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <ng-container *ngIf="remoteConfigListService.orderByColumn === 'condition'"
                                  [ngTemplateOutlet]="remoteConfigListService.orderDirection == 'DESC' ? orderDirectionDesc : orderDirectionAsc"
                                  [ngTemplateOutletContext]="{columnName:'Condition'}">
                    </ng-container>
                    {{remoteConfigListService.orderByColumn !== 'condition' ? "Condition" : ""}}
                </th>
                <th (click)="changeOrderBy('groupId')" scope="col" class="pl-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <ng-container *ngIf="remoteConfigListService.orderByColumn === 'groupId'"
                                  [ngTemplateOutlet]="remoteConfigListService.orderDirection == 'DESC' ? orderDirectionDesc : orderDirectionAsc"
                                  [ngTemplateOutletContext]="{columnName:'Group Id'}">
                    </ng-container>
                    {{remoteConfigListService.orderByColumn !== 'groupId' ? 'Group Id' : ""}}
                </th>
                <th (click)="changeOrderBy('minVersionMajor')" scope="col" class="pl-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <ng-container *ngIf="remoteConfigListService.orderByColumn === 'minVersionMajor'"
                                  [ngTemplateOutlet]="remoteConfigListService.orderDirection == 'DESC' ? orderDirectionDesc : orderDirectionAsc"
                                  [ngTemplateOutletContext]="{columnName:'Min version Major'}">
                    </ng-container>
                    {{remoteConfigListService.orderByColumn !== 'minVersionMajor' ? "Major" : ""}}
                </th>
                <th (click)="changeOrderBy('minVersionMinor')" scope="col" class="pl-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <ng-container *ngIf="remoteConfigListService.orderByColumn === 'minVersionMinor'"
                                  [ngTemplateOutlet]="remoteConfigListService.orderDirection == 'DESC' ? orderDirectionDesc : orderDirectionAsc"
                                  [ngTemplateOutletContext]="{columnName:'Min version Minor'}">
                    </ng-container>
                    {{remoteConfigListService.orderByColumn !== 'minVersionMinor' ? "Minor" : ""}}
                </th>
				        <th (click)="changeOrderBy('minVersionBuild')" scope="col" class="pl-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <ng-container *ngIf="remoteConfigListService.orderByColumn === 'minVersionBuild'"
                                  [ngTemplateOutlet]="remoteConfigListService.orderDirection == 'DESC' ? orderDirectionDesc : orderDirectionAsc"
                                  [ngTemplateOutletContext]="{columnName:'Build Version'}">
                    </ng-container>
                    {{remoteConfigListService.orderByColumn !== 'minVersionBuild' ? "Build Version" : ""}}
                </th>
                <th (click)="changeOrderBy('content')" scope="col" class="pl-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <ng-container *ngIf="remoteConfigListService.orderByColumn === 'content'"
                                  [ngTemplateOutlet]="remoteConfigListService.orderDirection == 'DESC' ? orderDirectionDesc : orderDirectionAsc"
                                  [ngTemplateOutletContext]="{columnName:'Content'}">
                    </ng-container>
                    {{remoteConfigListService.orderByColumn !== 'content' ? "Content" : ""}}
                </th>
                <th (click)="changeOrderBy('createdAt')" scope="col" class="pl-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <ng-container *ngIf="remoteConfigListService.orderByColumn === 'createdAt'"
                                  [ngTemplateOutlet]="remoteConfigListService.orderDirection == 'DESC' ? orderDirectionDesc : orderDirectionAsc"
                                  [ngTemplateOutletContext]="{columnName:'Created At'}">
                    </ng-container>
                    {{remoteConfigListService.orderByColumn !== 'createdAt' ? "Created At kek" : ""}}
                </th>
<!--                <th (click)="changeOrderBy('updatedAt')" scope="col" class="pl-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">-->
<!--                    <ng-container *ngIf="remoteConfigListService.orderByColumn === 'updatedAt'"-->
<!--                                  [ngTemplateOutlet]="remoteConfigListService.orderDirection == 'DESC' ? orderDirectionDesc : orderDirectionAsc"-->
<!--                                  [ngTemplateOutletContext]="{columnName:'Updated At'}">-->
<!--                    </ng-container>-->
<!--                    {{remoteConfigListService.orderByColumn !== 'updatedAt' ? "Updated At" : ""}}-->
<!--                </th>-->
                <th scope="col" class="pl-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Author
                </th>
            </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
            <ng-container *ngFor="let rcName of objectKeys(remoteConfigsGroup)">
              <ng-container *ngFor="let remoteConfig of remoteConfigsGroup[rcName]; let i = index">
                <tr *ngIf="remoteConfigsGroup[rcName].length > 1 && i == 0 && !visibleRemoteConfigGroups.includes(rcName)" class="border-t border-gray-200 hover:bg-blue-50">
                  <td (click)="showRemoteConfigGroup(rcName)">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style="margin-left: 40px"  class="size-5 w-5 h-5 text-blue-600">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>
                  </td>
                  <td (click)="showRemoteConfigGroup(rcName)" class="pl-10 pr-3 text-left text-sm font-normal text-gray-900">{{remoteConfig.name}}
                    <span class="text-blue-400 pl-2 text-right">x{{remoteConfigsGroup[rcName].length}}</span>
                  </td>
                  <td class="pl-6 text-sm text-gray-900 has-tooltip">
                    {{remoteConfig.condition}}
                  </td>
                  <td class="pl-6 text-sm text-gray-900">
                    {{remoteConfig.groupId}}
                  </td>
                  <td class="pl-6 text-sm text-gray-900">
                    {{remoteConfig.minVersionMajor}}
                  </td>
                  <td class="pl-6 text-sm text-gray-900">
                    {{remoteConfig.minVersionMinor}}
                  </td>
                  <td class="pl-6 text-sm text-gray-900">
                    {{remoteConfig.minVersionBuild}}
                  </td>
                  <th class="pl-6 text-sm flex flex-row text-blue-500">
                    <svg (click)="downloadRemoteConfigConfigAsJsonFile(remoteConfig.name, remoteConfig.content)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 hover:text-blue-800 cursor-pointer">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m.75 12 3 3m0 0 3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                    </svg>
                    <button class="pl-4 hover:text-blue-800" (click)="editContentAndCloseOthers(remoteConfig.id, remoteConfig.content)">Edit</button>
                  </th>
                  <td class="pl-6 text-sm text-gray-900">
                    {{remoteConfig.createdAt}}
                  </td>
                  <td class="pl-6 text-sm text-gray-900">
                    {{remoteConfig.username}}
                  </td>
                  <td class="pl-6 text-sm text-gray-900">
                    <button (click)="deleteRemoteConfig(remoteConfig)">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-red-400 mr-2 pt-1">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                      </svg>
                    </button>
                  </td>
                </tr>
                <tr *ngIf="visibleRemoteConfigGroups.includes(rcName) || remoteConfigsGroup[rcName].length === 1" class="hover:bg-blue-50">
                  <td (click)="showRemoteConfigGroup(rcName)" class="{{visibleRemoteConfigGroups.includes(rcName) && remoteConfigsGroup[rcName].length > 1 ? 'flex flex-row' : 'pl-10'}} text-sm text-gray-900">
                    <svg *ngIf="visibleRemoteConfigGroups.includes(rcName) && remoteConfigsGroup[rcName].length > 1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5 w-5 h-5 text-red-600 mx-2.5" style="margin-right: 0.585rem;">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                    </svg>
                    <a href="/remote-config-edit/{{remoteConfig.id}}/remote-config-list" class="text-blue-600 hover:text-blue-900">{{remoteConfig.id}}</a>
                  </td>
                  <td (click)="showRemoteConfigGroup(rcName)" class="pl-10 text-sm text-gray-900">
                    {{remoteConfig.name}}
                  </td>
                  <td class="pl-6 text-sm text-gray-900 has-tooltip">
                    {{remoteConfig.condition}}
                  </td>
                  <td class="pl-6 text-sm text-gray-900">
                    {{remoteConfig.groupId}}
                  </td>
                  <td class="pl-6 text-sm text-gray-900">
                    {{remoteConfig.minVersionMajor}}
                  </td>
                  <td class="pl-6 text-sm text-gray-900">
                    {{remoteConfig.minVersionMinor}}
                  </td>
                  <td class="pl-6 text-sm text-gray-900">
                    {{remoteConfig.minVersionBuild}}
                  </td>
                  <th class="pl-6 text-sm flex flex-row text-blue-500">
                    <svg (click)="downloadRemoteConfigConfigAsJsonFile(remoteConfig.name, remoteConfig.content)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 hover:text-blue-800 cursor-pointer">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m.75 12 3 3m0 0 3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                    </svg>
                    <button class="pl-4 hover:text-blue-800" (click)="editContentAndCloseOthers(remoteConfig.id, remoteConfig.content)">Edit</button>
                  </th>
                  <td class="pl-6 text-sm text-gray-900">
                    {{remoteConfig.createdAt}}
                  </td>
                  <td class="pl-6 text-sm text-gray-900">
                    {{remoteConfig.username}}
                  </td>
                  <td class="pl-6 text-sm text-gray-900">
                    <button (click)="deleteRemoteConfig(remoteConfig)">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-red-400 mr-2 pt-1">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                      </svg>
                    </button>
                  </td>
                </tr>
                <tr *ngIf="isEditedContentList[remoteConfig.id]" class="bg-gray-50">
                  <td colspan="4" class="align-top text-center">
                    <div class="m-5 w-full">
                      <div id="tabs-1-panel-1" class="-m-0.5 rounded-lg p-0.5" aria-labelledby="tabs-1-tab-1" role="tabpanel" tabindex="0">
                        <label for="editor" class="sr-only">Editor</label>
                        <div>
                          <textarea rows="10" [(ngModel)]="remoteConfig.content" name="comment" id="editor" class="block max-h-full w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-200 sm:text-sm sm:leading-6" placeholder=""></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="mx-5 mb-5">
                      <button *ngIf="isContentWasChangedAndValid(remoteConfig)" (click)="updateRemoteConfig(remoteConfig)" type="submit" class="flex-1 py-2 px-4 bg-green-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-green-500">Зберегти</button>
                    </div>
                  </td>
                  <td colspan="4" class="align-top">
                    <div class="m-5 block">
                      <div id="tabs-2-panel-2" class="-m-0.5 rounded-lg p-0.5 overflow-x-auto overflow-y-auto" style="width: 500px; max-height: 1000px;" aria-labelledby="tabs-1-tab-1" role="tabpanel" tabindex="0">
                        <label for="viewer" class="sr-only">Viewer</label>
                        <div class="w-max">
                          <ngx-json-viewer id="viewer" [json]="parseContentStringToJson(remoteConfig.content)" [expanded]="true"></ngx-json-viewer>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
    </div>
    <div class="mt-4">
        <nav class="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
            <div class="-mt-px w-0 flex-1 flex">
<!--                <a [routerLink]="" queryParamsHandling="preserve" (click)="changePage(remoteConfigListService.currentPage - 1)" *ngIf="remoteConfigListService.currentPage > 1" class="cursor-pointer border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">-->
<!--                    <svg class="mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">-->
<!--                        <path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />-->
<!--                    </svg>-->
<!--                    Попередня сторінка-->
<!--                </a>-->
            </div>
<!--            <div *ngFor="let page of remoteConfigListService.paginationList" class="hidden md:-mt-px md:flex">-->
<!--                <a *ngIf="page !== '...'" [routerLink]="" queryParamsHandling="preserve" class="{{page === remoteConfigListService.currentPage ? 'border-gray-500 text-gray-600' : 'border-transparent hover:border-gray-300 hover:text-gray-700'}} text-gray-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium">-->
<!--                    {{page}}-->
<!--                </a>-->
<!--                <span *ngIf="page === '...'" class="border-transparent text-gray-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium">-->
<!--                  {{page}}-->
<!--                </span>-->
<!--            </div>-->
            <div class="-mt-px w-0 flex-1 flex justify-end">
<!--                <a [routerLink]="" queryParamsHandling="preserve" (click)="changePage(remoteConfigListService.currentPage + 1)" *ngIf="remoteConfigListService.totalPage > 1 && remoteConfigListService.currentPage !== remoteConfigListService.totalPage" class="cursor-pointer border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">-->
<!--                    Наступна сторінка-->
<!--                    <svg class="ml-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">-->
<!--                        <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />-->
<!--                    </svg>-->
<!--                </a>-->
            </div>
        </nav>
    </div>
</div>

<ng-template #orderDirectionDesc let-columnName="columnName">
  <div class="group inline-flex">
    <span class="relative top-0.5">{{columnName}}</span>
    <span class="ml-2 flex-none rounded bg-green-200 text-green-800" x-description="Active: &quot;bg-gray-200 text-gray-900 group-hover:bg-gray-300&quot;, Not Active: &quot;invisible text-gray-400 group-hover:visible group-focus:visible&quot;">
      <svg class="h-5 w-5" x-description="Heroicon name: mini/chevron-down" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd"></path>
      </svg>
    </span>
  </div>
</ng-template>

<ng-template #orderDirectionAsc let-columnName="columnName">
  <div class="group inline-flex">
    <span class="relative top-0.5">{{columnName}}</span>
    <span class="ml-2 flex-none rounded bg-green-200 text-green-800" x-description="Active: &quot;bg-gray-200 text-gray-900 group-hover:bg-gray-300&quot;, Not Active: &quot;invisible text-gray-400 group-hover:visible group-focus:visible&quot;">
      <svg class="h-5 w-5" x-description="Heroicon name: mini/chevron-down" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" transform="rotate(180)">
        <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd"></path>
      </svg>
    </span>
  </div>
</ng-template>
