import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AuthorizationService} from "../../security/authorization.service";
import {PermissionService} from "../../security/permission.service";
import {NotificationsService} from "angular2-notifications";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class UserLogoutService {

  constructor(
    private http: HttpClient,
    private authorizationService: AuthorizationService,
    private permissionService: PermissionService,
    private notificationsService: NotificationsService,
    private router: Router
  ) {}

  logout(): void {
    this.http
      .post('/api/v1/security/logout', {}, {observe: 'response'})
      .subscribe(
        (data) => {

          if (data.status === 200) {
            this.authorizationService.removeId();
            this.authorizationService.removeToken();
            this.permissionService.removePermissions();
            this.authorizationService.isStillAuthenticated();

            this.notificationsService.info('Авторизація', 'Успішний вихід', {
              timeOut: 1500,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            });

            setTimeout(() => {
              this.router.navigateByUrl('/login');
            }, 1500);
          }
        },
        error => {
          this.notificationsService.error('Помилка!', error.error.detail, {
            timeOut: 1500,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
          });
        }
      );
  }
}
