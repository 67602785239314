export enum PageTypeEnum {
  DASHBOARD = '/',
  USER_EDIT = '/user-edit',
  USER_LIST = '/user-list',
  USER_SETTINGS = '/user-settings',
  DEPLOY_CREATE = '/deploy-create/save-list',
  DEPLOY_EDIT = '/deploy-edit/deploy-list', // TODO Зробити в лівому меню
  DEPLOY_EDIT_OLD = '/deploy',
  DEPLOY_LIST = '/deploy-list',
  PROFILE = '/profile',
  OFFER_CREATE = '/offer-create/offer-list',
  OFFER_EDIT = '/offer-edit/offer-list',
  SAVE_LIST = '/save-list',
  CHEAT_SAVE_LIST = '/cheat-save-list',
  CHEAT_SAVE_CREATE = '/cheat-save-create/cheat-save-list',
  CHEAT_SAVE_EDIT = '/cheat-save-edit/cheat-save-list',
  RENOVATION_CREATE = '/renovation-create/renovation-list',
  RENOVATION_LIST = '/renovation-list',
  REMOTE_CONFIG_EDIT = '/remote-config-edit/remote-config-list',
  REMOTE_CONFIG_CREATE = '/remote-config-create/remote-config-list',
  REMOTE_CONFIG_LIST = '/remote-config-list',
  PAGE_NOT_FOUND = '/page-not-found',
}
