<div class="flex flex-col" *ngIf="player$ | async as player">
    <div class="bg-white border border-gray-200 rounded-lg p-4">
        <div class="flex flex-col">
            <div class="border-b border-gray-200">
                <div class="grid lg:grid-cols-8 sm:grid-cols-2 md:grid-cols-4 gap-4 lg:px-4 md:px-4 pb-4">
                    <div>
                        <div class="">User ID</div>
                        <div class="has-tooltip">
                            <span class='tooltip-profile'>{{ player.id}}</span>
                            <div class="text-gray-500 text-xs truncate">{{ player.id}}</div>
                        </div>
                    </div>
                    <div>
                        <div class="">Username</div>
                        <div class="has-tooltip">
                            <span class='tooltip-profile'>{{ player.username}}</span>
                            <div class="text-gray-500 text-xs truncate">{{ player.username}}</div>
                        </div>
                    </div>
                    <div>
                        <div class="">Email</div>
                        <div class="has-tooltip">
                            <span class='tooltip-profile'>{{ player.email}}</span>
                            <div class="text-gray-500 text-xs truncate">{{ player.email}}</div>
                        </div>
                    </div>
                    <div>
                        <div class="">First Seen</div>
                        <div class="text-gray-500 text-xs">{{ player.firstSeen}}</div>
                    </div>
                    <div>
                        <div class="">Last Seen</div>
                        <div class="text-gray-500 text-xs">{{ player.lastSeen}}</div>
                    </div>
                    <div>
                        <div class="">Usage Days</div>
                        <div class="text-gray-500 text-xs">{{ player.usageDays}}</div>
                    </div>
                    <div>
                        <div class="">Usage Time</div>
                        <div class="text-gray-500 text-xs">{{ player.usageTime}}</div>
                    </div>
                </div>
            </div>
            <div class="border-b border-gray-200">
                <div class="grid grid-cols-8 gap-4 p-4">
                    <div>
                        <div class="">App Name</div>
                        <div class="text-gray-500 text-xs"> Hidden City </div>
                    </div>
                    <div>
                        <div class="">Max Level</div>
                        <div class="text-gray-500 text-xs"> 9000 </div>
                    </div>
                    <div>
                        <div class="">Platform</div>
                        <div class="text-gray-500 text-xs">{{ player.gameSessions[0].platform}}</div>
                    </div>
                </div>
            </div>
            <div class="border-b border-gray-200">
                <div class="grid grid-cols-8 gap-4 p-4">
                    <div>
                        <div class="">Country</div>
                        <div class="text-gray-500 text-xs">{{ player.gameSessions[0].countryCode}}</div>
                    </div>
                    <div>
                        <div class="">Language</div>
                        <div class="text-gray-500 text-xs">{{ player.gameSessions[0].languageCode}}</div>
                    </div>
                    <div>
                        <div class="">Connection Type</div>
                        <div class="text-gray-500 text-xs">{{ player.gameSessions[0].connectionType}}</div>
                    </div>
                </div>
            </div>
            <div class="border-b border-gray-200">
                <div class="grid grid-cols-8 gap-4 p-4">
                    <div>
                        <div class="">Device Type</div>
                        <div class="text-gray-500 text-xs">{{ player.gameSessions[0].deviceName}}</div>
                    </div>
                    <div>
                        <div class="">OS Version</div>
                        <div class="text-gray-500 text-xs">{{ player.gameSessions[0].osVersion}}</div>
                    </div>
                    <div>
                        <div class="">FW Version</div>
                        <div class="text-gray-500 text-xs">{{ player.gameSessions[0].fwVersion}}</div>
                    </div>
                </div>
            </div>

            <div class="border-b border-gray-200">
                <div class="grid grid-cols-8 gap-4 p-4">
                    <div>
                        <div class="">Payment Profile</div>
                        <div class="text-gray-500 text-xs"> - </div>
                    </div>
                    <div>
                        <div class="">Total Spent</div>
                        <div class="text-gray-500 text-xs"> - </div>
                    </div>
                    <div>
                        <div class="">Purchases</div>
                        <div class="text-gray-500 text-xs"> - </div>
                    </div>
                    <div>
                        <div class="">Median Purchase</div>
                        <div class="text-gray-500 text-xs"> - </div>
                    </div>
                    <div>
                        <div class="">Last Purchase</div>
                        <div class="text-gray-500 text-xs"> - </div>
                    </div>
                </div>
            </div>
            <div class="">
                <div class="grid grid-cols-8 gap-4 px-4 pt-4">
                    <div>
                        <div class="">Avatar</div>
                        <div class="text-gray-500 text-xs"> jpg </div>
                    </div>
                    <div>
                        <div class="">Nickname</div>
                        <div class="text-gray-500 text-xs"> - </div>
                    </div>
                    <div>
                        <div class="">Sex</div>
                        <div class="text-gray-500 text-xs">{{ player.sex }}</div>
                    </div>
                    <div>
                        <div class="">Age</div>
                        <div class="text-gray-500 text-xs">{{ player.age }}</div>
                    </div>
                </div>
            </div>
            <!--            <div class="grid grid-cols-2">-->
            <!--                <div>-->
            <!--                    <div class="flex flex-row text-gray-500 hover:text-blue-500 p-4">-->
            <!--                        <svg *ngIf="!isShowingAdditionalProfileParams" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">-->
            <!--                            <path fill-rule="evenodd" d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z" clip-rule="evenodd" />-->
            <!--                        </svg>-->
            <!--                        <svg *ngIf="isShowingAdditionalProfileParams" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">-->
            <!--                            <path fill-rule="evenodd" d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />-->
            <!--                        </svg>-->
            <!--                        <button class="text-xs pl-2 pt-0.5" (click)="isShowingAdditionalProfileParams=!isShowingAdditionalProfileParams">Дополнительные параметры профиля</button>-->
            <!--                    </div>-->
            <!--                    <div *ngIf="isShowingAdditionalProfileParams" class="border-t border-gray-200 px-4 pb-4">-->


            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->
        </div>
    </div>
    <div class="bg-white border border-gray-200 rounded-lg p-4 mt-10">
        <div class="border-b border-gray-200">
            <div class="flex flex-row text-gray-500 hover:text-blue-500 px-4 pb-4">
                <svg *ngIf="!isShowingAllPlayerDevices" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <svg *ngIf="isShowingAllPlayerDevices" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                </svg>
                <button *ngIf="!isShowingAllPlayerDevices" class="text-xs pl-2 pt-0.5" (click)="isShowingAllPlayerDevices=!isShowingAllPlayerDevices">Показать все девайсы игрока</button>
                <button *ngIf="isShowingAllPlayerDevices" class="text-xs pl-2 pt-0.5" (click)="isShowingAllPlayerDevices=!isShowingAllPlayerDevices">
                Показать последний используемый девайс игрока</button>
            </div>
            <table class="table-fixed divide-y divide-gray-200">
                <thead class="bg-gray-50">
                <tr>
                    <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Device ID
                    </th>
                    <th scope="col" class="w-1/6 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Device Type
                    </th>
                    <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Platform
                    </th>
                    <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        First Seen
                    </th>
                    <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Last Seen
                    </th>
                    <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        OS Version
                    </th>
                    <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        FW Version
                    </th>
                    <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Connection Type
                    </th>
                    <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Payment Profile
                    </th>
                </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                <tr *ngFor="let gameSession of player.devices; index as i" [ngClass]="{'hidden': !isShowingAllPlayerDevices && i > 0}" >
                    <td class="px-6 py-4 text-sm text-gray-900">
                        {{ gameSession.deviceId }}
                    </td>
                    <td class="px-6 py-4 text-sm text-gray-900">
                        {{ gameSession.deviceName }}
                    </td>
                    <td class="px-6 py-4 text-sm text-gray-900">
                        {{ gameSession.platform }}
                    </td>
                    <td class="px-6 py-4 text-sm text-gray-900">
                        {{ gameSession.firstSeen }}
                    </td>
                    <td class="px-6 py-4 text-sm text-gray-900">
                        {{ gameSession.lastSeen }}
                    </td>
                    <td class="px-6 py-4 text-sm text-gray-900">
                        {{ gameSession.osVersion }}
                    </td>
                    <td class="max-w-xs px-6 py-4 text-sm text-gray-900">
                        {{ gameSession.fwVersion }}
                    </td>
                    <td class="px-6 py-4 text-sm text-gray-900">
                        {{ gameSession.connectionType }}
                    </td>
                    <td class="px-6 py-4 text-sm text-gray-900">
                        -
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="bg-white border border-gray-200 rounded-lg p-4 mt-10">
        <div class="border-b border-gray-200">
            <div class="flex flex-row text-gray-500 px-4 pb-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
                </svg>
                <span class="text-xs pl-2 pt-0.5">Активные приложения игрока</span>
            </div>
            <table class="table-fixed divide-y divide-gray-200">
                <thead class="bg-gray-50">
                <tr>
                    <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        App Name
                    </th>
                    <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        First Seen
                    </th>
                    <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Last Seen
                    </th>
                    <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Language
                    </th>
                    <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Max Level
                    </th>
                    <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Profiles
                    </th>
                    <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Connection Type
                    </th>
                    <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Payment Profile
                    </th>
                </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                <tr *ngFor="let gameSession of player.apps; index as i">
                    <td class="px-6 py-4 text-sm text-gray-900">
                        <a routerLink="/player/{{player.id}}/profile/victorian-quest" routerLinkActive="active" class="text-blue-500 hover:text-blue-700">Victorian Quest</a>
                        <!-- TODO Hardcoded app name  -->
                    </td>
                    <td class="px-6 py-4 text-sm text-gray-900">
                        {{ gameSession.firstSeen }}
                    </td>
                    <td class="px-6 py-4 text-sm text-gray-900">
                        {{ gameSession.lastSeen }}
                    </td>
                    <td class="px-6 py-4 text-sm text-gray-900">
                        {{ gameSession.languageCode }}
                    </td>
                    <td class="px-6 py-4 text-sm text-gray-900">
                        9000
                    </td>
                    <td class="px-6 py-4 text-sm text-gray-900">
                        -
                    </td>
                    <td class="max-w-xs px-6 py-4 text-sm text-gray-900">
                        {{ gameSession.connectionType }}
                    </td>
                    <td class="px-6 py-4 text-sm text-gray-900">
                        -
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
<!--    <div class="bg-white border border-gray-200 rounded-lg p-4 mt-10">-->
<!--        <div class="grid grid-cols-2 gap-4">-->
<!--            <div>-->
<!--                <div class="text-center p-2.5">A/B Groups</div>-->
<!--                <table class="w-full table-fixed text-sm text-gray-500">-->
<!--                    <thead>-->
<!--                    <tr>-->
<!--                        <th class="w-1/2" style="display: none"></th>-->
<!--                        <th class="w-1/2" style="display: none"></th>-->
<!--                    </tr>-->
<!--                    </thead>-->
<!--                    <tbody>-->
<!--                    <tr>-->
<!--                        <td class="border-t border-gray-200 pl-4">A/B_test_group 1</td>-->
<!--                        <td class="border-t border-gray-200 additional-params bg-gray-50" *ngIf="!isEditModeEnabledForAdditionalProfileParams">-->
<!--                            - -->
<!--                        </td>-->
<!--                        <td class="border-t border-gray-200" *ngIf="isEditModeEnabledForAdditionalProfileParams">-->
<!--                            <input formControlName="1"-->
<!--                                   id="1"-->
<!--                                   type="text"-->
<!--                                   class="additional-params-input block w-full text-sm border-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"-->
<!--                                   placeholder="Login Group">-->
<!--                        </td>-->
<!--                    </tr>-->
<!--                    <tr>-->
<!--                        <td class="border-t border-gray-200 pl-4">A/B_test_group 2</td>-->
<!--                        <td class="border-t border-gray-200 additional-params bg-gray-50" *ngIf="!isEditModeEnabledForAdditionalProfileParams">-->
<!--                            - -->
<!--                        </td>-->
<!--                        <td class="border-t border-gray-200 bg-green-50" *ngIf="isEditModeEnabledForAdditionalProfileParams">-->
<!--                            <input formControlName="2"-->
<!--                                   id="2"-->
<!--                                   type="text"-->
<!--                                   class="additional-params-input bg-green-50 block w-full text-sm border-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"-->
<!--                                   placeholder="18">-->
<!--                        </td>-->
<!--                    </tr>-->
<!--                    <tr>-->
<!--                        <td class="border-t border-gray-200">Communication_joined</td>-->
<!--                        <td class="border-t border-gray-200 additional-params bg-gray-50" *ngIf="!isEditModeEnabledForAdditionalProfileParams">297</td>-->
<!--                        <td class="border-t border-gray-200 bg-red-50" *ngIf="isEditModeEnabledForAdditionalProfileParams">-->
<!--                            <input formControlName="3"-->
<!--                                   id="3"-->
<!--                                   type="text"-->
<!--                                   class="additional-params-input bg-red-50 block w-full text-sm border-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"-->
<!--                                   placeholder="297">-->
<!--                        </td>-->
<!--                    </tr>-->
<!--                    <tr>-->
<!--                        <td class="border-t border-gray-200">Gender</td>-->
<!--                        <td class="border-t border-gray-200 additional-params bg-gray-50" *ngIf="!isEditModeEnabledForAdditionalProfileParams">Female</td>-->
<!--                        <td class="border-t border-gray-200" *ngIf="isEditModeEnabledForAdditionalProfileParams">-->
<!--                            <select formControlName="4" id="4" class="block w-full text-sm text-gray-500 border-none focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">-->
<!--                                <option selected>Female</option>-->
<!--                                <option>Male</option>-->
<!--                            </select>-->
<!--                        </td>-->
<!--                    </tr>-->
<!--                    </tbody>-->
<!--                </table>-->
<!--                <button class="mt-4 py-2 px-4 bg-blue-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-blue-500" *ngIf="!isEditModeEnabledForAdditionalProfileParams" (click)="isEditModeEnabledForAdditionalProfileParams=!isEditModeEnabledForAdditionalProfileParams">Edit</button>-->
<!--                <button class="mt-4 py-2 px-4 bg-green-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-green-500" *ngIf="isEditModeEnabledForAdditionalProfileParams">Save</button>-->
<!--                <button class="mt-4 py-2 px-4 bg-red-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-red-500" *ngIf="isEditModeEnabledForAdditionalProfileParams" (click)="isEditModeEnabledForAdditionalProfileParams=!isEditModeEnabledForAdditionalProfileParams">Decline</button>-->
<!--            </div>-->
<!--            <div>-->
<!--                <div class="text-center p-2.5">Another Groups</div>-->
<!--                <table class="w-full table-fixed text-sm text-gray-500">-->
<!--                    <thead>-->
<!--                    <tr>-->
<!--                        <th class="w-1/2" style="display: none"></th>-->
<!--                        <th class="w-1/2" style="display: none"></th>-->
<!--                    </tr>-->
<!--                    </thead>-->
<!--                    <tbody>-->
<!--                    <tr>-->
<!--                        <td class="border-t border-gray-200 pl-4">Login Group</td>-->
<!--                        <td class="border-t border-gray-200 additional-params bg-gray-50" *ngIf="!isEditModeEnabledForAdditionalProfileParams">-->
<!--                            - -->
<!--                        </td>-->
<!--                        <td class="border-t border-gray-200" *ngIf="isEditModeEnabledForAdditionalProfileParams">-->
<!--                            <input formControlName="1"-->
<!--                                   id="1"-->
<!--                                   type="text"-->
<!--                                   class="additional-params-input block w-full text-sm border-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"-->
<!--                                   placeholder="Login Group">-->
<!--                        </td>-->
<!--                    </tr>-->
<!--                    <tr>-->
<!--                        <td class="border-t border-gray-200 pl-4">Age</td>-->
<!--                        <td class="border-t border-gray-200 additional-params bg-gray-50" *ngIf="!isEditModeEnabledForAdditionalProfileParams">-->
<!--                            18-->
<!--                        </td>-->
<!--                        <td class="border-t border-gray-200 bg-green-50" *ngIf="isEditModeEnabledForAdditionalProfileParams">-->
<!--                            <input formControlName="2"-->
<!--                                   id="2"-->
<!--                                   type="text"-->
<!--                                   class="additional-params-input bg-green-50 block w-full text-sm border-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"-->
<!--                                   placeholder="18">-->
<!--                        </td>-->
<!--                    </tr>-->
<!--                    <tr>-->
<!--                        <td class="border-t border-gray-200 pl-4">Communication_joined</td>-->
<!--                        <td class="border-t border-gray-200 additional-params bg-gray-50" *ngIf="!isEditModeEnabledForAdditionalProfileParams">297</td>-->
<!--                        <td class="border-t border-gray-200 bg-red-50" *ngIf="isEditModeEnabledForAdditionalProfileParams">-->
<!--                            <input formControlName="3"-->
<!--                                   id="3"-->
<!--                                   type="text"-->
<!--                                   class="additional-params-input bg-red-50 block w-full text-sm border-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"-->
<!--                                   placeholder="297">-->
<!--                        </td>-->
<!--                    </tr>-->
<!--                    <tr>-->
<!--                        <td class="border-t border-gray-200 pl-4">Gender</td>-->
<!--                        <td class="border-t border-gray-200 additional-params bg-gray-50" *ngIf="!isEditModeEnabledForAdditionalProfileParams">Female</td>-->
<!--                        <td class="border-t border-gray-200" *ngIf="isEditModeEnabledForAdditionalProfileParams">-->
<!--                            <select formControlName="4" id="4" class="block w-full text-sm text-gray-500 border-none focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">-->
<!--                                <option selected>Female</option>-->
<!--                                <option>Male</option>-->
<!--                            </select>-->
<!--                        </td>-->
<!--                    </tr>-->
<!--                    </tbody>-->
<!--                </table>-->
<!--                <button class="mt-4 py-2 px-4 bg-blue-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-blue-500" *ngIf="!isEditModeEnabledForAdditionalProfileParams" (click)="isEditModeEnabledForAdditionalProfileParams=!isEditModeEnabledForAdditionalProfileParams">Edit</button>-->
<!--                <button class="mt-4 py-2 px-4 bg-green-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-green-500" *ngIf="isEditModeEnabledForAdditionalProfileParams">Save</button>-->
<!--                <button class="mt-4 py-2 px-4 bg-red-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-red-500" *ngIf="isEditModeEnabledForAdditionalProfileParams" (click)="isEditModeEnabledForAdditionalProfileParams=!isEditModeEnabledForAdditionalProfileParams">Decline</button>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
    <div class="bg-white border border-gray-200 rounded-lg px-4 mt-10">
        <div>
            <div class="flex flex-row text-gray-500 hover:text-blue-500 p-4">
                <svg *ngIf="!isShowingProfileActions" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <svg *ngIf="isShowingProfileActions" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                </svg>
                <button class="text-xs pl-2 pt-0.5" (click)="isShowingProfileActions=!isShowingProfileActions">Операции с аккаунтом игрока</button>
            </div>
            <div *ngIf="isShowingProfileActions" class="border-t border-gray-200 p-4">
                <button class="py-2 px-4 bg-red-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-red-500">Disable profile</button>
            </div>
        </div>
    </div>
</div>
