<div class="lg:w-5/6 mx-auto flex flex-col" *ngIf="list$ | async as logs">
    <div class="bg-white border border-gray-200 rounded-lg p-4">
        <div class="border-b border-gray-200">
            <table class="table-fixed divide-y divide-gray-200">
                <thead class="bg-gray-50">
                <tr>
                    <th scope="col" class="w-1/12 h-10 px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Логи приложения по дням
                    </th>
                </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                <tr *ngFor="let filename of logs;">
                    <td class="h-10 px-6 py-2 text-sm text-gray-900">
                        <a routerLink="/application-log/{{filename}}" routerLinkActive="active" class="text-blue-500 hover:text-blue-700">{{filename}}</a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
