import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {SectionKeyName, SectionsKeyNamesList} from "../../players/section-type";
import {
  ProfileVictorianQuestService
} from "../../players/profiles/profile-victorian-quest/profile-victorian-quest.service";
import {trans} from "../../../translations";
import {SectionsKeyMappingService} from "./sections-key-mapping.service";
import {NotificationsService} from "angular2-notifications";
import {Observable} from "rxjs";
import {SectionKeyMapping} from "../section-key-mapping";

@Component({
  selector: 'app-sections-values-mapping',
  templateUrl: './sections-values-mapping.component.html',
  styleUrls: ['./sections-values-mapping.component.scss']
})
export class SectionsValuesMappingComponent implements OnInit {

  sectionsValues: SectionKeyName[] = [];
  mappedSectionsList$: Observable<SectionKeyMapping[]>;

  searchSectionsKeyNameFormDefaults = {
    sectionsValueId: trans('Select item'),
    mappedSectionsName: null,
    mappedSectionsDescription: null,
  }

  searchSectionsKeyNameForm = new FormGroup({
    'sectionsValueId': new FormControl(this.searchSectionsKeyNameFormDefaults.sectionsValueId, [Validators.required]),
    'mappedSectionsName': new FormControl(this.searchSectionsKeyNameFormDefaults.mappedSectionsName, [
      Validators.required,
      Validators.pattern('[a-zA-Z0-9 ._-]*'),
      Validators.minLength(3),
      Validators.maxLength(255),
    ]),
    'mappedSectionsDescription': new FormControl(this.searchSectionsKeyNameFormDefaults.mappedSectionsName, [
      Validators.pattern('[a-zA-Z0-9 ._-]*'),
      Validators.minLength(3),
      Validators.maxLength(4095),
    ]),
  });

  constructor(
    private profileVictorianQuestService: ProfileVictorianQuestService,
    private sectionsKeyMappingService: SectionsKeyMappingService,
    private notificationsService: NotificationsService,
  ) {
    this.mappedSectionsList$ = this.sectionsKeyMappingService.mappedSections$;
  }

  ngOnInit(): void {
    this.fetchSectionKeyMappingList();
  }

  fetchSectionKeyMappingList(): void {
    this.sectionsKeyMappingService.getSectionKeyMappingList().subscribe(
      (data) => {
        if (data.body !== null) {
          this.sectionsKeyMappingService.setMappedSections(data.body);
        }
    });
  }

  searchSectionsValues(e: { term: string }) {
    if (e.term.length > 2) {
      this.profileVictorianQuestService.getSectionsKeyNamesList(e.term).subscribe(
        (data) => {
          this.sectionsValues = data.body !== null ? this.getAllSectionsValues(data.body) : [];
          console.log(this.sectionsValues);
        },
        () => {
          this.sectionsValues = [];
        });
    } else {
      this.sectionsValues = [];
    }
  }

  getAllSectionsValues(sectionsKeyNamesList: SectionsKeyNamesList) {
    return [
      ...sectionsKeyNamesList.sectionsBinaryNames,
      ...sectionsKeyNamesList.sectionsBoolNames,
      ...sectionsKeyNamesList.sectionsDatetimeNames,
      ...sectionsKeyNamesList.sectionsFloatNames,
      ...sectionsKeyNamesList.sectionsInt64Names,
      ...sectionsKeyNamesList.sectionsIntNames,
      ...sectionsKeyNamesList.sectionsStringNames,
      ...sectionsKeyNamesList.sectionsNamesWithoutValues
    ];
  }

  isSelectedSectionsKeyName() {
    const sectionsValueId = this.searchSectionsKeyNameForm.controls['sectionsValueId'].value
    return sectionsValueId !== null && sectionsValueId !== this.searchSectionsKeyNameFormDefaults.sectionsValueId
  }

  isValidMappedSectionsName(): boolean {
    if (!this.searchSectionsKeyNameForm.controls['mappedSectionsName'].touched || !this.searchSectionsKeyNameForm.controls['mappedSectionsName'].dirty) {
      return true;
    }

    return !this.searchSectionsKeyNameForm.controls['mappedSectionsName'].invalid;
  }

  isShowingSaveButton() {
    return this.isSelectedSectionsKeyName()
      && !this.searchSectionsKeyNameForm.controls['mappedSectionsName'].invalid
      && !this.searchSectionsKeyNameForm.controls['mappedSectionsDescription'].invalid;
  }

  isShowingDeclineButton() {
    const formValues = {...this.searchSectionsKeyNameForm.value};

    return formValues.sectionsValueId !== this.searchSectionsKeyNameFormDefaults.sectionsValueId
      || formValues.mappedSectionsName !== this.searchSectionsKeyNameFormDefaults.mappedSectionsName
      || formValues.mappedSectionsDescription !== this.searchSectionsKeyNameFormDefaults.mappedSectionsDescription
  }

  resetForm() {
    this.searchSectionsKeyNameForm.reset(this.searchSectionsKeyNameFormDefaults);
  }

  saveMappedSection() {
    if (this.searchSectionsKeyNameForm.invalid) {
      return;
    }

    let formValues = {...this.searchSectionsKeyNameForm.value};

    this.sectionsKeyMappingService.createSectionKeyMapping(formValues.mappedSectionsName, formValues.sectionsValueId, formValues.mappedSectionsDescription).subscribe(
      (data) => {
        if (data.status === 201) {
          this.fetchSectionKeyMappingList();
          this.resetForm();
          this.notificationsService.success('Success', 'Section mapping saved', {
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
          });
        }
      }, (data) => {
        if (data.status !== 201) {
          this.notificationsService.error('Error', 'Failed saving section mapping', {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
          });
        }
      });
  }
}
