export enum SectionType {
  BINARY = 'binary',
  BOOL = 'bool',
  DATETIME = 'datetime',
  FLOAT = 'float',
  INT64 = 'int64',
  INT = 'int',
  STRING = 'string',
  NULL = 'null'
}

export interface SectionsValues {
  id: number;
  sectionsValueId: number;
  name: string;
  value: any;
  transactionId: number;
  type: SectionType
}

export interface SectionsKeyNamesList {
  sectionsBinaryNames: SectionKeyName[];
  sectionsBoolNames: SectionKeyName[];
  sectionsDatetimeNames: SectionKeyName[];
  sectionsFloatNames: SectionKeyName[];
  sectionsInt64Names: SectionKeyName[];
  sectionsIntNames: SectionKeyName[];
  sectionsStringNames: SectionKeyName[];
  sectionsNamesWithoutValues: SectionKeyName[];
}

export interface SectionKeyName {
  id: number;
  name: string;
  type: SectionType;
}
