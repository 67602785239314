import { Component, OnInit } from '@angular/core';
import {UserLogoutService} from "./user-logout.service";
import {AppService} from "../../app.service";

@Component({
  selector: 'app-user-logout',
  templateUrl: './user-logout.component.html',
  styleUrls: ['./user-logout.component.scss']
})
export class UserLogoutComponent implements OnInit {

  constructor(
    public appService: AppService,
    private userLogoutService: UserLogoutService
  ) { }

  ngOnInit(): void {
  }

  logoutUser() {
    this.userLogoutService.logout();
  }
}
