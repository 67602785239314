import { Injectable } from '@angular/core';
import {Observable, Subject} from "rxjs";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {User} from "../user";
import {ActivatedRoute} from "@angular/router";
import {AppService} from "../../app.service";

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  private _user$ = new Subject<User>();

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    public appService: AppService
  ) {

  }



  getUser(id:string|number): Observable<HttpResponse<User>> {
    return this.http.get<User>('/api/v1/ui/user/' + id + '/profile', {
      observe: 'response',
      withCredentials: true
    });
  }

  setUser(user: User) {
    this._user$.next(user);
  }

  get user$(): Subject<User> {
    return this._user$;
  }
}
