import {SectionType} from "../players/section-type";

export interface DeployName {
  id: number
  name: string
}

export interface Deploy {
  id: number
  name: string
  userId: number
  state: DeployState
  changes: Section[]|null
  createdAt: Date
  updatedAt: Date|null
}

export interface Section {
  playerId: number
  operationType: OperationType,
  sectionType: SectionType,
  keyId: number,
  keyName: string|null,
  value: any,
}

export interface SectionsValuesChanges {
  playerId: number|undefined,
  operationType: OperationType,
  sectionsValueId: number,
  sectionsValueName: string|undefined,
  sectionsValueType: SectionType,
  sectionsValue: any
}

export interface DeployForList {
  id: number
  name: string
  state: DeployState
  changesCount: number
  createdAt: Date
  updatedAt: Date|null
}

export enum OperationType {
  ADD = 'add',
  SET = 'set'
}

export enum DeployState {
  initial = 'initial',
  applied = 'applied',
  declined = 'declined',
  reverted = 'reverted'
}

export interface ConflictedSectionsToReverting {
  [key: string]: {
    before: ConflictedSectionValue,
    deployed: SectionsValuesChanges,
    actual: ConflictedSectionValue,
  }
}

export enum ConflictedSectionsOptions {
  before = 'before',
  actual = 'actual'
}

export interface ConflictedSectionValue {
  playerId: number,
  keyId: number,
  keyName: string,
  sectionType: SectionType,
  operationType: OperationType
  value: any,
  selected: boolean,
}
