import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs";
import {PermissionService} from "../../security/permission.service";
import {DeployListService} from "./deploy-list.service";
import {Deploy} from "./deploy";
import {DeployState} from "../deploy/deploy";
import {AppService} from "../../app.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-deploy-list',
  templateUrl: './deploy-list.component.html',
  styleUrls: ['./deploy-list.component.scss']
})
export class DeployListComponent implements OnInit {

  deploys$: Observable<Deploy[]>;
  from = 0;
  to = 30;
  total = 0;
  limit = 30;
  totalPage = 1;
  currentPage = 1;
  pagination: any[] = [];

  orderByColumn = "created_at"
  orderDirection = "DESC"

  constructor(
    private appService: AppService,
    private route: ActivatedRoute,
    private deployListService: DeployListService,
    private permissionService: PermissionService
  ) {
    this.deploys$ = this.deployListService.deploys$;
  }

  ngOnInit(): void {
    const type = this.route.snapshot.data['type'];
    this.appService.setPageType$(type)
    this.permissionService.loadPermissions();
    this.fetchDeployList();
  }

  fetchDeployList() {
    this.deployListService.getDeployList(this.from, this.to, this.orderByColumn, this.orderDirection).subscribe(
      (data) => {
        if (data.body !== null) {
          this.deployListService.setDeploys(data.body);
          const rangeHeader = data.headers.get('Content-Range');
          if (rangeHeader !== null) {
            const nameWithRange = rangeHeader.split('/');
            this.total = Number(nameWithRange[1]);
            this.totalPage = this.total > this.to ? Math.ceil(this.total / this.limit) : this.totalPage;
            const StringRange = nameWithRange[0].split(' ')[1];
            const ranges = StringRange.split('-');
            this.from = Number(ranges[0]);
            this.to = Number(ranges[1]);
            this.pagination = this.getPaginationList(this.currentPage, this.totalPage)
          }
        }
      }
    );
  }

  changePage(page: number) {
    this.currentPage = page;

    if (this.totalPage === 1) {
      return;
    }

    this.from = this.limit * (page - 1);
    this.to = this.limit * page;

    if (this.to > this.total) {
      this.to = this.total;
    }

    this.fetchDeployList();
  }

  getPaginationList(currentPage: number = 1, totalPage: number = 1, pageRange: number = 1) {
    let pagination: any[] = [];

    if (totalPage <= 7) {
      for (let i = 1; i <= totalPage; i++) {
        pagination.push(i);
      }

      return pagination;
    }

    let rangeStart = currentPage - pageRange;
    let rangeEnd = currentPage + pageRange;

    if (rangeEnd > totalPage) {
      rangeEnd = totalPage;
      rangeStart = totalPage - pageRange * 2;
      rangeStart = rangeStart < 1 ? 1 : rangeStart;
    }

    if (rangeStart <= 1) {
      rangeStart = 1;
      rangeEnd = Math.min(pageRange * 2 + 1, totalPage);
    }

    if (rangeStart <= 3) {
      for (let i = 1; i < rangeStart; i++) {
        pagination.push(i);
      }
    } else {
      pagination = [1, '...'];
    }

    for (let i = rangeStart; i <= rangeEnd; i++) {
      pagination.push(i);
    }

    if (rangeEnd >= totalPage - 2) {
      for (let i = rangeEnd + 1; i <= totalPage; i++) {
        pagination.push(i);
      }
    } else {
      pagination.push('...', totalPage);
    }

    return pagination;
  }

  getRatioPlayers(deploy : Deploy): string {
    if (deploy.appliedPlayers === null || deploy.totalPlayers === null) {
      return "0%"
    }

    if (deploy.appliedPlayers === 0 && deploy.totalPlayers === 0) {
      return ""
    }

    const ratio = (deploy.appliedPlayers / deploy.totalPlayers) * 100

    if (ratio === 0) {
      return "0%"
    }

    const roundedRatio = ratio % 1 !== 0 ? ratio.toFixed(2) : ratio;

    return roundedRatio + "%"
  }

  getBackgroundColorForDeployState(state: DeployState): string {
    switch (state) {
      case DeployState.initial: return 'blue'
      case DeployState.applied: return 'green'
      case DeployState.declined: return 'red'
      case DeployState.reverted: return 'yellow'
      case DeployState.deleted: return 'red'
    }
  }

  changeOrderBy(columnName: string) {
    if (this.orderByColumn !== columnName) {
      this.orderByColumn = columnName;
      this.orderDirection = "DESC";
      this.fetchDeployList();

      return;
    }

    if (this.orderByColumn == columnName && this.orderDirection === "ASC") {
      this.orderByColumn = "created_at";
      this.orderDirection = "DESC";
      this.fetchDeployList();

      return;
    }

    if (this.orderByColumn == columnName) {
      this.orderDirection = "ASC";
      this.fetchDeployList();

      return;
    }
  }
}
