<div class="flex flex-col" *ngIf="list$ | async as userList">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full lg:px-8">
            <div class="overflow-hidden border border-gray-200 sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                    <tr>
                        <th scope="col" class="h-10 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Id
                        </th>
                        <th scope="col" class="h-10 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Name
                        </th>
                        <th scope="col" class="h-10 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Email
                        </th>
                        <th scope="col" class="h-10 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Status
                        </th>
                        <th scope="col" class="h-10 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Role
                        </th>
                    </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                    <tr *ngFor="let user of userList; index as i">
                        <td class="h-10 px-6 whitespace-nowrap">
                            <a routerLink="/user/{{user.id}}/profile" routerLinkActive="active" class="text-sm text-blue-500 hover:text-blue-700">{{user.id}}</a>
                        </td>
                        <td class="h-10 px-6 whitespace-nowrap">
<!--                            <div class="flex items-center">-->
<!--                                <div class="flex-shrink-0 h-10 w-10">-->
<!--                                    <img class="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60" alt="">-->
<!--                                </div>-->
<!--                                <div class="ml-4">-->
<!--                                    <div class="text-sm text-gray-900">-->
<!--                                        {{user.username}}-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
                            <div class="text-sm text-gray-900">
                                {{user.username}}
                            </div>
                        </td>
                        <td class="h-10 px-6 whitespace-nowrap">
                            <div class="text-sm text-gray-900">{{user.email}}</div>
                        </td>
                        <td class="h-10 px-6 whitespace-nowrap">
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                              Active
                            </span>
<!--                            <span *ngIf="i === 3" class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">-->
<!--                              Disabled-->
<!--                            </span>-->
                        </td>
                        <td class="h-10 px-6 whitespace-nowrap text-sm text-gray-900">
                            {{user.roles.join(", ")}}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
