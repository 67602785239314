import { Injectable } from '@angular/core';
import {Observable, Subject} from "rxjs";
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {CheatSave} from "./cheat-save";
import {PaginationService} from "../../common/pagination-service";
import {PlayerSavesMetadataByDay, SaveMetadata} from "../save-list/save";

@Injectable({
  providedIn: 'root'
})
export class CheatSaveListModalService extends PaginationService {
  private _cheatSaves: CheatSave[] = [];
  private _playerSaveMetadataByDay: PlayerSavesMetadataByDay|null = null;
  private _selectedPlayerId: string|null = null

  public playerSavesByDayModalHeight: string = 'max-h-80'
  public isMovingPlayerSaveToCheatSave = false;
  public isMovingCheatSaveToPlayerSave = false;

  constructor(private http: HttpClient) {
    super(0, 50, 50);
  }

  getCheatSaveListModal(): Observable<HttpResponse<CheatSave[]>> {
    return this.http.get<CheatSave[]>('/api/v1/ui/cheat-save-list', {
      headers: new HttpHeaders({
        'Range': 'cheat-saves=' + this.from + '-' + this.to,
      }),
      params: {
        from: this.from.toString(),
        to: this.to.toString(),
        orderBy: this.orderByColumn,
        orderDirection: this.orderDirection
      },
      observe: 'response',
      withCredentials: true
    });
  }

  setCheatSaves(cheatSaves: CheatSave[]) {
    this._cheatSaves = cheatSaves;
  }

  get cheatSaves(): CheatSave[] {
    return this._cheatSaves;
  }

  get playerSaveMetadataByDay(): PlayerSavesMetadataByDay | null {
    return this._playerSaveMetadataByDay;
  }

  set playerSaveMetadataByDay(value: PlayerSavesMetadataByDay | null) {
    this._playerSaveMetadataByDay = value;
  }

  setSelectedPlayerId(selectedPlayer: string) {
    this._selectedPlayerId = selectedPlayer
  }

  get selectedPlayerId(): string | null {
    return this._selectedPlayerId;
  }

  changeModalHeightForPlayerSaves(saves: SaveMetadata[]): void {
    if (saves.length > 6) {
      this.playerSavesByDayModalHeight = 'h-80'
    } else {
      this.playerSavesByDayModalHeight = 'max-h-80'
    }
  }

  exportPlayerSaveToCheatSave(selectedCheatSave: CheatSave, playerSaveMetadata: SaveMetadata): Observable<HttpResponse<any>> {
    return this.http.patch<any>('/api/v1/ui/cheat-save/' + selectedCheatSave.id + '/update-save-data', {
      "playerId": this._selectedPlayerId,
      "appVersion": playerSaveMetadata.appVersion,
      "dbSaveId": playerSaveMetadata.id // У кеш сейву немає id, тож дане поле не відправиться
    }, {
      observe: 'response',
      withCredentials: true
    });
  }

  importCheatSaveToPlayerSave(selectedCheatSave: CheatSave): Observable<HttpResponse<any>> {
    return this.http.patch<any>('/api/v1/ui/cheat-save/' + selectedCheatSave.id + '/import-to-player', {
      "playerId": this._selectedPlayerId
    }, {
      observe: 'response',
      withCredentials: true
    });
  }
}
