<div class="flex flex-col" *ngIf="logs$ | async as logs">
    <div class="bg-white border border-gray-200 rounded-lg p-4">
        <div class="flex flex-row text-gray-500 px-4 pb-4">
            <span class="text-xs pl-2 pt-0.5 mr-8 ">{{ logs[0]?.requestType | uppercase }} : {{ logs[0]?.requestId }} : {{ logs[0]?.userId }}</span>
        </div>
        <div class="-my-2 overflow-x-auto">
            <div class="py-2 align-middle inline-block min-w-full">
                <div class="overflow-hidden">
                    <table class="table-fixed divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                        <tr>
                            <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Время отправки запроса
                            </th>
                            <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Время логирования сервера
                            </th>
                            <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Уроверь
                            </th>
                            <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Сообщение
                            </th>
                            <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Контекст
                            </th>
                        </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                        <tr *ngFor="let log of logs;">
                            <td class="px-6 py-4 text-sm text-gray-900">
                                {{ log.requestDate | date:'Y-MM-d H:mm:s z' }}
                            </td>
                            <td class="px-6 py-4 text-sm text-gray-900">
                                {{ log.serverDate | date:'Y-MM-d H:mm:s.SSS z' }}
                            </td>
                            <td class="px-6 py-4 text-sm text-gray-900">
                                <span *ngIf="log.channelLevelName === 'app.DEBUG'" class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">{{ log.channelLevelName }}</span>
                                <span *ngIf="log.channelLevelName === 'app.INFO' || log.channelLevelName === 'app.NOTICE'" class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">{{ log.channelLevelName }}</span>
                                <span *ngIf="log.channelLevelName === 'app.WARNING'" class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">{{ log.channelLevelName }}</span>
                                <span *ngIf="log.channelLevelName === 'app.ERROR' || log.channelLevelName === 'app.CRITICAL' || log.channelLevelName === 'app.ALERT' || log.channelLevelName === 'app.EMERGENCY'" class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">{{ log.channelLevelName }}</span>
                            </td>
                            <td class="px-6 py-4 text-sm text-gray-900">
                                {{ log.message }}
                            </td>
                            <td class="max-w-xs px-6 py-4 text-sm text-blue-500">
                                <button *ngIf="log.raw === null" type="button" (click)="showLogContextModal(log.context)">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                    </svg>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- This example requires Tailwind CSS v2.0+ -->
<div *ngIf="isShowingLogContextModal" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <!--
          Background overlay, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <!--
          Modal panel, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        -->
        <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-x-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-7xl sm:w-full sm:p-6">
            <div class="sm:block absolute top-0 right-0 pt-4 pr-4">
                <button (click)="isShowingLogContextModal=!isShowingLogContextModal" type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span class="sr-only">Close</span>
                    <!-- Heroicon name: outline/x -->
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>

            <pre class="text-sm" [innerHTML]="currentActiveLogContext | prettyprint"></pre>
            <div class="mt-5 sm:mt-6 text-center">
                <button (click)="isShowingLogContextModal=!isShowingLogContextModal" type="button" class="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-300 text-base font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">
                    Закрыть
                </button>
            </div>
        </div>
    </div>
</div>
