export interface GameSession {
  id: string|number;
  state: State;
  startTime: string;
  endTime: string;
  deviceId: string;
  deviceName: string;
  platform: Platform;
  countryCode: string
  languageCode: string
  connectionType: string;
  osVersion: string;
  fwVersion: string;
  firstSeen: string;
  lastSeen: string;
}

export enum State {
  active,
  exited,
  lost
}

export enum Platform {
  'com-g5e-hiddencity',
  'com-g5e-hiddencity-winrt',
  'com-g5e-hiddencity-hd'
}
