import { Injectable } from '@angular/core';
import {Observable, Subject} from "rxjs";
import {HttpClient, HttpResponse} from "@angular/common/http";
import { Profile } from './profile';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private _profile$ = new Subject<Profile>();
  // private _sectionsKeyNamesList$ = new Subject<SectionsKeyNamesList>();

  constructor(private http: HttpClient) { }

  getProfile(id: string): Observable<HttpResponse<Profile>> {
    return this.http.get<Profile>('/api/v1/ui/profile/' + id, {
      observe: 'response',
      withCredentials: true
    });
  }

  changeProfileStatus(playerId: number, status: string, reason: string): Observable<HttpResponse<Profile>> {
    return this.http.post<Profile>('/api/v1/ui/profile-change-status', {
      'playerId': playerId,
      'status': status,
      'reason': reason
    },{
      observe: 'response',
      withCredentials: true
    });
  }

  eraseProfile(playerId: string, reason: string): Observable<HttpResponse<any>> {
    return this.http.post<Profile>('/api/v1/ui/profile/' + playerId + '/erase', {
      'reason': reason,
    },{
      observe: 'response',
      withCredentials: true
    });
  }

  addNoteToPlayer(playerId: number, note: string): Observable<HttpResponse<Profile>> {
    return this.http.post<Profile>('/api/v1/ui/profile-add-note', {
      'playerId': playerId,
      'note': note
    },{
      observe: 'response',
      withCredentials: true
    });
  }

  // getSectionsKeyNamesList(name: string|null = null): Observable<HttpResponse<SectionsKeyNamesList>> {
  //   return this.http.get<SectionsKeyNamesList>(name
  //       ? '/api/v1/section-key-names-list/' + name
  //       : '/api/v1/section-key-names-list', {
  //     observe: 'response',
  //     withCredentials: true
  //   });
  // }

  setProfile(profile: Profile) {
    this._profile$.next(profile);
  }

  get profile$(): Subject<Profile> {
    return this._profile$;
  }

  // setSectionsKeyNamesList(value: SectionsKeyNamesList) {
  //   this._sectionsKeyNamesList$.next(value);
  // }
  //
  // get sectionsKeyNamesList$(): Subject<SectionsKeyNamesList> {
  //   return this._sectionsKeyNamesList$;
  // }
  //
  // updateSectionsValues(id: string | number, sectionsValues: any[]): Observable<HttpResponse<any>> {
  //   return this.http.patch<any>('/api/v1/players/' + id + '/update-sections-values', sectionsValues, {
  //     observe: 'response',
  //     withCredentials: true
  //   });
  // }
  //
  // getSectionKeyName(id: number, type: SectionType, list: SectionsKeyNamesList): string {
  //   switch (type) {
  //     case SectionType.BINARY: return String(list.sectionsBinaryNames.find(item => item.id === id)?.name)
  //     case SectionType.BOOL: return String(list.sectionsBoolNames.find(item => item.id === id)?.name)
  //     case SectionType.DATETIME: return String(list.sectionsDatetimeNames.find(item => item.id === id)?.name)
  //     case SectionType.STRING: return String(list.sectionsStringNames.find(item => item.id === id)?.name)
  //     case SectionType.INT: return String(list.sectionsIntNames.find(item => item.id === id)?.name)
  //     case SectionType.INT64: return String(list.sectionsInt64Names.find(item => item.id === id)?.name)
  //     case SectionType.FLOAT: return String(list.sectionsFloatNames.find(item => item.id === id)?.name)
  //     case SectionType.NULL: return '';
  //     default: return '';
  //   }
  // }
}
