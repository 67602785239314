<div class="sm:w-full lg:w-4/12 mx-auto">
    <form class="bg-gray-50 overflow-hidden border border-gray-200 rounded-lg px-10 pt-10 pb-4 mb-4">
        <div class="mb-6">
            <label class="block">
                <label class="block text-gray-500 text-sm mb-2" for="email">Email</label>
                <input
                        id="email"
                        type="email"
                        class="mt-1 block w-full text-sm rounded-md border-gray-200 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        placeholder="user@ab-games.com"
                />
            </label>
        </div>
        <div class="mb-6 flex justify-center">
            <button class="bg-blue-400 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                Reset password
            </button>
        </div>
    </form>
</div>
