<div class="sm:w-full lg:w-4/12 mx-auto">
    <form class="bg-gray-50 overflow-hidden border border-gray-200 rounded-lg px-10 pt-10 pb-4 mb-4" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="mb-4">
            <label class="block text-gray-500 text-sm mb-2" for="username">Ім'я користувача</label>
            <input formControlName="username"
                   id="username"
                   type="text"
                   class="block w-full text-sm rounded-md border-gray-200 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
            <div *ngIf="loginForm.controls['username'].invalid && (loginForm.controls['username'].dirty || loginForm.controls['username'].touched)">
                <p class="text-red-500 text-xs italic">The Username field must only contain alpha-numeric characters and "_", "-"</p>
<!--                TODO доделать валидацию когда не указан юзернейм или указан неверный-->
            </div>
        </div>
        <div class="mb-6">
            <label class="block text-gray-500 text-sm mb-2" for="password">Пароль</label>
            <input formControlName="password"
                   id="password"
                   type="password"
                   class="block w-full text-sm rounded-md border-gray-200 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
            <div *ngIf="loginForm.controls['password'].invalid && (loginForm.controls['password'].dirty || loginForm.controls['password'].touched)">
                <p class="text-red-500 text-xs italic">The Password field must be at least 8 characters in length</p>
            </div>
        </div>
        <div class="mb-4 flex justify-center">
            <button type="submit"
                    class="bg-blue-400 hover:bg-blue-500 font-bold text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                Увійти
            </button>
        </div>
        <div class="mb-4 flex justify-center">
            <a class="inline-block align-baseline text-sm text-blue-400 hover:text-blue-600" routerLink="/forgot-password" routerLinkActive="active">
                Забули пароль?
            </a>
        </div>
    </form>
</div>
