export abstract class PaginationService
{
  from = 0;
  to = 30;
  total = 0;
  limit = 30;
  totalPage = 1;
  currentPage = 1;
  paginationList: any[] = [];
  orderByColumn = "createdAt"
  orderDirection = "DESC"

  defaultFrom: number
  defaultTo: number
  defaultLimit: number
  defaultOrderByColumn: string

  protected constructor(from: number = 0, to: number = 30, limit: number = 30, orderByColumn: string = "createdAt") {
    this.from = this.defaultFrom = from;
    this.to = this.defaultTo = to;
    this.limit = this.defaultLimit = limit;
    this.orderByColumn = this.defaultOrderByColumn = orderByColumn;
  }

  parseRangeHeader(range: string): void {
    const nameWithRange = range.split('/');

    this.total = Number(nameWithRange[1]);
    this.totalPage = this.total > this.to ? Math.ceil(this.total / this.limit) : this.totalPage;
    const StringRange = nameWithRange[0].split(' ')[1];
    const ranges = StringRange.split('-');
    this.from = Number(ranges[0]);
    this.to = Number(ranges[1]);
    this.paginationList = this.getPaginationList(this.currentPage, this.totalPage, 1)
    console.log("PAGINATION LIST", this.paginationList, range)
  }

  changePage(page: number) {
    this.currentPage = page;

    if (this.totalPage === 1) {
      return;
    }

    this.from = this.limit * (page - 1);
    this.to = this.limit * page;

    if (this.to > this.total) {
      this.to = this.total;
    }
  }

  getPaginationList(currentPage: number = 1, totalPage: number = 1, pageRange: number = 1): any[] {
    let pagination: any[] = [];

    if (totalPage <= 7) {
      for (let i = 1; i <= totalPage; i++) {
        pagination.push(i);
      }

      return pagination;
    }

    let rangeStart = currentPage - pageRange;
    let rangeEnd = currentPage + pageRange;

    if (rangeEnd > totalPage) {
      rangeEnd = totalPage;
      rangeStart = totalPage - pageRange * 2;
      rangeStart = rangeStart < 1 ? 1 : rangeStart;
    }

    if (rangeStart <= 1) {
      rangeStart = 1;
      rangeEnd = Math.min(pageRange * 2 + 1, totalPage);
    }

    if (rangeStart <= 3) {
      for (let i = 1; i < rangeStart; i++) {
        pagination.push(i);
      }
    } else {
      pagination = [1, '...'];
    }

    for (let i = rangeStart; i <= rangeEnd; i++) {
      pagination.push(i);
    }

    if (rangeEnd >= totalPage - 2) {
      for (let i = rangeEnd + 1; i <= totalPage; i++) {
        pagination.push(i);
      }
    } else {
      pagination.push('...', totalPage);
    }

    return pagination;
  }

  resetPagination(withPaginationListClear: boolean = false) {
    this.from = this.defaultFrom;
    this.to = this.defaultTo;
    this.total = 0;
    this.limit = this.defaultLimit;
    this.orderByColumn = this.defaultOrderByColumn
    this.totalPage = 1;
    this.currentPage = 1;

    if (withPaginationListClear) {
      this.paginationList = [];
    }
  }

  // After calling this func you need to fetch entities again
  changeOrderBy(columnName: string) {
    if (this.orderByColumn !== columnName) {
      this.orderByColumn = columnName;
      this.orderDirection = "DESC";
      return;
    }

    if (this.orderByColumn == columnName && this.orderDirection === "ASC") {
      this.orderByColumn = this.defaultOrderByColumn;
      this.orderDirection = "DESC";
      return;
    }

    if (this.orderByColumn == columnName) {
      this.orderDirection = "ASC";
      return;
    }
  }
}
