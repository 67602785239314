import { Injectable } from '@angular/core';
import {MapElement} from "../map-element/map-element";
import {RenovationStatus} from "../renovation/renovation";
import {PaginationService} from "../../../common/pagination-service";

@Injectable({
  providedIn: 'root'
})
export class MapElementListService extends PaginationService {

  isActiveMapElementList: boolean = false;
  isAllMapElementsSelected: boolean = false;

  selectedMapElements: MapElement[] = []
  pendingMapElements: MapElement[] = []
  savedMapElements: MapElement[] = []
  confirmedMapElements: MapElement[] = []
  notSelectedMapElementsWarning: string[] = [];

  addPendingMapElement(mapElement: MapElement) {
    this.pendingMapElements.push(mapElement)
    this.isActiveMapElementList = true
  }

  getMapElementById(mapElementId: string): MapElement {
    return <MapElement>this.pendingMapElements.find(mapElement => mapElement.id === mapElementId)
      ?? this.savedMapElements.find(mapElement => mapElement.id === mapElementId)
      ?? this.confirmedMapElements.find(mapElement => mapElement.id === mapElementId)
  }

  selectMapElement(mapElement: MapElement): void {
    if (this.isMapElementSelected(mapElement)) {
      this.deselectMapElement(mapElement);
      return;
    }

    if (this.notSelectedMapElementsWarning.includes(mapElement.id)) {
      this.notSelectedMapElementsWarning = this.notSelectedMapElementsWarning.filter((id) => id !== mapElement.id);
    }

    this.selectedMapElements.push(mapElement);
  }

  isMapElementSelected(mapElement: MapElement): boolean {
    return this.selectedMapElements.includes(mapElement);
  }

  deselectMapElement(mapElement: MapElement): void {
    this.selectedMapElements = this.selectedMapElements.filter((pl) => pl !== mapElement);
  }

  selectAllMapElements(): void {
    if (this.isAllMapElementsSelected) {
      this.deselectAllMapElements();
      return;
    }

    this.isAllMapElementsSelected = true;
    this.pendingMapElements.forEach((pl) => this.selectedMapElements.push(pl));
  }

  deselectAllMapElements(): void {
    this.isAllMapElementsSelected = false;
    this.selectedMapElements = [];
  }

  updateRenovationStatusInSelectedMapElements(status: RenovationStatus) {
    this.selectedMapElements.forEach(mapElement => {
      mapElement.status = status;
    });
  }
}
