<div *ngIf="isShowingCheatSaveModal" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

    <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
    <form class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
      <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
        <button (click)="closeModal()" type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <span class="sr-only">Закрыть</span>
          <!-- Heroicon name: outline/x -->
          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div>
        <div class="relative mt-5">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="w-full border-t border-gray-300"></div>
          </div>
          <div class="relative flex justify-center">
            <span class="bg-white px-2 text-sm text-gray-500">Сейви гравця по дням</span>
          </div>
        </div>
        <div class="flow-root mt-12 pb-12">
          <ul role="list" class="-my-5 divide-y divide-gray-200">
            <div class="{{cheatSaveListModalService.playerSavesByDayModalHeight}}">
              <nav class="h-full overflow-y-auto" aria-label="Directory">
                <div class="relative">
                  <ul role="list" class="relative z-0 divide-y divide-gray-200">
                    <li *ngIf="this.cheatSaveListModalService.playerSaveMetadataByDay && this.cheatSaveListModalService.playerSaveMetadataByDay.cached" class="hover:bg-green-50">
                      <button type="button" class="flex flex-row w-full" (click)="choosePlayerSaveByDay(this.cheatSaveListModalService.playerSaveMetadataByDay.cached)">
                        <div *ngIf="selectedPlayerSave && selectedPlayerSave.id === this.cheatSaveListModalService.playerSaveMetadataByDay.cached.id" class="text-green-500 p-3">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                          </svg>
                        </div>
                        <div class="focus-within:ring-2 text-left focus-within:ring-indigo-500 py-2.5 {{selectedPlayerSave && selectedPlayerSave.id === this.cheatSaveListModalService.playerSaveMetadataByDay?.cached?.id ? 'px-1' : 'pl-12'}}">
                          <span class="text-sm font-bold text-gray-900">{{this.cheatSaveListModalService.playerSaveMetadataByDay?.cached?.receivedTime | date: 'Y-MM-dd HH:mm:ss'}}</span>
                          <span class="pl-8 text-sm font-bold text-gray-500">{{this.cheatSaveListModalService.playerSaveMetadataByDay?.cached?.appVersion}}</span>
                          <span class="pl-8 text-sm text-yellow-500">Останній з кеша</span>
                        </div>
                      </button>
                    </li>
                    <li *ngFor="let dbSave of this.cheatSaveListModalService.playerSaveMetadataByDay?.db" class="hover:bg-green-50">
                      <button type="button" class="flex flex-row w-full" (click)="choosePlayerSaveByDay(dbSave)">
                        <div *ngIf="selectedPlayerSave && selectedPlayerSave.id === dbSave.id" class="text-green-500 p-3">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                          </svg>
                        </div>
                        <div class="focus-within:ring-2 text-left focus-within:ring-indigo-500 py-2.5 {{selectedPlayerSave && selectedPlayerSave.id === dbSave.id ? 'px-1' : 'pl-12'}}">
                          <span class="text-sm text-gray-900">{{dbSave.receivedTime | date: 'Y-MM-dd HH:mm:ss'}}</span>
                          <span class="pl-8 text-sm text-gray-500">{{dbSave.appVersion}}</span>
                          <span class="pl-8 text-sm text-yellow-500">БД</span>
                        </div>
                      </button>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </ul>
        </div>
        <div class="relative">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="w-full border-t border-gray-300"></div>
          </div>
          <div class="relative flex justify-center">
            <span class="bg-white px-2 text-sm text-gray-500">Чит сейви</span>
          </div>
        </div>
        <div class="flow-root mt-12">
          <ul role="list" class="-my-5 divide-y divide-gray-200">
            <div class="h-80">
              <nav class="h-full overflow-y-auto" aria-label="Directory">
                <div class="relative">
                  <ul role="list" class="relative z-0 divide-y divide-gray-200">
                    <li *ngFor="let cheatSave of this.cheatSaveListModalService.cheatSaves" class="hover:bg-green-50">
                      <button type="button" class="flex flex-row w-full" (click)="chooseSaveToExportIntoCheatSave(cheatSave)">
                        <div *ngIf="selectedCheatSave && selectedCheatSave.id === cheatSave.id" class="text-green-500 p-3">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                          </svg>
                        </div>
                        <div class="focus-within:ring-2 text-left focus-within:ring-indigo-500 py-2.5 {{selectedCheatSave && selectedCheatSave.id === cheatSave.id ? 'px-1' : 'pl-12'}}">
                          <span class="text-sm text-gray-900">{{cheatSave.saveName}}</span>
                          <span class="pl-8 text-sm text-gray-500">{{cheatSave.appVersion}}</span>
                        </div>
                      </button>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </ul>
        </div>
        <div>
          <button *ngIf="cheatSaveListModalService.cheatSaves.length < cheatSaveListModalService.total" (click)="showMoreCheatSaves()" type="button" class="mt-12 w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
            Показати більше
          </button>
        </div>
      </div>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button *ngIf="selectedCheatSave && selectedPlayerSave && cheatSaveListModalService.isMovingPlayerSaveToCheatSave" (click)="exportPlayerSaveToCheatSave()" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-300 text-base font-medium text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm">
          Перенести в чит сейв
        </button>
        <button *ngIf="selectedCheatSave && cheatSaveListModalService.isMovingCheatSaveToPlayerSave" (click)="importCheatSaveToPlayerSave()" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-300 text-base font-medium text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm">
          Перенести в сейв гравця
        </button>
        <button (click)="closeModal()" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
          Закрити
        </button>
      </div>
      <!--            <div class="relative rounded-xl overflow-auto p-8">-->
      <!--              <div class="flex items-center justify-center">-->
      <!--                <button type="button"-->
      <!--                        class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-indigo-500"-->
      <!--                        disabled="">-->
      <!--                  <svg class="motion-reduce:hidden animate-spin -ml-1 mr-3 h-5 w-5 text-white"-->
      <!--                       xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">-->
      <!--                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>-->
      <!--                    <path class="opacity-75" fill="currentColor"-->
      <!--                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>-->
      <!--                  </svg>-->
      <!--                  Processing...-->
      <!--                </button>-->
      <!--              </div>-->
      <!--            </div>-->
    </form>
  </div>
</div>
