import {Component, OnInit} from '@angular/core';
import {cloneDeep} from "lodash";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DeployModalService} from "./deploy-modal.service";
import {NotificationsService} from "angular2-notifications";
import {DeployName, Section, SectionsValuesChanges} from "../deploy";
import {SectionType} from "../../players/section-type";

@Component({
  selector: 'app-deploy-modal',
  templateUrl: './deploy-modal.component.html',
  styleUrls: ['./deploy-modal.component.scss']
})
export class DeployModalComponent implements OnInit {

  deploys: DeployName[] = [
    {
      id: 1,
      name: 'Дать господам монет'
    },
    {
      id: 2,
      name: 'А вот этим игрокам рубинов'
    }
  ] as DeployName[];

  isShowingDeployModal = true;
  deploysCopy: DeployName[] = [];
  newChanges: any;
  selectedDeploy: DeployName|null = null;
  isShowingTextInputForNewDeploy: boolean = false;

  newDeployForm = new FormGroup({
    'name': new FormControl(null, [Validators.required, Validators.pattern('[\\s\\dа-яёА-ЯЁa-zA-Z._-]*')]),
  });

  constructor(
    private deployModalService: DeployModalService,
    private notificationsService: NotificationsService
  ) { }

  ngOnInit(): void {
    this.deploysCopy = cloneDeep(this.deploys);
  }

  addDeployModalList(deployModalList: DeployName[]) {
    this.deploys = deployModalList;
  }

  addChangesToDeploy(changes: SectionsValuesChanges) {
    this.newChanges = {
      playerId: changes.playerId,
      sectionType: changes.sectionsValueType,
      operationType: changes.operationType,
      keyId: changes.sectionsValueId,
      keyName: changes.sectionsValueName, // TODO убрать дополнительное форматирование отсюда позже
      value: changes.sectionsValueType === SectionType.FLOAT ? String(changes.sectionsValue).replace(',', '.') : changes.sectionsValue,
    } as Section;
  }

  addChangesToExistedDeploy(deployId: number): any {
    const selectedDeploy = this.deploys.find(deploy => deploy.id === deployId);

    if (!selectedDeploy) {
      return;
    }

    this.selectedDeploy = selectedDeploy;
    this.hideTextInputForNewDeploy();
  }

  isInvalidDeployName(): boolean {
    return this.newDeployForm.controls['name'].dirty && this.newDeployForm.controls['name'].invalid;
  }

  closeModal() {
    this.isShowingDeployModal = false;
  }

  showTextInputForNewDeploy() {
    this.selectedDeploy = null;
    this.isShowingTextInputForNewDeploy = true;
  }

  hideTextInputForNewDeploy() {
    this.isShowingTextInputForNewDeploy = false;

    if (this.isInvalidDeployName()) {
      this.newDeployForm.controls['name'].reset();
    }
  }

  saveDeployChanges() {
    if (!this.newDeployForm.invalid && !this.selectedDeploy) {
      this.deployModalService.createDeploy({
        'name': this.newDeployForm.controls['name'].value,
        'changes': [this.newChanges]
      }).subscribe(
        (data) => {
          if (data.status === 201 && data.body !== null) {
            this.closeModal();
            this.notificationsService.success('Success!', 'Deploy created.', {
              timeOut: 1500,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            });
          }
        }
      );
    }

    if (this.selectedDeploy) {
      this.deployModalService.appendDeployChanges(this.selectedDeploy.id, {
        'changes': [this.newChanges]
      }).subscribe(
        (data) => {
          if (data.status === 200) {
            this.closeModal();
            this.notificationsService.success('Success!', 'Deploy changes updated.', {
              timeOut: 1500,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            });
          }
        }
      );
    }
  }

  getBackgroundColorForNewDeployInput(): string {
    if (this.isInvalidDeployName() && !this.selectedDeploy) {
      return 'bg-red-50';
    }

    if (this.isShowingTextInputForNewDeploy) {
      return '';
    }

    return 'hover:bg-green-50'
  }
}
