import { Injectable } from '@angular/core';
import {Observable, Subject} from "rxjs";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {Field, Rule} from 'angular2-query-builder';
import {RuleValidatorResult} from "../deploy/deploy";

@Injectable({
  providedIn: 'root'
})
export class QueryBuilderService {

  private _list$ = new Subject<Field[]>();
  private _queryBuilderQuery: any = null;
  private _queryBuilderRules = new Map<Rule, RuleValidatorResult>()
  private _isNotQueryContainsErrors = true

  // private _simpleSearchFiltersParams$ = new Subject<SimpleSearchFiltersParams>();

  constructor(private http: HttpClient) { }

  getList(): Observable<HttpResponse<Field[]>> {

    // for (let [key, value] of SimpleSearchPlayersRequestParamsMap) {
    //   httpParams = httpParams.append(key, value);
    // }

    return this.http.get<Field[]>('/api/v1/ui/query-builder/field-list', {
      observe: 'response',
      withCredentials: true
    });
  }

  setList(list: Field[]) {
    this._list$.next(list);
  }

  get list$(): Subject<Field[]> {
    return this._list$;
  }

  get queryBuilderQuery(): any {
    return this._queryBuilderQuery;
  }

  setQueryBuilderQuery(value: any) {
    this._queryBuilderQuery = value;
  }

  get queryBuilderRules(): Map<Rule, RuleValidatorResult> {
    return this._queryBuilderRules;
  }

  resetQueryBuilderRules() {
   this._queryBuilderRules = new Map<Rule, RuleValidatorResult>()
  }

  get isNotQueryContainsErrors(): boolean {
    return this._isNotQueryContainsErrors;
  }

  isValidQuery(): boolean {
    let isValidQuery = true;

    this._queryBuilderRules.forEach((result: RuleValidatorResult, key: Rule) => {
      if (!result.isValidOperator || !result.isValidValue) {
        this._isNotQueryContainsErrors = false;
        isValidQuery = false;
      }
    });

    return isValidQuery;
  }

  // setSimpleSearchFiltersParams(simpleSearchFiltersParams: SimpleSearchFiltersParams) {
  //   this._simpleSearchFiltersParams$.next(simpleSearchFiltersParams);
  // }
  //
  // get simpleSearchFiltersParams$(): Subject<SimpleSearchFiltersParams> {
  //   return this._simpleSearchFiltersParams$;
  // }
  //
  // getSimpleSearchFiltersParams(): Observable<HttpResponse<SimpleSearchFiltersParams>> {
  //   return this.http.get<SimpleSearchFiltersParams>('/api/v1/players-list-filters', {
  //     observe: 'response',
  //     // params: this.resolveListHttpParams(),
  //     withCredentials: true
  //   });
  // }
}
