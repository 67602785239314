import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {forkJoin, Observable} from "rxjs";
import {LogPlayerStatusChange, Player, PlayerStatus} from "../../player";
import {ActivatedRoute, Router} from "@angular/router";
import {ProfileVictorianQuestService} from "./profile-victorian-quest.service";
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {trans} from "../../../../translations";
import {SectionKeyName, SectionsKeyNamesList, SectionType} from "../../section-type";
import {NgSelectConfig} from "@ng-select/ng-select";
import {DeployModalComponent} from "../../../deploy/deploy-modal/deploy-modal.component";
import {DeployModalService} from "../../../deploy/deploy-modal/deploy-modal.service";
import {DeployName, OperationType} from "../../../deploy/deploy";
import {NotificationsService} from "angular2-notifications";
import {GameSession, Platform, State} from "../../game-session";
import {SectionsBinaryValues} from "../../sections-binary-values";
import {SectionsBoolValues} from "../../sections-bool-values";
import {SectionsDatetimeValues} from "../../sections-datetime-values";
import {SectionsFloatValues} from "../../sections-float-values";
import {SectionsInt64Values} from "../../sections-int64-values";
import {SectionsIntValues} from "../../sections-int-values";
import {SectionsStringValues} from "../../sections-string-values";

@Component({
  selector: 'app-profile-victorian-quest',
  templateUrl: './profile-victorian-quest.component.html',
  styleUrls: ['./profile-victorian-quest.component.scss']
})
export class ProfileVictorianQuestComponent implements OnInit {

  @ViewChild('deploy', { read: ViewContainerRef })
  deploy!: ViewContainerRef;

  add(sectionsValuesForm: FormGroup): void {
    this.deployModalService.setDeployModalList([
      {
        id: 1,
        name: 'Дать господам монет'
      },
      {
        id: 2,
        name: 'А вот этим игрокам рубинов'
      }
    ] as DeployName[]);
    const dynamicComponentFactory = this.componentFactoryResolver.resolveComponentFactory(DeployModalComponent);
    let deployModalComponent = this.deploy.createComponent(dynamicComponentFactory);
    deployModalComponent.instance.addDeployModalList(this.deployModalService.deployModalList);
    deployModalComponent.instance.addChangesToDeploy({...sectionsValuesForm.value, playerId: Number(this.route.snapshot.paramMap.get('id'))});
    this.resetForm();
    // this.deployModalService.getDeployModalList().subscribe(
    //   (data) => {
    //     if (data.status === 200 || data.status === 204) {
    //       this.deployModalService.setDeployModalList(data.body ?? []);
    //       const dynamicComponentFactory = this.componentFactoryResolver.resolveComponentFactory(DeployModalComponent);
    //       let deployModalComponent = this.deploy.createComponent(dynamicComponentFactory);
    //       deployModalComponent.instance.addDeployModalList(this.deployModalService.deployModalList);
    //       deployModalComponent.instance.addChangesToDeploy({...sectionsValuesForm.value, playerId: Number(this.route.snapshot.paramMap.get('id'))});
    //       this.resetForm();
    //     }
    //   }
    // );
  }

  isShowingProfileActions = false;
  isShowingProfileDeactivationModal = false;
  isShowingProfileEraseModal = false;
  isShowingProfileAddNoteModal = false;
  isEditModeEnabledForABGroups = false;
  isEditModeEnabledForAnotherGroups = false;
  isShowingAllPlayerDevices = false;

  player$: Observable<Player>;
  sectionsKeyNamesList$: Observable<SectionsKeyNamesList>;
  sectionsValues: SectionKeyName[] = [
    {
      id: 1,
      name: 'Achievement 10lvl',
      type: SectionType.BOOL,
    } as SectionKeyName,
    {
      id: 2,
      name: 'Ruby',
      type: SectionType.INT,
    } as SectionKeyName,
    {
      id: 3,
      name: 'Coins',
      type: SectionType.INT,
    } as SectionKeyName
  ];

  selectedOperationType: OperationType|null = null;
  selectedSectionsValueId: number|null = null;

  inputType: string = "text";
  inputValueType: SectionType = SectionType.STRING;
  inputPlaceholder: string = '';

  playerFormControlsDefaults = {
    operationType: trans('Select operation'),
    sectionsValueId: null,
    sectionsValueType: null,
    sectionsValueName: null,
    sectionsValue: SectionType.NULL,
    sectionsEntity: trans('Select item'),
  }

  operations = [
    {
      id: OperationType.ADD,
      name: trans('Change count')
    },
    {
      id: OperationType.SET,
      name: trans('Set item')
    },
  ]

  sectionsValuesForm = new FormGroup({
    'operationType':     new FormControl(this.playerFormControlsDefaults.operationType, []),
    'sectionsValueId':   new FormControl(this.playerFormControlsDefaults.sectionsValueId, []),
    'sectionsValueType': new FormControl(this.playerFormControlsDefaults.sectionsValueType, []),
    'sectionsValueName': new FormControl(this.playerFormControlsDefaults.sectionsValueName, []),
    'sectionsValue':     new FormControl(this.playerFormControlsDefaults.sectionsValue, []),
    'sectionsEntity':    new FormControl(this.playerFormControlsDefaults.sectionsEntity, []),
  });

  changeStatusForm = new FormGroup({
    'reason': new FormControl('', [Validators.required, Validators.minLength(1)]),
  });

  constructor(
    private profileVictorianQuestService: ProfileVictorianQuestService,
    private deployModalService: DeployModalService,
    private route: ActivatedRoute,
    private router: Router,
    private config: NgSelectConfig,
    private componentFactoryResolver: ComponentFactoryResolver,
    private notificationsService: NotificationsService,
    // public sectionsKeyNamesList: SectionsKeyNamesList,
    // public player: Player,
  ) {
    this.player$ = this.profileVictorianQuestService.player$;
    this.sectionsKeyNamesList$ = this.profileVictorianQuestService.sectionsKeyNamesList$;
  }

  public player = {
    id: 'nxiLz3rOxdUVQcImvKk15eMJB6er',
    status: 'active',
    username: 'player99',
    email: 'player99@gmail.com',
    firstSeen: '100 days ago',
    lastSeen: '2 days ago',
    usageDays: '44 days',
    usageTime: '3 days 14 hours 5 min 3 seconds',
    sex: 'man',
    age: 32,
    devices: [
      {
        id: '4eb19a8b-5d53-4567-bc4f-3a24b557152b',
        state: State.exited,
        startTime: '2 days 16 hours ago',
        endTime: '2 days 14 hours ago',
        deviceId: 'A3A7AA27-856D-5166-AB77-CC423DA3DDB2 (device # 1)',
        deviceName: 'Iphone 12 pro max',
        platform: Platform["com-g5e-hiddencity-hd"],
        countryCode: 'UA',
        languageCode: 'UA',
        connectionType: '-',
        osVersion: 'IOS 12.1',
        fwVersion: '-',
        firstSeen: '64 days ago',
        lastSeen: '2 days ago'
      },
      {
        id: '6eb20a8b-4542-8454-fgsd-5a24b5571ddf',
        state: State.exited,
        startTime: '14 days 3 hours ago',
        endTime: '14 days 2 hours ago',
        deviceId: 'A3A7AA27-856D-5166-AB77-CC423DA3DDB2 (device # 2)',
        deviceName: 'Oneplus 8 pro',
        platform: Platform["com-g5e-hiddencity-winrt"],
        countryCode: 'UA',
        languageCode: 'UA',
        connectionType: '-',
        osVersion: 'Android 11.1',
        fwVersion: '-',
        firstSeen: '100 days ago',
        lastSeen: '14 days ago'
      }
    ] as GameSession[],
    apps: [                                         // имеется ввиду игры нашей компании в которые играет игрок
      {
        id: '4eb19a8b-5d53-4567-bc4f-3a24b557152b',
        state: State.exited,
        startTime: '2 days 16 hours ago',
        endTime: '2 days 14 hours ago',
        deviceId: 'A3A7AA27-856D-5166-AB77-CC423DA3DDB2 (device # 1)',
        deviceName: 'Iphone 12 pro max',
        platform: Platform["com-g5e-hiddencity-hd"],
        countryCode: 'UA',
        languageCode: 'UA',
        connectionType: '-',
        osVersion: 'IOS 12.1',
        fwVersion: '-',
        firstSeen: '64 days ago',
        lastSeen: '2 days ago'
      },
    ] as GameSession[],
    gameSessions: [                 // последняя игровая сессия
      {
        id: '4eb19a8b-5d53-4567-bc4f-3a24b557152b',
        state: State.exited,
        startTime: '2 days 16 hours ago',
        endTime: '2 days 14 hours ago',
        deviceId: 'A3A7AA27-856D-5166-AB77-CC423DA3DDB2 (device # 1)',
        deviceName: 'Iphone 12 pro max',
        platform: Platform["com-g5e-hiddencity-hd"],
        countryCode: 'UA',
        languageCode: 'UA',
        connectionType: '-',
        osVersion: 'IOS 12.1',
        fwVersion: '-',
        firstSeen: '64 days ago',
        lastSeen: '2 days ago'
      },
    ] as GameSession[],
    sectionsBinaryValues: [] as SectionsBinaryValues[],
    sectionsBoolValues: [{
      id: 1,
      name: 'Achievement 10lvl',
      value: true,
      transactionId: 2132132,
      type: SectionType.BOOL
    }] as SectionsBoolValues[],
    sectionsDatetimeValues: [] as SectionsDatetimeValues[],
    sectionsFloatValues: [] as SectionsFloatValues[],
    sectionsInt64Values: [] as SectionsInt64Values[],
    sectionsIntValues: [
      {
        id: 2,
        name: 'Ruby',
        value: 200,
        transactionId: 34324,
        type: SectionType.INT
      },
      {
        id: 3,
        name: 'Coins',
        value: 1000,
        transactionId: 45454,
        type: SectionType.INT
      },
    ] as SectionsIntValues[],
    sectionsStringValues: [] as SectionsStringValues[],
    logsPlayerStatusChanges: [
      {
        userId: 1,
        username: 'dp',
        status: PlayerStatus.blocked,
        reason: 'Cheat codes',
        createdAt: new Date()
      }
    ] as LogPlayerStatusChange[],
  } as Player;

  ngOnInit(): void {
    const playerId = this.route.snapshot.paramMap.get('id');

    this.profileVictorianQuestService.setPlayer(this.player);
    if (playerId) {

      // forkJoin({
      //   player: this.profileVictorianQuestService.getPlayer(playerId),
      //   // sectionsKeyNamesList: this.profileVictorianQuestService.getSectionsKeyNamesList(),
      //   // deployModalList: this.deployModalService.getDeployModalList()
      // }).subscribe(results => {
      //
      //   // if (results.sectionsKeyNamesList.body) {
      //   //   this.profileVictorianQuestService.setSectionsKeyNamesList(results.sectionsKeyNamesList.body);
      //   // }
      //
      //   if (results.player.body) {
      //     this.profileVictorianQuestService.setPlayer(results.player.body);
      //   }
      //
      //   // if (results.deployModalList.body) {
      //   //   this.deployModalService.setDeployModalList(results.deployModalList.body);
      //   // }
      // });
    }
  }

  fetchPlayer() {
    const playerId = this.route.snapshot.paramMap.get('id');

    this.profileVictorianQuestService.setPlayer(this.player);
    if (playerId) {
      // this.profileVictorianQuestService.getPlayer(playerId).subscribe(
      //   (data) => {
      //     if (data.body !== null) {
      //       this.profileVictorianQuestService.setPlayer(data.body);
      //     }
      //   }
      // );
    }
  }

  //
  // fetchSectionsKeyNamesList() {
  //   this.profileVictorianQuestService.getSectionsKeyNamesList().subscribe(
  //     (data) => {
  //       if (data.body !== null) {
  //         this.profileVictorianQuestService.setSectionsKeyNamesList(data.body);
  //       }
  //     }
  //   );
  // }

  private setBooleanInput() {
    this.inputType = '';
    this.inputPlaceholder = '';
  }

  private setStringInput() {
    this.inputType = "text";
    this.inputPlaceholder = 'Введите текст';
  }

  private setNumberInput() {
    this.inputType = "text";
    this.inputPlaceholder = 'Введите число';
  }

  private setDatetimeInput() {
    this.inputType = "text";
    this.inputPlaceholder = 'yyyy-mm-dd hh:mm:ss'
  }

  // private addDatetimeValidator(): ValidatorFn {
  //   return (control: AbstractControl): ValidationErrors | null => {
  //     const value = '1y 2mo 20d';
  //
  //
  //     const $intervals = ['y', 'mo', 'd', 'h', 'mi', 's'];
  //
  //
  //     return (datetime > 0 && !isNaN(datetime)) || control.value?.trim() === ''
  //       ? null
  //       : {invalidDatetime: {value: control.value}};
  //   };
  // }

  private datetimeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const datetime = Date.parse(control.value);
      return (datetime > 0 && !isNaN(datetime)) || control.value?.trim() === ''
        ? null
        : {invalidDatetime: {value: control.value}};
    };
  }

  private bigIntValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value >= Number.MIN_SAFE_INTEGER && control.value <= Number.MAX_SAFE_INTEGER && control.value % 1 === 0
        ? null
        : {invalidBigInt: {value: control.value}};
    };
  }

  private intValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value >= -2147483648 && control.value <= 2147483647 && control.value % 1 === 0
        ? null
        : {invalidInt: {value: control.value}};
    };
  }

  private floatValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let floatValue = Number(String(control.value).replace(',', '.'));
      const isPositiveOrNegativeFloatSectionsValue = Math.sign(Number(String(floatValue).replace(',', '.')));

      if (isPositiveOrNegativeFloatSectionsValue === 1 || isPositiveOrNegativeFloatSectionsValue === -1 || Number(String(floatValue).replace(',', '.')) === 0) {
        return null;
      }

      return {invalidFloat: {value: control.value}};
    };
  }

  getPlayerSectionsForList(player: Player) {
    return [
      ...player.sectionsBinaryValues.slice(0, 50),
      ...player.sectionsBoolValues.slice(0, 50),
      ...player.sectionsDatetimeValues.slice(0, 50),
      ...player.sectionsFloatValues.slice(0, 50),
      ...player.sectionsInt64Values.slice(0, 50),
      ...player.sectionsIntValues.slice(0, 50),
      ...player.sectionsStringValues.slice(0, 50)
    ];
  }

  getPlayerSectionsValues(player: Player) {
    return [
      ...player.sectionsBinaryValues,
      ...player.sectionsBoolValues,
      ...player.sectionsDatetimeValues,
      ...player.sectionsFloatValues,
      ...player.sectionsInt64Values,
      ...player.sectionsIntValues,
      ...player.sectionsStringValues
    ];
  }

  getAllSectionsValues(sectionsKeyNamesList: SectionsKeyNamesList) {
    return [
      ...sectionsKeyNamesList.sectionsBinaryNames,
      ...sectionsKeyNamesList.sectionsBoolNames,
      ...sectionsKeyNamesList.sectionsDatetimeNames,
      ...sectionsKeyNamesList.sectionsFloatNames,
      ...sectionsKeyNamesList.sectionsInt64Names,
      ...sectionsKeyNamesList.sectionsIntNames,
      ...sectionsKeyNamesList.sectionsStringNames,
      ...sectionsKeyNamesList.sectionsNamesWithoutValues
    ];
  }

  onChangeForm(player: Player) {
    let formValues = {...this.sectionsValuesForm.value};
    let selectedSectionsValue = undefined;

    if (typeof formValues.sectionsEntity === "object" && formValues.sectionsEntity !== null) {
      this.sectionsValuesForm.patchValue({
        sectionsValueId: formValues.sectionsEntity.id,
        sectionsValueType: formValues.sectionsEntity.type,
        sectionsValueName: formValues.sectionsEntity.name,
      });

      formValues = {...this.sectionsValuesForm.value};
    }

    // switch (formValues.operationType) {
    //   case OperationType.ADD:
    //     selectedSectionsValue = this.sectionsValues.find(item => item.id === formValues.sectionsValueId && item.type === formValues.sectionsValueType);
    //     break;
    //   case OperationType.SET:
    //     const playerSectionsValues = this.getPlayerSectionsValues(player);
    //     const playerSectionsKeyNames: SectionKeyName[] = []
    //
    //     for (let playerSectionsValue of playerSectionsValues) {
    //       playerSectionsKeyNames.push({
    //         id: playerSectionsValue.sectionsValueId,
    //         name: playerSectionsValue.name,
    //         type: playerSectionsValue.type
    //       })
    //     }
    //
    //     this.sectionsValues = playerSectionsKeyNames;
    //     selectedSectionsValue = playerSectionsValues.find(item =>
    //       item.sectionsValueId === formValues.sectionsValueId &&
    //       item.type === formValues.sectionsValueType
    //     );
    // }
    selectedSectionsValue = this.sectionsValues.find(item => item.id === formValues.sectionsValueId && item.type === formValues.sectionsValueType);

    if (!selectedSectionsValue) {
      this.sectionsValuesForm.controls['sectionsValue'].reset();

      return;
    }

    this.sectionsValuesForm.controls['sectionsValue'].clearValidators();
    this.sectionsValuesForm.controls['sectionsValue'].addValidators(Validators.required);

    switch (selectedSectionsValue.type) {
      case SectionType.BINARY:
        this.inputValueType = SectionType.BINARY;
        this.setStringInput();
        break;
      case SectionType.BOOL:
        this.inputValueType = SectionType.BOOL;
        this.setBooleanInput();
        break;
      case SectionType.DATETIME:
        this.inputValueType = SectionType.DATETIME;
        this.sectionsValuesForm.controls['sectionsValue'].addValidators(this.datetimeValidator());
        this.sectionsValuesForm.controls['sectionsValue'].addValidators(Validators.minLength(19));
        this.sectionsValuesForm.controls['sectionsValue'].addValidators(Validators.maxLength(19));
        this.setDatetimeInput();
        break;
      case SectionType.FLOAT:
        this.inputValueType = SectionType.FLOAT;
        this.sectionsValuesForm.controls['sectionsValue'].addValidators(this.floatValidator());
        this.setNumberInput();
        break;
      case SectionType.INT64:
        this.inputValueType = SectionType.INT64;
        this.sectionsValuesForm.controls['sectionsValue'].addValidators(this.bigIntValidator());
        this.setNumberInput();
        break;
      case SectionType.INT:
        this.inputValueType = SectionType.INT;
        this.sectionsValuesForm.controls['sectionsValue'].addValidators(this.intValidator());
        this.setNumberInput();
        break;
      case SectionType.STRING:
        this.inputValueType = SectionType.STRING;
        this.setStringInput();
        break;
    }

    this.sectionsValuesForm.patchValue({
      sectionsValueType: selectedSectionsValue.type,
      sectionsValueName: selectedSectionsValue.name
    });

    if (this.selectedSectionsValueId !== formValues.sectionsValueId || this.selectedOperationType !== formValues.operationType) {
      this.sectionsValuesForm.controls['sectionsValue'].reset();
      formValues = {...this.sectionsValuesForm.value};
    }

    // if (formValues.operationType === OperationType.SET) {
    //   const selectedSectionsValue = this.getPlayerSectionsValues(player).find(item => item.sectionsValueId === formValues.sectionsValueId && item.type === formValues.sectionsValueType);
    //
    //   this.sectionsValuesForm.patchValue({
    //     sectionsValue: formValues.sectionsValue === null ? selectedSectionsValue?.value : formValues.sectionsValue
    //   });
    // }

    this.selectedOperationType = formValues.operationType;
    this.selectedSectionsValueId = formValues.sectionsValueId;
  }

  // onSubmitForm() {
  //   const playerId = this.route.snapshot.paramMap.get('id');
  //
  //   if (playerId) {
  //     this.profileVictorianQuestService.updateSectionsValues(playerId, [{...this.sectionsValuesForm.value}]).subscribe(
  //       (data) => {
  //         if (data.status === 201 && data.body !== null) {
  //           this.sectionsValuesForm.reset(this.playerFormControlsDefaults);
  //           this.fetchPlayer();
  //           // this.fetchSectionsKeyNamesList();
  //         }
  //       }
  //     );
  //   }
  // }

  isShowingSaveButton(player: Player) {
    let formValues = {...this.sectionsValuesForm.value};

    if (formValues.sectionsValueId === this.playerFormControlsDefaults.sectionsValueId) {
      return false;
    }

    // if (formValues.operationType === OperationType.SET) {
    //   const selectedSectionsValue = this.getPlayerSectionsValues(player).find(item => item.sectionsValueId === formValues.sectionsValueId && item.type === formValues.sectionsValueType);
    //   this.sectionsValuesForm.controls['sectionsValue'].updateValueAndValidity();
    //
    //   return selectedSectionsValue !== undefined
    //     && selectedSectionsValue.value !== formValues.sectionsValue
    //     && !this.sectionsValuesForm.controls['sectionsValue'].invalid;
    // }

    // if (formValues.operationType === OperationType.ADD) {
      const selectedItem = this.sectionsValues.find(item => item.id === formValues.sectionsValueId && item.type === formValues.sectionsValueType);

      if (selectedItem) {
        switch (selectedItem.type) {
          case SectionType.BOOL:
            if (formValues.sectionsValue === undefined || formValues.sectionsValue === null) {
              this.sectionsValuesForm.controls['sectionsValue'].patchValue(false);
            }

            return true;
          case SectionType.BINARY:
            return true;
          case SectionType.DATETIME:
            this.sectionsValuesForm.controls['sectionsValue'].updateValueAndValidity();
            return !this.sectionsValuesForm.controls['sectionsValue'].invalid
          case SectionType.FLOAT:
            const isPositiveOrNegativeFloatSectionsValue = Math.sign(Number(String(formValues.sectionsValue).replace(',', '.')));

            if (isPositiveOrNegativeFloatSectionsValue === 1 || isPositiveOrNegativeFloatSectionsValue === -1 || Number(String(formValues.sectionsValue).replace(',', '.')) === 0) {
              return !this.sectionsValuesForm.controls['sectionsValue'].invalid
            }

            break;
          case SectionType.INT64:
          case SectionType.INT:
            const isPositiveOrNegativeSectionsValue = Math.sign(formValues.sectionsValue);

            if (isPositiveOrNegativeSectionsValue === 1 || isPositiveOrNegativeSectionsValue === -1 || Number(formValues.sectionsValue) === 0) {
              return !this.sectionsValuesForm.controls['sectionsValue'].invalid
            }

            break;
          case SectionType.STRING:
            return !this.sectionsValuesForm.controls['sectionsValue'].invalid;
        }
      }
    // }

    return false;
  }

  isShowingDeclineButton(): boolean {
    const formValues = {...this.sectionsValuesForm.value};

    return formValues.operationType !== this.playerFormControlsDefaults.operationType
      || formValues.sectionsValueId !== this.playerFormControlsDefaults.sectionsValueId
      || formValues.sectionsValueType !== this.playerFormControlsDefaults.sectionsValueType
      || formValues.sectionsValue !== SectionType.NULL;
  }

  isSelectedOperationAndItem(): boolean {
    const formValues = {...this.sectionsValuesForm.value};

    return formValues.operationType !== this.playerFormControlsDefaults.operationType
      && (formValues.sectionsValueId !== this.playerFormControlsDefaults.sectionsValueId);
  }

  resetForm() {
    this.sectionsValuesForm.reset(this.playerFormControlsDefaults);
  }

  isValidSectionsValue(): boolean {
    this.sectionsValuesForm.controls['sectionsValue'].updateValueAndValidity();

    return this.sectionsValuesForm.controls['sectionsValue'].invalid
      && (this.sectionsValuesForm.controls['sectionsValue'].touched || this.sectionsValuesForm.controls['sectionsValue'].dirty);
  }

  // isFilledForm(): boolean {
  //   for (const [key, value] of Object.entries({...this.playerItemsForm.value})) {
  //     if (value !== null
  //       && value !== this.playerFormControlsDefaults.operationType
  //       && value !== this.playerFormControlsDefaults.sectionsValueId
  //       && value !== ''
  //     ) {
  //       return true
  //     }
  //   }
  //
  //   return false
  // }

  searchSectionsValues(e: {term: string}, player: Player) {
    // if (this.sectionsValuesForm.controls['operationType'].value === OperationType.SET) {
    //   this.sectionsValues = this.getPlayerSectionsValues(player);
    //   return;
    // }

    if (e.term.length > 2) {
      this.profileVictorianQuestService.getSectionsKeyNamesList(e.term).subscribe(
        (data) => {
          this.sectionsValues = data.body !== null ? this.getAllSectionsValues(data.body) : [];
        },
        () => {
          this.sectionsValues = [];
        });
    } else {
      this.sectionsValues = [];
    }
  }

  deactivatePlayer() {
    const playerId = this.route.snapshot.paramMap.get('id');

    if (this.changeStatusForm.valid && playerId) {
      this.isShowingProfileDeactivationModal = !this.isShowingProfileDeactivationModal;
      this.profileVictorianQuestService.changePlayerStatus(
        Number(playerId),
        'blocked',
        this.changeStatusForm.controls['reason'].value
      ).subscribe(
        (data) => {
          if (data.status === 200) {
            this.fetchPlayer();
            this.notificationsService.success('Успешно', 'Пользовать заблокирован', {
              timeOut: 1500,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            });
          }
        }
      );

      this.changeStatusForm.reset();
      return;
    }

    this.focusById('deactivateTextForm');
  }

  erasePlayer() {
    const playerId = this.route.snapshot.paramMap.get('id');

    if (this.changeStatusForm.valid && playerId) {
      this.isShowingProfileEraseModal = !this.isShowingProfileEraseModal;
      this.profileVictorianQuestService.changePlayerStatus(
        Number(playerId),
        'erased',
        this.changeStatusForm.controls['reason'].value
      ).subscribe(
        (data) => {
          if (data.status === 200) {
            this.fetchPlayer();
            this.notificationsService.success('Успешно', 'Персональные данные стерты', {
              timeOut: 1500,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            });
          }
        }
      );

      this.changeStatusForm.reset();
      return;
    }
    this.focusById('eraseTextForm');
  }

  addNoteToPlayer() {
    const playerId = this.route.snapshot.paramMap.get('id');

    if (this.changeStatusForm.valid && playerId) {
      this.isShowingProfileAddNoteModal = !this.isShowingProfileAddNoteModal;
      this.profileVictorianQuestService.addNoteToPlayer(
        Number(playerId),
        this.changeStatusForm.controls['reason'].value
      ).subscribe(
        (data) => {
          if (data.status === 201) {
            this.fetchPlayer();
            this.notificationsService.success('Успешно', 'Примечание добавлено', {
              timeOut: 1500,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            });
          }
        }
      );

      this.changeStatusForm.reset();
      return;
    }
    this.focusById('addNoteTextForm');
  }

  focusById(id: string) {
    document.getElementById(id)?.focus();
  }

  getPlayerStatusColor(status: PlayerStatus): string {
    switch (status) {
      case PlayerStatus.active: return  'green';
      case PlayerStatus.erased: return 'red'
      case PlayerStatus.blocked: return 'red'
    }
  }

  getPlayerStatusTitlePaddingLeft(status: PlayerStatus): string {
    switch (status) {
      case PlayerStatus.active: return  'pl-1';
      case PlayerStatus.erased: return 'pl-1'
      case PlayerStatus.blocked: return 'pl-2'
    }
  }
}
