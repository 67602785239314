import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {User} from "../user";
import {UserProfileService} from "./user-profile.service";
import {AppService} from "../../app.service";

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  user$: Observable<User>;

  constructor(
    private userProfileService: UserProfileService,
    private appService: AppService,
    private route: ActivatedRoute
  ) {
    this.user$ = this.userProfileService.user$;
  }

  ngOnInit(): void {
    const type = this.route.snapshot.data['type'];
    this.appService.setPageType$(type)
    const userId = this.route.snapshot.paramMap.get('id');

    if (userId) {
      this.userProfileService.getUser(userId).subscribe(
        (data) => {
          if (data.body !== null) {
            this.userProfileService.setUser(data.body);
          }
        }
      );
    } // TODO else redirect to 404
  }
}
