import {Component} from '@angular/core';
import {NgxPermissionsService} from "ngx-permissions";
import {AuthorizationService} from "./security/authorization.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {environment} from "../environments/environment";
import {Observable} from "rxjs";
import {AppService} from "./app.service";
import {PageTypeEnum} from "./common/page";
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'core-service-angular';
  // currentSlug: string | null = null;
  currentSlug: string | undefined;
  filename: string | undefined;
  requestId: string | undefined;
  //https://stackoverflow.com/questions/44939878/dynamically-adding-and-removing-components-in-angular/48932243
  // @ViewChild('loader', { read: ViewContainerRef })
  // loader!: ViewContainerRef;
  pageType$: Observable<PageTypeEnum>;

  constructor(
    private permissionsService: NgxPermissionsService,
    public authorizationService: AuthorizationService,
    // private componentFactoryResolver: ComponentFactoryResolver,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public appService: AppService
  ) {
    this.pageType$ = appService.pageType$
    // this.pageType$.subscribe((url) => console.log("KEK", url, url.toString()));
    // console.log(router.url);  // to print only path eg:"/login"
    // console.log("Data1", this.route)

    // this.appService.pageType$.subscribe(pageType => {
    //   this.pageType = pageType;
    //   console.log(this.pageType)
    // });

    // this.pageType$.subscribe(
    //   (url) => console.log("KEK", url, url.toString())
    // );
  }

  pageType: string = '';

  ngOnInit(): void {
    this.permissionsService.loadPermissions([]);
    this.authorizationService.isStillAuthenticated();

    if (!this.authorizationService.isAuthenticated) {
      this.router.navigateByUrl('/login');
    }

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      let currentRoute = this.activatedRoute;
      let routePath = '';

      while (currentRoute.firstChild) {
        currentRoute.firstChild.url.subscribe((segments) => {
          routePath += '/' + segments.map(segment => segment.path).join('/');
        });

        currentRoute = currentRoute.firstChild;
      }

      // console.log("PATH: " + routePath, "CURRENT ROUTE: " + currentRoute)
      this.selectOption(routePath);
    });

    // const type = this.route.snapshot.data['type'];
    // console.log("1",type, typeof type);

    // console.log("Data2", this.route)

    // this.appService.pageType$.subscribe(pageType => {
    //   this.pageType = pageType;
    //   console.log(this.pageType)
    // });

    // console.log(this.pageType$, this.appService.pageType$.isStopped)

    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     console.log("KEK")
    //     // console.log("} ", event)
    //     // this.currentSlug = event.url.split('/').pop();
    //     // console.log(this.route)
    //
    //     // const currentPath = this.router.url.split('?')[0]; // Get current route path without query parameters
    //     //
    //     // const isPathValid = this.router.config.some(route => {
    //     //   // Get route path without query parameters
    //     //   // const routePath = this.route.path.split('?')[0];
    //     //   // Create a regular expression to match the route path with dynamic segments
    //     //   const regex = new RegExp('^' + currentPath.replace(/:[^/]+/g, '[^/]+') + '$');
    //     //   console.log(regex, regex.test(currentPath))
    //     //   return regex.test(currentPath);
    //     // });
    //     //
    //     // if (!isPathValid) {
    //     //   console.log('Current route path is not valid');
    //     // }
    //     // console.log("-->", this.router.parseUrl( event.toString()).fragment)
    //     //
    //     const type = this.route.snapshot.data['type'];
    //     console.log("2", type, typeof type);
    //
    //     // console.log("Data3", this.router.data.subscribe((url:Data) => console.log(url)))
    //     // console.log("Event", event.id, event.urlAfterRedirects, event.url, event.toString())
    //   }
    // });

    // this.route.params.subscribe(params => {
    //   this.filename = params['filename'];
    //   this.requestId = params['requestId'];
    //   console.log(params)
    // });

    // this.route.params.subscribe(params => {
    //   console.log(params); // this will log the current route parameters to the console
    // });
    //
    // this.route.queryParams.subscribe(params => {
    //   console.log(params); // this will log the current query parameters to the console
    // });
    //
    // this.route.data.subscribe(data => {
    //   console.log(data); // this will log the current route data to the console
    // });
    // console.log(this.route.snapshot); // ActivatedRouteSnapshot
    // console.log(this.route.snapshot.url); // UrlSegment[]
    // console.log(this.route.snapshot.url[0]); // UrlSegment
    // console.log(this.route.snapshot.url[0].path); // e.g. /products
    // console.log(this.route.snapshot.url[0].parameters); // e.g. { id: 'x8klP0' }
  }

  ngOnDestroy() {

  }

  get envColor(): string {
    if (this.appService.env === 'dev') {
      return 'green';
    }

    if (this.appService.env === 'test') {
      return 'blue';
    }

    return 'red';
  }

  isDashboardPage(pageType: PageTypeEnum): boolean
  {
    return pageType === PageTypeEnum.DASHBOARD
  }

  isUserEditPage(pageType: PageTypeEnum): boolean
  {
    return pageType === PageTypeEnum.USER_EDIT
  }

  isUserListPage(pageType: PageTypeEnum): boolean
  {
    return pageType === PageTypeEnum.USER_LIST
  }

  isUserSettingsPage(pageType: PageTypeEnum): boolean
  {
    return pageType === PageTypeEnum.USER_SETTINGS
  }

  isSaveListPage(pageType: PageTypeEnum): boolean
  {
    return pageType === PageTypeEnum.SAVE_LIST
  }

  isCheatSaveListPage(pageType: PageTypeEnum): boolean
  {
    return pageType === PageTypeEnum.CHEAT_SAVE_LIST
  }

  isOfferCreatePage(pageType: PageTypeEnum): boolean
  {
    return pageType === PageTypeEnum.OFFER_CREATE
  }

  isOfferEditPage(pageType: PageTypeEnum): boolean
  {
    return pageType === PageTypeEnum.OFFER_EDIT
  }

  isDeployCreatePage(pageType: PageTypeEnum): boolean
  {
    return pageType === PageTypeEnum.DEPLOY_CREATE
  }

  isDeployEditPage(pageType: PageTypeEnum): boolean
  {
    return pageType === PageTypeEnum.DEPLOY_EDIT
  }

  isRenovationCreatePage(pageType: PageTypeEnum): boolean
  {
    return pageType === PageTypeEnum.RENOVATION_CREATE
  }

  isRemoteConfigCreatePage(pageType: PageTypeEnum): boolean
  {
    return pageType === PageTypeEnum.REMOTE_CONFIG_CREATE
  }

  isRemoteConfigEditPage(pageType: PageTypeEnum): boolean
  {
    return pageType === PageTypeEnum.REMOTE_CONFIG_EDIT
  }

  isCheatSaveCreatePage(pageType: PageTypeEnum): boolean
  {
    return pageType === PageTypeEnum.CHEAT_SAVE_CREATE
  }

  isCheatSaveEditPage(pageType: PageTypeEnum): boolean
  {
    return pageType === PageTypeEnum.CHEAT_SAVE_EDIT
  }

  get CurrentUrl(): string {
    // const currentSlug = this.route.snapshot.url[0].path;
    // console.log(this.route.toString().snapshot.url); // Output: current URL slug
    return ""
  }

  get isFullLeftSideMenu(): boolean {
    return this.appService.isFullLeftSideMenu
  }

  // add(): void {
  //   const dynamicComponentFactory = this.componentFactoryResolver.resolveComponentFactory(LoaderComponent);
  //   this.loader.createComponent(dynamicComponentFactory);
  // }
  selectOption(routePath: string) {
    const reverse = new Map(Object.values(PageTypeEnum).map(item => [item.toString(), item]))
    let pageType = reverse.get(routePath);

    if (pageType) {
      this.appService.setPageType$(pageType)
      this.appService.isFullLeftSideMenu = this.isCreatePage(pageType) || this.isEditPage(pageType);

      return;
    } else {
      pageType = this.transformEditRoutePath(routePath);

      if (pageType) {
        this.appService.setPageType$(pageType)
        this.appService.isFullLeftSideMenu = this.isCreatePage(pageType) || this.isEditPage(pageType);
        return;
      }
    }

    console.error("Route path: " + routePath + ", not found")
    return;
  }

  transformEditRoutePath(routePath: string): PageTypeEnum|undefined {
    const reverse = new Map(Object.values(PageTypeEnum).map(item => [item.toString(), item]))
    const segments = routePath.split('/');
    const transformedSegments = segments.filter((segment, index) => {
      // Exclude numeric segments unless they are part of the first or last position
      return isNaN(Number(segment)) || index === 0 || index === segments.length - 1;
    });
    const transformedRoutePath = transformedSegments.join('/');
    const pageType = reverse.get(transformedRoutePath)

    switch (true) {
      // Supporting old deploy edit page
      case pageType === undefined && ("/" + segments[1]) === PageTypeEnum.DEPLOY_EDIT_OLD:
        return PageTypeEnum.DEPLOY_EDIT_OLD;
      case pageType === undefined && ("/" + segments[1]) === PageTypeEnum.PROFILE:
        return PageTypeEnum.PROFILE;
    }

    return pageType;
  }

  isCreatePage(pageType: PageTypeEnum): boolean
  {
    switch (pageType) {
      case PageTypeEnum.DEPLOY_CREATE:
      case PageTypeEnum.RENOVATION_CREATE:
      // case PageTypeEnum.OFFER_CREATE:
      case PageTypeEnum.REMOTE_CONFIG_CREATE:
      case PageTypeEnum.CHEAT_SAVE_CREATE:
        return true
      default:
        return false
    }
  }

  isEditPage(pageType: PageTypeEnum): boolean
  {
    switch (pageType) {
      // case PageTypeEnum.DEPLOY_EDIT:    // TODO в планах тільки
      // case PageTypeEnum.USER_EDIT:      // TODO там поки нема редагування
      case PageTypeEnum.RENOVATION_CREATE: // TODO в планах тільки
      // case PageTypeEnum.OFFER_EDIT:
      case PageTypeEnum.REMOTE_CONFIG_EDIT:
      case PageTypeEnum.CHEAT_SAVE_EDIT:
        return true
      default:
        return false
    }
  }

  getUserId(): string|null {
    return this.authorizationService.getId();
  }
}
