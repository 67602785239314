import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {trim} from "lodash";
import {RemoteConfig} from "./remote-config";

@Injectable({
  providedIn: 'root'
})
export class RemoteConfigService {
  constructor(private http: HttpClient) {}

  saveRemoteConfig(name: string, condition: string|null, minVersionMinor: number|null, minVersionMajor: number|null, minVersionBuild: number|null, content: File|string, recreateId: number|undefined): Observable<HttpResponse<any>> {
    const formData = new FormData();
    formData.append('name', name);

    if (typeof content === 'string') {
      formData.append('content', content);
    } else {
      formData.append('content', content, content.name);
    }

    if (condition != null && trim(condition) != "") {
      formData.append('condition', condition);
    }

    if (minVersionMinor != null) {
      formData.append('minVersionMinor', minVersionMinor.toString());
    }

    if (minVersionMajor != null) {
      formData.append('minVersionMajor', minVersionMajor.toString());
    }

    if (minVersionBuild != null) {
      formData.append('minVersionBuild', minVersionBuild.toString());
    }

    const options: { observe: 'response', withCredentials: true, params?: any } = {
      observe: 'response',
      withCredentials: true
    };

    if (recreateId !== undefined) {
      options.params = {recreateId: recreateId};
    }

    return this.http.post<any>('/api/v1/ui/remote-config/create', formData, options);
  }

  getRemoteConfig(id: number): Observable<HttpResponse<RemoteConfig>> {
    return this.http.get<RemoteConfig>('/api/v1/ui/remote-config/' + id, {
      observe: 'response',
      withCredentials: true
    });
  }
}
