<div [formGroup]="skinForm" (submit)="save()">
  <div>
    <h3 class="font-medium text-gray-900 p-2.5">Скін</h3>
    <div>
      <div class="block w-full">
        <input
          formControlName="uniqueId"
          type="text"
          placeholder="Унікальний ID"
          class="input-text block w-full text-sm text-gray-500 border-none {{getInputStyles(this.skinForm.controls.uniqueId)}} focus:ring focus:ring-opacity-50">
      </div>
      <div class="block w-full">
        <input
          formControlName="name"
          type="text"
          placeholder="Унікальна назва"
          class="input-text block w-full text-sm text-gray-500 border-none {{getInputStyles(this.skinForm.controls.name)}} focus:ring focus:ring-opacity-50">
      </div>
      <div class="block w-full {{getDropdownStyles(this.skinForm.controls.mapElementIds)}}">
        <ng-select
          [clearable]=false
          [selectOnTab]="true"
          [multiple]="true"
          (change)="onChangeMapElement()"
          bindValue="id"
          bindLabel="uniqueId"
          formControlName="mapElementIds"
          placeholder="{{skinForm.controls['mapElementIds'].value === null ? 'Елемент мапи' : ''}}"
          class="custom-select block w-full text-sm text-gray-500">
          <ng-template ng-multi-label-tmp let-items="mapElements" let-clear="clear">
            <div class="ng-value py-0.5" *ngFor="let mapElement of getSelectedDropdownMapElements()">
              <span class="ng-value-icon right" (click)="removeDropdownMapElement(mapElement)" aria-hidden="true">×</span>
              <span class="ng-value-label"> {{mapElement.uniqueId}}</span>
<!--              <span *ngIf="renovationListService.getRenovationStatusById(mapElement.skinRenovationId) === RenovationStatusPending" class="ml-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">✓</span>-->
<!--              <span *ngIf="renovationListService.getRenovationStatusById(mapElement.skinRenovationId) === RenovationStatusSaved" class="ml-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">✓</span>-->
<!--              <span *ngIf="renovationListService.getRenovationStatusById(mapElement.skinRenovationId) === RenovationStatusConfirmed" class="ml-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">✓</span>-->
            </div>
          </ng-template>
          <ng-option *ngFor="let mapElement of mapElementListService.pendingMapElements"
                     [value]="mapElement.id">{{mapElement.uniqueId}}
<!--            <span class="ml-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">✓</span>-->
          </ng-option>
          <ng-option *ngFor="let mapElement of mapElementListService.savedMapElements"
                     [value]="mapElement.id">{{mapElement.uniqueId}}
<!--            <span class="ml-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">✓</span>-->
          </ng-option>
          <ng-option *ngFor="let mapElement of mapElementListService.confirmedMapElements"
                     [value]="mapElement.id">{{mapElement.uniqueId}}
<!--            <span class="ml-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">✓</span>-->
          </ng-option>
        </ng-select>
      </div>
      <div class="block w-full {{getDropdownStyles(this.skinForm.controls.setId)}}">
        <ng-select
          [clearable]=false
          [selectOnTab]="true"
          bindValue="id"
          bindLabel="uniqueId"
          formControlName="setId"
          placeholder="{{skinForm.controls['setId'].value === null ? 'Сет' : ''}}"
          class="custom-select block w-full text-sm text-gray-500">
          <ng-option style="padding-right: 8px" *ngFor="let set of setListService.pendingSets"
                     [value]="set.id">{{set.name}}
<!--            <span class="ml-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">✓</span>-->
          </ng-option>
          <ng-option style="padding-right: 8px" *ngFor="let set of setListService.savedSets"
                     [value]="set.id">{{set.name}}
<!--            <span class="ml-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">✓</span>-->
          </ng-option>
          <ng-option style="padding-right: 8px" *ngFor="let set of setListService.confirmedSets"
                     [value]="set.id">{{set.name}}
<!--            <span class="ml-1 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">✓</span>-->
          </ng-option>
        </ng-select>
      </div>
      <div class="block w-full {{getDropdownStyles(this.skinForm.controls.skinType)}}">
        <ng-select
          [clearable]=false
          [selectOnTab]="true"
          (change)="onChangeSkinTypeOrRareLevel()"
          bindValue="value"
          bindLabel="label"
          formControlName="skinType"
          placeholder="{{skinForm.controls['skinType'].value === null ? 'Тип скіна' : ''}}"
          class="custom-select block w-full text-sm text-gray-500">
          <ng-option *ngFor="let type of renovationService.DropdownSkinType"
                     [value]="type.value">{{type.label}}
          </ng-option>
        </ng-select>
      </div>
      <div class="block w-full {{getDropdownStyles(this.skinForm.controls.rareLevel)}}">
        <ng-select
          [clearable]=false
          [selectOnTab]="true"
          (change)="onChangeSkinTypeOrRareLevel()"
          bindValue="value"
          bindLabel="label"
          formControlName="rareLevel"
          placeholder="{{skinForm.controls['rareLevel'].value === null ? 'Рідкісний рівень / Furniture level' : ''}}"
          class="custom-select block w-full text-sm text-gray-500">
          <ng-option *ngFor="let rareLevel of renovationService.DropdownRareLevel"
                     [value]="rareLevel.value">{{rareLevel.label}}
          </ng-option>
        </ng-select>
      </div>
      <div *ngIf="isActiveCurrencyAndPriceField" class="block w-full {{getDropdownStyles(this.skinForm.controls.currency)}}">
        <ng-select
          [clearable]=false
          [selectOnTab]="true"
          bindValue="value"
          bindLabel="label"
          formControlName="currency"
          placeholder="{{skinForm.controls['currency'].value === null ? 'Валюта / Soft currency' : ''}}"
          class="custom-select block w-full text-sm text-gray-500">
          <ng-option *ngFor="let currency of renovationService.DropdownCurrency"
                     [value]="currency.value">{{currency.label}}
          </ng-option>
        </ng-select>
      </div>
      <div *ngIf="isActiveCurrencyAndPriceField" class="block w-full">
        <input
          formControlName="price"
          type="number"
          placeholder="Ціна"
          class="input-text block w-full text-sm text-gray-500 border-none {{getInputStyles(this.skinForm.controls.price)}} focus:ring focus:ring-opacity-50">
      </div>
      <div *ngIf="isActiveExpForAccessibilityField" class="block w-full">
        <input
          (keyup)="onChangeExpForAccessibility()"
          formControlName="expForAccessibility"
          type="number"
          placeholder="Рівень відкриття / Required level"
          class="input-text block w-full text-sm text-gray-500 border-none {{getInputStyles(this.skinForm.controls.expForAccessibility)}} focus:ring focus:ring-opacity-50">
      </div>
      <div class="block w-full mb-2">
        <input
          formControlName="quest"
          type="text"
          placeholder="Квест / 3_1_1"
          class="input-text block w-full text-sm text-gray-500 border-none {{getInputStyles(this.skinForm.controls.quest)}} focus:ring focus:ring-opacity-50">
      </div>
      <div class="flex items-center pl-2 py-2">
        <button type="button" (click)="setSkinRoyal()" class="{{isSkinRoyal() ? 'bg-blue-400' : 'bg-gray-200'}} relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2" role="switch" aria-labelledby="annual-billing-label">
          <span aria-hidden="false" class="translate-x-{{isSkinRoyal() ? '5' : '0'}} pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"></span>
        </button>
        <span class="ml-3 text-sm">
          <span class="text-gray-500">Унікальний скін</span>
        </span>
      </div>
    </div>
  </div>
  <div class="flex">
    <button *ngIf="!skinService.editableSkin" type="submit" (click)="save()" class="flex-1 mt-2 py-2 px-4 text-sm font-semibold rounded-lg {{this.skinForm.valid ? 'bg-yellow-100 text-yellow-700 hover:bg-yellow-200' : 'bg-gray-300 text-white'}}">В очікування</button>
    <button *ngIf="skinService.editableSkin" type="submit" (click)="update()" class="flex-1 mt-2 py-2 px-4 text-sm font-semibold rounded-lg {{this.skinForm.valid ? 'bg-yellow-100 text-yellow-700 hover:bg-yellow-200' : 'bg-gray-300 text-white'}}">Змінити</button>
    <button type="button" (click)="reset()" class="flex-1 mt-2 ml-3 py-2 px-4 text-sm text-white font-semibold rounded-lg {{this.skinForm.dirty ? 'bg-red-300 hover:bg-red-400' : 'bg-gray-300'}}">Відмінити</button>
  </div>
</div>
