import {Chunk} from "../chunk/chunk";
import {RenovationStatus} from "../renovation/renovation";

export interface MapElement {
  id: string
  uniqueId: string
  projection: Projection|null
  chunk: Chunk|null
  status: RenovationStatus;
  skinRenovationId: number
  createdAt: Date
  updatedAt: Date|null
}

export interface MapElementDto {
  uniqueId: string
  allowedSkinIds: string[]
  projection: Projection|null
}

export enum Projection {
  FREE = 0,
  LEFT = 1,
  RIGHT = 2,
  TOP = 3,
  DOWN = 4,
}
