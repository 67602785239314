import {Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {Dashboard} from "./dashboard";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private _dashboard$ = new Subject<Dashboard>();

  constructor(private http: HttpClient) {

  }

  getDashboardData(): Observable<HttpResponse<Dashboard>> {
    return this.http.get<Dashboard>('/api/v1/ui/dashboard', {
      observe: 'response',
      withCredentials: true,
      headers: new HttpHeaders(
        {
          'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
          'Pragma': 'no-cache',
          'Expires': '0'
        })
    });
  }

  setDashboard(dashboard: Dashboard) {
    this._dashboard$.next(dashboard);
  }

  get dashboard$(): Subject<Dashboard> {
    return this._dashboard$;
  }
}
