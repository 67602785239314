<div>
    <div class="flex flex-col" *ngIf="mappedSectionsList$ | async as mappedSectionsList">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full lg:px-8">
                <div class="overflow-hidden border border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                        <tr>
                            <th scope="col" class="h-10 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Id
                            </th>
                            <th scope="col" class="h-10 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Original Id
                            </th>
                            <th scope="col" class="h-10 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Original Name
                            </th>
                            <th scope="col" class="h-10 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Mapped Name
                            </th>
                            <th scope="col" class="h-10 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Mapped Description
                            </th>
                            <th scope="col" class="h-10 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Created At
                            </th>
                        </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                        <tr *ngFor="let mappedSectionsValue of mappedSectionsList; index as i">
                            <td class="h-10 px-6 whitespace-nowrap">
                                <div class="text-sm text-gray-900">
                                  {{ mappedSectionsValue.id }}
                                </div>
                            </td>
                            <td class="h-10 px-6 whitespace-nowrap">
                                <div class="text-sm text-gray-900">
                                    {{ mappedSectionsValue.sectionsNamesId }}
                                </div>
                            </td>
                            <td class="h-10 px-6 whitespace-nowrap">
                                <div class="text-sm text-gray-900">
                                <!--     TODO {{ mappedSectionsValue.sectionKeyName }}                             -->
                                    ориг. имя
                                </div>
                            </td>
                            <td class="h-10 px-6 whitespace-nowrap">
                                <div class="text-sm text-gray-900">
                                    {{mappedSectionsValue.name}}
                                </div>
                            </td>
                            <td class="h-10 px-6 whitespace-nowrap">
                                <div class="text-sm text-gray-900">
                                    {{mappedSectionsValue.description}}
                                </div>
                            </td>
                            <td class="h-10 px-6 whitespace-nowrap">
                                <div class="text-sm text-gray-900">
                                    {{mappedSectionsValue.createdAt}}
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>


    <div class="bg-white border border-gray-200 rounded-lg px-4 mt-10">
        <div class="flex flex-row text-gray-500 p-4 border-b border-gray-200">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
            </svg>
            <span class="text-xs pl-2 pt-0.5">Маппинг параметров</span>
        </div>
        <div class="pb-60">
            <form [formGroup]="searchSectionsKeyNameForm" (submit)="saveMappedSection()" class="">
                <table class="w-full table-fixed text-sm text-gray-500">
                    <thead>
                    <tr>
                        <th class="w-1/2" style="display: none"></th>
                        <th class="w-1/2" style="display: none"></th>
                        <th class="w-1/2" style="display: none"></th>
                        <th class="w-1/2" style="display: none"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td class="border-b border-gray-200">
                            <ng-select
                                (search)="searchSectionsValues($event)"
                                formControlName="sectionsValueId"
                                id="69"
                                class="custom block w-full text-sm text-gray-500 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                                <ng-option *ngFor="let sectionsValue of sectionsValues"
                                           [value]="sectionsValue.id">{{sectionsValue.name}}
                                  <span class="text-xs text-blue-600 ml-4">{{sectionsValue.type}}</span>
                              </ng-option>
                            </ng-select>
                        </td>
                        <td class="border-b border-gray-200">
                            <input *ngIf="isSelectedSectionsKeyName()"
                                   formControlName="mappedSectionsName"
                                   id="70"
                                   type="text"
                                   class="{{isValidMappedSectionsName() ? '' : 'bg-red-100'}} additional-params-input block w-full text-sm border-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                   placeholder="Введите название" [value]="this.searchSectionsKeyNameForm.controls['mappedSectionsName'].value">
                        </td>
                        <td class="border-b border-gray-200">
                            <input *ngIf="isSelectedSectionsKeyName()"
                                   formControlName="mappedSectionsDescription"
                                   id="71"
                                   type="text"
                                   class="{{!searchSectionsKeyNameForm.controls['mappedSectionsDescription'].invalid  ? '' : 'bg-red-100'}} additional-params-input block w-full text-sm border-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                   placeholder="Введите описание (не обязательно)" [value]="this.searchSectionsKeyNameForm.controls['mappedSectionsDescription'].value">
                        </td>
                        <td class="border-b border-gray-200">
                            <div class="grid grid-cols-2">
                                <button *ngIf="isShowingSaveButton()" type="submit" class="py-2 bg-green-300 text-sm text-white font-semibold hover:bg-green-500">Сохранить</button>
                                <button *ngIf="isShowingDeclineButton()" (click)="resetForm()" class="py-2 bg-red-300 text-sm text-white font-semibold hover:bg-red-500">Отменить</button>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </form>
        </div>
    </div>
</div>
