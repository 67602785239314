import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {UserListComponent} from './users/user-list/user-list.component';
import {UserProfileComponent} from './users/user-profile/user-profile.component';
import {UserLoginComponent} from './users/user-login/user-login.component';
import {UserRegistrationComponent} from './users/user-registration/user-registration.component';
import {UserForgotPasswordComponent} from './users/user-forgot-password/user-forgot-password.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {TokenInterceptor} from "./security/token.interceptor";
import {UnauthorizedInterceptor} from "./security/unauthorized.interceptor";
import {SimpleNotificationsModule} from "angular2-notifications";
import {BrowserAnimationsModule, NoopAnimationsModule} from "@angular/platform-browser/animations";
import {PlayerListComponent} from './players/player-list/player-list.component';
import {QueryBuilderModule} from "angular2-query-builder";
import {ProfileVictorianQuestComponent} from "./players/profiles/profile-victorian-quest/profile-victorian-quest.component";
import {ProfileCommonComponent} from './players/profiles/profile-common/profile-common.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {PageAccessForbiddenComponent} from './page-access-forbidden/page-access-forbidden.component';
import {NgxPermissionsModule} from "ngx-permissions";
import {UserLogoutComponent} from './users/user-logout/user-logout.component';
import {FooterComponent} from './navigation/footer/footer.component';
import {BreadcrumbComponent} from './navigation/breadcrumb/breadcrumb.component';
import {HeaderComponent} from './navigation/header/header.component';
import {LoaderComponent} from './navigation/loader/loader.component';
import {LoaderInterceptor} from "./security/loader.interceptor";
import {HttpHeadersInterceptor} from "./security/http-headers.interceptor";
import {ApplicationLogsListComponent} from './security/application-logs-list/application-logs-list.component';
import {ApplicationLogComponent} from './security/application-log/application-log.component';
import {JsonPrettyPrintPipe} from "./pipes/json-pretty-print.pipe";
import {QueryBuilderComponent} from './vq/query-builder/query-builder.component';
import {NgSelectModule} from "@ng-select/ng-select";
import {DeployModalComponent} from './deploy/deploy-modal/deploy-modal.component';
import {FocusDirective} from "./directives/focus-element.directive";
import {DeployEditComponent} from './deploy/deploy-edit/deploy-edit.component';
import {SectionsValuesMappingComponent} from './sections-values/sections-values-mapping/sections-values-mapping.component';
import {SaveListComponent} from './vq/save-list/save-list.component';
import {ProfileComponent} from "./vq/profile/profile.component";
import {DeployListComponent} from './vq/deploy-list/deploy-list.component';
import {DeployComponent} from './vq/deploy/deploy.component';
import {DeployListModalComponent} from './vq/deploy-list-modal/deploy-list-modal.component';
import {FileUploadComponent} from './components/file-upload/file-upload.component';
import {ClipboardModule} from "@angular/cdk/clipboard";
import {SelectRowsDirective} from "./directives/select-rows.directive";
import {EnumStringPipe} from "./pipes/enum-to-string.pipe";
import { UserSettingsComponent } from './users/user-settings/user-settings.component';
import { SkinComponent } from './vq/skin-renovations/skin/skin.component';
import { SkinListComponent } from './vq/skin-renovations/skin-list/skin-list.component';
import { RenovationComponent } from './vq/skin-renovations/renovation/renovation.component';
import { RenovationListComponent } from './vq/skin-renovations/renovation-list/renovation-list.component';
import { MapElementListComponent } from './vq/skin-renovations/map-element-list/map-element-list.component';
import { MapElementComponent } from './vq/skin-renovations/map-element/map-element.component';
import { RemoteConfigComponent } from './vq/remote-config/remote-config.component';
import { RemoteConfigListComponent } from './vq/remote-config-list/remote-config-list.component';
import {NgxJsonViewerComponent} from "./components/ngx-json-viewer/ngx-json-viewer.component";
import { CheatSaveListModalComponent } from './vq/cheat-save-list-modal/cheat-save-list-modal.component';
import { CheatSaveComponent } from './vq/cheat-save/cheat-save.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SetComponent } from './vq/skin-renovations/set/set.component';
import { SetListComponent } from './vq/skin-renovations/set-list/set-list.component';
import {ChunkComponent} from "./vq/skin-renovations/chunk/chunk.component";
import {ChunkListComponent} from "./vq/skin-renovations/chunk-list/chunk-list.component";

@NgModule({
  declarations: [
    AppComponent,
    UserListComponent,
    UserProfileComponent,
    UserLoginComponent,
    UserRegistrationComponent,
    UserForgotPasswordComponent,
    PlayerListComponent,
    ProfileCommonComponent,
    ProfileVictorianQuestComponent,
    PageNotFoundComponent,
    PageAccessForbiddenComponent,
    UserLogoutComponent,
    FooterComponent,
    BreadcrumbComponent,
    HeaderComponent,
    LoaderComponent,
    ApplicationLogsListComponent,
    ApplicationLogComponent,
    JsonPrettyPrintPipe,
    EnumStringPipe,
    QueryBuilderComponent,
    DeployModalComponent,
    FocusDirective,
    SelectRowsDirective,
    DeployListComponent,
    DeployEditComponent,
    SectionsValuesMappingComponent,
    SaveListComponent,
    ProfileComponent,
    DeployComponent,
    DeployListModalComponent,
    FileUploadComponent,
    UserSettingsComponent,
    SkinComponent,
    SkinListComponent,
    RenovationComponent,
    RenovationListComponent,
    MapElementListComponent,
    ChunkListComponent,
    ChunkComponent,
    MapElementComponent,
    RemoteConfigComponent,
    RemoteConfigListComponent,
    NgxJsonViewerComponent,
    CheatSaveListModalComponent,
    CheatSaveComponent,
    DashboardComponent,
    SetComponent,
    SetListComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    SimpleNotificationsModule.forRoot(),
    QueryBuilderModule,
    FormsModule,
    NgxPermissionsModule.forRoot(),
    NgSelectModule,
    ClipboardModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpHeadersInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
