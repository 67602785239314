<li *ngIf="!getIsActiveSetList()"
    class="my-2.5 overflow-hidden flex flex-row bg-gray-50 border border-gray-200 rounded-lg з-">
  <span class="pl-6 py-2 pr-4 cursor-pointer" (click)="setIsActiveSetList()">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
         class="w-6 h-6 text-gray-400 hover:text-blue-500">
      <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"/>
    </svg>
  </span>
  <span class="pl-2 py-2 text-sm leading-6 text-gray-500">Сетів: </span>
  <span class="pl-1 pr-4 py-2 text-sm leading-6 text-blue-400">{{loadedSets !== undefined ? loadedSets.length : 0}}</span>
</li>
<div *ngIf="getIsActiveSetList()" class="px-4 sm:px-6 lg:px-8 bg-gray-50 border border-gray-200 rounded-lg з-">
  <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 rounded-lg з-">
    <div class="inline-block min-w-full py-2 align-middle">
      <table class="min-w-full divide-y divide-gray-200">
        <thead>
        <tr class="">
          <th scope="col"
              (click)="setIsActiveSetList()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                 class="w-6 h-6 ml-6 mr-5 text-gray-400 hover:text-blue-500 cursor-pointer">
              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3"/>
            </svg>
          </th>
          <th *ngIf="listType === RenovationStatusPending && loadedSets.length > 0" scope="col" class="w-6">
            <div (change)="setListService.selectAllSets()">
              <input type="checkbox" [checked]="setListService.isAllSetsSelected || (setListService.pendingSets.length > 0 && setListService.pendingSets.length === setListService.selectedSets.length)"
                     class="float-left h-4 w-4 rounded border-gray-300 text-blue-400 focus:ring-blue-400 focus:text-blue-400">
            </div>
          </th>
          <th (click)="changeOrderBy('uniqueId')" scope="col" class="whitespace-nowrap px-2 py-3.5 text-xs text-left font-medium hover:bg-gray-100 text-gray-500 uppercase cursor-pointer">
            <ng-container *ngIf="setListService.orderByColumn === 'uniqueId'"
                          [ngTemplateOutlet]="setListService.orderDirection == 'DESC' ? orderDirectionDesc : orderDirectionAsc"
                          [ngTemplateOutletContext]="{columnName:'ID'}">
            </ng-container>
            {{setListService.orderByColumn !== 'uniqueId' ? "ID" : ""}}
          </th>
          <th (click)="changeOrderBy('name')" scope="col" class="whitespace-nowrap px-2 py-3.5 text-xs text-left font-medium hover:bg-gray-100 text-gray-500 uppercase cursor-pointer">
            <ng-container *ngIf="setListService.orderByColumn === 'name'"
                          [ngTemplateOutlet]="setListService.orderDirection == 'DESC' ? orderDirectionDesc : orderDirectionAsc"
                          [ngTemplateOutletContext]="{columnName:'Назва'}">
            </ng-container>
            {{setListService.orderByColumn !== 'name' ? "Назва" : ""}}
          </th>
          <th *ngIf="listType === RenovationStatusPending" scope="col" class="relative whitespace-nowrap py-4 sm:pr-0">
            <span class="sr-only">Edit</span>
          </th>
        </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 bg-white">
        <tr *ngFor="let set of loadedSets" class="hover:bg-blue-50 {{setListService.isSetSelected(set) ? 'bg-blue-50' : setListService.notSelectedSetsWarning.includes(set.id) ? 'bg-yellow-50' : ''}}">
          <td class="relative w-10">
            <div *ngIf="setListService.isSetSelected(set)"
                 class="absolute inset-y-0 left-0 w-0.5 bg-blue-400"></div>
          </td>
          <td *ngIf="listType === RenovationStatusPending && loadedSets.length > 0" class="whitespace-nowrap text-sm text-gray-900">
            <input type="checkbox" (change)="setListService.selectSet(set)"
                   [checked]="setListService.isSetSelected(set)"
                   class="h-4 w-4 rounded border-gray-300 text-blue-400 focus:ring-blue-400 focus:text-blue-400">
          </td>
          <td style="max-width: 2.63rem"  class="whitespace-nowrap px-2 text-sm text-gray-900">{{set.uniqueId}}</td>
          <td class="whitespace-nowrap px-2 text-sm text-gray-900">{{set.name}}</td>
          <td *ngIf="listType === RenovationStatusPending" class="whitespace-nowrap px-4 mr-4 text-sm font-medium text-right">
            <button class="text-blue-400 hover:text-blue-500 mr-4" (click)="editSet(set)">
              Edit
            </button>
            <button (click)="deleteSet(set)">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style="top: 3px" class="w-4 h-4 relative text-red-400 mr-2 hover:text-red-800 cursor-pointer">
                <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
              </svg>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #orderDirectionDesc let-columnName="columnName">
  <div class="group inline-flex">
    <span class="relative top-0.5">{{columnName}}</span>
    <span class="ml-2 flex-none rounded bg-green-200 text-green-800" x-description="Active: &quot;bg-gray-200 text-gray-900 group-hover:bg-gray-300&quot;, Not Active: &quot;invisible text-gray-400 group-hover:visible group-focus:visible&quot;">
      <svg class="h-5 w-5" x-description="Heroicon name: mini/chevron-down" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd"></path>
      </svg>
    </span>
  </div>
</ng-template>

<ng-template #orderDirectionAsc let-columnName="columnName">
  <div class="group inline-flex">
    <span class="relative top-0.5">{{columnName}}</span>
    <span class="ml-2 flex-none rounded bg-green-200 text-green-800" x-description="Active: &quot;bg-gray-200 text-gray-900 group-hover:bg-gray-300&quot;, Not Active: &quot;invisible text-gray-400 group-hover:visible group-focus:visible&quot;">
      <svg class="h-5 w-5" x-description="Heroicon name: mini/chevron-down" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" transform="rotate(180)">
        <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd"></path>
      </svg>
    </span>
  </div>
</ng-template>

