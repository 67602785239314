import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from "@angular/common/http";
import {Set} from "./set";
import {v4 as uuidv4} from "uuid";
import {RenovationStatus} from "../renovation/renovation";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SetService {
  editableSet: Set|null = null;

  constructor(
    private http: HttpClient
  ) {}

  createSet(
    uniqueId: string,
    name: string,
  ): Set {
    return {
      id: uuidv4(),
      uniqueId: uniqueId === null || uniqueId === '' ? null : uniqueId,
      name: name,
      status: RenovationStatus.PENDING,
      skinRenovationId: 1,
      createdAt: new Date(),
      updatedAt: null
    }
  }

  savePendingSet(set: Set): Observable<HttpResponse<any>> {
    return this.http.post<any>('/api/v1/ui/skin-renovations/set-create', set, {
      observe: 'response',
      withCredentials: true
    });
  }

  updateSet(set: Set): Observable<HttpResponse<any>> {
    return this.http.patch<any>('/api/v1/ui/skin-renovations/set/' + set.id + '/update' , set, {
      observe: 'response',
      withCredentials: true
    });
  }

  deleteSet(id: string): Observable<HttpResponse<any>> {
    return this.http.delete<any>('/api/v1/ui/skin-renovations/set/' + id + '/delete', {
      observe: 'response',
      withCredentials: true
    });
  }
}
