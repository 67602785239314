import {AbstractControl, FormControl, ValidatorFn} from "@angular/forms";

export function allowedCharactersValidatorForUniqueId(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const allowedPattern = /^[a-z0-9_-]*$/;
    const valid = allowedPattern.test(control.value);
    return valid ? null : { 'invalidCharacters': { value: control.value } };
  };
}

export function appVersionValidator(control: FormControl): { [key: string]: boolean } | null {
  const versionRegex = /^\d+\.\d+\.\d+$/;
  if (control.value && !versionRegex.test(control.value)) {
    return { 'invalidVersion': true };
  }
  return null;
}
