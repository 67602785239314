import {Injectable} from '@angular/core';
import {v4 as uuidv4} from "uuid";
import {Chunk} from "./chunk";
import {RenovationStatus} from "../renovation/renovation";
import {Observable} from "rxjs";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {MapElement} from "../map-element/map-element";


@Injectable({
  providedIn: 'root'
})
export class ChunkService {
  editableChunk: Chunk|null = null;

  constructor(
    private http: HttpClient
  ) {}

  createChunk(uniqueId: string, expForAccessibility: number): Chunk {
    return {
      id: uuidv4(),
      uniqueId: uniqueId,
      expForAccessibility: expForAccessibility,
      status: RenovationStatus.PENDING,
      skinRenovationId: 1,
      createdAt: new Date(),
      updatedAt: null,
    }
  }

  savePendingChunk(chunk: Chunk): Observable<HttpResponse<any>> {
    return this.http.post<any>('/api/v1/ui/skin-renovations/chunk-create', chunk, {
      observe: 'response',
      withCredentials: true
    });
  }

  updateChunk(chunk: Chunk): Observable<HttpResponse<any>> {
    return this.http.patch<any>('/api/v1/ui/skin-renovations/chunk/' + chunk.id + '/update', chunk, {
      observe: 'response',
      withCredentials: true
    });
  }

  deleteChunk(id: string): Observable<HttpResponse<any>> {
    return this.http.delete<any>('/api/v1/ui/skin-renovations/chunk/' + id + '/delete', {
      observe: 'response',
      withCredentials: true
    });
  }
}
