export enum RenovationStatus {
  PENDING = 'pending',
  SAVED = 'saved',
  CONFIRMED = 'confirmed',
}

export enum RenovationOperation {
  EXPORT,
  IMPORT
}

export interface DropdownOption {
  label: string;
  value: string;
}
