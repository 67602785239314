import { Injectable } from '@angular/core';
import {Observable, Subject} from "rxjs";
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from "@angular/common/http";
import {PlayerSavesMetadataByDay, Save} from "./save";
import {PaginationService} from "../../common/pagination-service";
import {trim} from "lodash";

@Injectable({
  providedIn: 'root'
})
export class SaveListService extends PaginationService {
  private _list$ = new Subject<Save[]>();
  private _saveListTotalCount: number = 0;
  private _isAllPlayersSelected: boolean = false;

  selectedPages: number[] = [];
  selectedPagePlayers = new Map<number, string[]>();
  listedPlayerIds: string[] = [];
  listedPlayerIdAppVersionMap = new Map<string, number>();
  csvPlayerIds: string[] = [];

  constructor(private http: HttpClient) {
    super(0, 100, 100, 'receivedTime');
  }

  getList(body: any): Observable<HttpResponse<Save[]>> {
    return this.http.post<Save[]>('/api/v1/ui/save-list', body, {
      headers: new HttpHeaders({
        'Range': 'saves=' + this.from + '-' + this.to,
      }),
      params: {
        from: this.from.toString(),
        to: this.to.toString(),
        orderBy: this.orderByColumn,
        orderDirection: this.orderDirection
      },
      observe: 'response',
      withCredentials: true
    });
  }

  getPlayerSaveListByDay(playerId: string): Observable<HttpResponse<PlayerSavesMetadataByDay>> {
    return this.http.get<PlayerSavesMetadataByDay>('/api/v1/ui/player/' + playerId + '/save-list', {
      observe: 'response',
      withCredentials: true
    });
  }

  getCheatSaveList(searchCheatSaveName: string | null): Observable<HttpResponse<Save[]>> {
    const options: { headers: HttpHeaders, observe: 'response', withCredentials: true, params?: any } = {
      headers: new HttpHeaders({
        'Range': 'cheat-saves=' + this.from + '-' + this.to,
      }),
      params: {
        from: this.from.toString(),
        to: this.to.toString(),
        orderBy: this.orderByColumn === 'receivedTime' ? 'createdAt' : this.orderByColumn, // Костиль за браком часу на розділення компонентів чит сейву та сейву
        orderDirection: this.orderDirection
      },
      observe: 'response',
      withCredentials: true
    };

    if (searchCheatSaveName) {
      options.params.saveName = searchCheatSaveName;
    }

    return this.http.get<Save[]>('/api/v1/ui/cheat-save-list', options);
  }

  get list$(): Subject<Save[]> {
    return this._list$;
  }

  setList(list: Save[]) {
    this._list$.next(list);
  }

  get saveListTotalCount(): number {
    return this._saveListTotalCount;
  }

  set saveListTotalCount(value: number) {
    this._saveListTotalCount = value;
  }

  get isAllPlayersSelected(): boolean {
    return this._isAllPlayersSelected;
  }

  setAllPlayersSelected() {
    this._isAllPlayersSelected = !this._isAllPlayersSelected;
  }

  setAllPlayersNotSelected() {
    this._isAllPlayersSelected = false;
  }

  resetSelectedPageAndPagePlayers() {
    this.selectedPagePlayers = new Map<number, string[]>();
    this.selectedPages = [];
  }

  updatePlayerIdList(saves: Save[]) {
    if (this.listedPlayerIds.length > 0) {
      this.listedPlayerIds = [];
      this.listedPlayerIdAppVersionMap.clear()
    }

    if (saves.length === 0) {
      this.listedPlayerIds = [];
      this.listedPlayerIdAppVersionMap.clear()

      return
    }

    for (const save of saves) {
      this.listedPlayerIds.push(save.playerId);
      this.listedPlayerIdAppVersionMap.set(save.playerId, save.appVersion);
    }
  }

  getSelectedPlayers(): string[] {
    let players: string[] = [];

    this.selectedPagePlayers.forEach((selectedPagePlayers) => {
      players = players.concat(selectedPagePlayers);
    });

    return players;
  }

  chooseSaveListDefaultPagination(): void {
    this.defaultOrderByColumn = 'receivedTime';
    this.orderByColumn = 'receivedTime';
    this.orderDirection = 'DESC';
  }

  chooseCheatSaveListDefaultPagination(): void {
    this.defaultOrderByColumn = 'createdAt';
    this.orderByColumn = 'createdAt';
    this.orderDirection = 'DESC';
  }


// setSimpleSearchFiltersParams(simpleSearchFiltersParams: SimpleSearchFiltersParams) {
  //   this._simpleSearchFiltersParams$.next(simpleSearchFiltersParams);
  // }
  //
  // get simpleSearchFiltersParams$(): Subject<SimpleSearchFiltersParams> {
  //   return this._simpleSearchFiltersParams$;
  // }
  //
  // getSimpleSearchFiltersParams(): Observable<HttpResponse<SimpleSearchFiltersParams>> {
  //   return this.http.get<SimpleSearchFiltersParams>('/api/v1/players-list-filters', {
  //     observe: 'response',
  //     // params: this.resolveListHttpParams(),
  //     withCredentials: true
  //   });
  // }

  deleteCheatSave(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>('/api/v1/ui/cheat-save/' + id + '/delete', {
      observe: 'response',
      withCredentials: true
    });
  }

  exportCheatSaveToEnv(id: number, env: string): Observable<HttpResponse<any>> {
    return this.http.post<any>('/api/v1/ui/cheat-save/' + id + '/export/' + env, {}, {
      observe: 'response',
      withCredentials: true
    });
  }
}
