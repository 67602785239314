import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {trim} from "lodash";
import {CheatSave} from "../cheat-save-list-modal/cheat-save";

@Injectable({
  providedIn: 'root'
})
export class CheatSaveService {
  constructor(private http: HttpClient) {}

  // content: File|string|null
  saveCheatSave(saveName: string, appVersion: string, playerId: string): Observable<HttpResponse<any>> {
    const formData = new FormData();
    formData.append('saveName', saveName);
    formData.append('appVersion', appVersion);
    formData.append('playerId', playerId);

    // if (typeof content === 'string') {
    //   formData.append('content', content);
    // } else if (content === null) {
    //   if (playerId) {
    //     formData.append('playerId', playerId);
    //   }
    // } else {
    //   formData.append('content', content, content.name);
    // }

    const options: { observe: 'response', withCredentials: true, params?: any } = {
      observe: 'response',
      withCredentials: true
    };

    return this.http.post<any>('/api/v1/ui/cheat-save/create', formData, options);
  }

  // content: File|string|null
  updateCheatSave(id: number, saveName: string, appVersion: string, playerId: string): Observable<HttpResponse<any>> {
    const formData = new FormData();
    formData.append('saveName', saveName);
    formData.append('appVersion', appVersion);

    if (playerId) {
      formData.append('playerId', playerId);
    }

    // if (typeof content === 'string') {
    //   formData.append('content', content);
    // } else if (content === null) {
    //   if (playerId) {
    //     formData.append('playerId', playerId);
    //   }
    // } else {
    //   formData.append('content', content, content.name);
    // }

    const options: { observe: 'response', withCredentials: true, params?: any } = {
      observe: 'response',
      withCredentials: true
    };

    return this.http.post<any>('/api/v1/ui/cheat-save/' + id + '/update', formData, options);
  }

  getCheatSave(id: number): Observable<HttpResponse<CheatSave>> {
    return this.http.get<CheatSave>('/api/v1/ui/cheat-save/' + id, {
      observe: 'response',
      withCredentials: true
    });
  }
}
