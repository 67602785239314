<div [formGroup]="mapElementForm" (submit)="save()">
  <div>
    <h3 class="font-medium text-gray-900 p-2.5">Елемент мапи</h3>
    <div>
      <div class="block w-full">
        <input
          formControlName="uniqueId"
          type="text"
          placeholder="Унікальний ID"
          class="input-text block w-full text-sm text-gray-500 border-none {{getInputStyles(this.mapElementForm.controls.uniqueId)}} focus:ring focus:ring-opacity-50">
      </div>
      <div class="block w-full {{getDropdownStyles(this.mapElementForm.controls.chunkId)}} ">
        <ng-select
          [clearable]=false
          [selectOnTab]="true"
          bindValue="id"
          bindLabel="uniqueId"
          formControlName="chunkId"
          placeholder="{{mapElementForm.controls['chunkId'].value === null ? 'Чанк' : ''}}"
          class="custom-select block w-full text-sm text-gray-500 focus:ring focus:ring-opacity-50 mb-10">
          <ng-option *ngFor="let chunk of chunkListService.pendingChunks"
                     [value]="chunk.id">{{chunk.uniqueId}}
<!--            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800"> ✓ </span>-->
          </ng-option>
          <ng-option *ngFor="let chunk of chunkListService.savedChunks"
                     [value]="chunk.id">{{chunk.uniqueId}}
<!--            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"> ✓ </span>-->
          </ng-option>
          <ng-option *ngFor="let chunk of chunkListService.confirmedChunks"
                     [value]="chunk.id">{{chunk.uniqueId}}
<!--            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800"> ✓ </span>-->
          </ng-option>
        </ng-select>
      </div>
      <div class="block w-full {{getDropdownStyles(this.mapElementForm.controls.projection)}}">
        <ng-select
          [clearable]=false
          [selectOnTab]="true"
          bindValue="value"
          bindLabel="label"
          formControlName="projection"
          placeholder="{{mapElementForm.controls['projection'].value === null ? 'Проекція' : ''}}"
          class="custom-select block w-full text-sm text-gray-500 focus:ring focus:ring-opacity-50">
          <ng-option *ngFor="let projection of renovationService.DropdownProjections"
                     [value]="projection.value">{{projection.label}}
            <span class="text-xs text-blue-600 ml-1">{{projection.value}}</span>
          </ng-option>
        </ng-select>
      </div>
    </div>
  </div>
  <div class="flex">
    <button *ngIf="!mapElementService.editableMapElement" type="submit" (click)="save()" class="flex-1 mt-2 py-2 px-4 text-sm font-semibold rounded-lg {{this.mapElementForm.valid ? 'bg-yellow-100 text-yellow-700 hover:bg-yellow-200' : 'bg-gray-300 text-white'}}">В очікування</button>
    <button *ngIf="mapElementService.editableMapElement" type="submit" (click)="update()" class="flex-1 mt-2 py-2 px-4 text-sm font-semibold rounded-lg {{this.mapElementForm.valid ? 'bg-yellow-100 text-yellow-700 hover:bg-yellow-200' : 'bg-gray-300 text-white'}}">Змінити</button>
    <button type="button" (click)="reset()" class="flex-1 mt-2 ml-3 py-2 px-4 text-sm text-white font-semibold rounded-lg {{this.mapElementForm.dirty ? 'bg-red-300 hover:bg-red-400' : 'bg-gray-300'}}">Відмінити</button>
  </div>
</div>
