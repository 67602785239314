import {Injectable} from '@angular/core';
import {RareLevel, Skin, SkinType, SoftCurrency} from "./skin";
import {v4 as uuidv4} from "uuid";
import {MapElementListService} from "../map-element-list/map-element-list.service";
import {SetListService} from "../set-list/set-list.service";
import {MapElement} from "../map-element/map-element";
import {Observable} from "rxjs";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {RenovationStatus} from "../renovation/renovation";

@Injectable({
  providedIn: 'root'
})
export class SkinService {
  editableSkin: Skin|null = null;

  constructor(
    private mapElementListService: MapElementListService,
    private setListService: SetListService,
    private http: HttpClient
  ) {}

  createSkin(
    uniqueId: string,
    name: string,
    mapElementIds: string[],
    setId: string | null,
    skinType: SkinType,
    rareLevel: RareLevel,
    currency: SoftCurrency,
    price: number,
    expForAccessibility: number,
    quest: string | null,
    isRoyal: boolean,
  ): Skin {
    const set = setId !== null ? this.setListService.getSetById(setId) : null;
    const mapElements: MapElement[] = []

    if (mapElementIds !== null && mapElementIds.length > 0) {
      for (const id of mapElementIds) {
        mapElements.push(this.mapElementListService.getMapElementById(id))
      }
    }

    return {
      id: uuidv4(),
      uniqueId: uniqueId === null || uniqueId === '' ? null : uniqueId,
      name: name,
      type: skinType,
      rareLevel: rareLevel,
      mapElements: mapElements,
      set: set,
      softCurrency: currency,
      price: price,
      expForAccessibility: expForAccessibility,
      quest: quest,
      isRoyal: isRoyal,
      status: RenovationStatus.PENDING,
      skinRenovationId: 1,
      createdAt: new Date(),
      updatedAt: null
    }
  }

  savePendingSkin(skin: Skin): Observable<HttpResponse<any>> {
    return this.http.post<any>('/api/v1/ui/skin-renovations/skin-create', skin, {
      observe: 'response',
      withCredentials: true
    });
  }

  updateSkin(skin: Skin): Observable<HttpResponse<any>> {
    return this.http.patch<any>('/api/v1/ui/skin-renovations/skin/' + skin.id + '/update' , skin, {
      observe: 'response',
      withCredentials: true
    });
  }

  deleteSkin(id: string): Observable<HttpResponse<any>> {
    return this.http.delete<any>('/api/v1/ui/skin-renovations/skin/' + id + '/delete', {
      observe: 'response',
      withCredentials: true
    });
  }
}
