import { Component, OnInit } from '@angular/core';
import {RenovationService} from "../renovation/renovation.service";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {trans} from "../../../../translations";
import {SkinService} from "./skin.service";
import {SkinListService} from "../skin-list/skin-list.service";
import {MapElementListService} from "../map-element-list/map-element-list.service";
import {SetListService} from "../set-list/set-list.service";
import {MapElement} from "../map-element/map-element";
import {RareLevel, SkinType} from "./skin";
import {NotificationsService} from "angular2-notifications";
import {HttpErrorResponse} from "@angular/common/http";
import {RenovationListService} from "../renovation-list/renovation-list.service";
import {RenovationStatus} from "../renovation/renovation";
import {allowedCharactersValidatorForUniqueId} from "../../../form-validators/validators";

@Component({
  selector: 'app-skin',
  templateUrl: './skin.component.html',
  styleUrls: ['./skin.component.scss']
})
export class SkinComponent implements OnInit {

  public readonly RenovationStatusPending = RenovationStatus.PENDING;
  public readonly RenovationStatusSaved = RenovationStatus.SAVED;
  public readonly RenovationStatusConfirmed = RenovationStatus.CONFIRMED;

  public readonly trans = trans;
  public isActiveCurrencyAndPriceField: boolean = true;
  public isActiveExpForAccessibilityField: boolean = true;
  private savedMapElementIds: string[] = []

  skinForm = new FormGroup({
    uniqueId:                   new FormControl(null, [Validators.maxLength(128), allowedCharactersValidatorForUniqueId()]),
    name:                       new FormControl(null, [Validators.required, Validators.maxLength(128)]),
    mapElementIds:              new FormControl(null, []),
    setId:                      new FormControl(null, []),
    rareLevel:                  new FormControl(null, [Validators.required, Validators.min(0), Validators.max(5)]),
    currency:                   new FormControl(null, []),
    price:                      new FormControl(null, [Validators.min(0)]),
    skinType:                   new FormControl(null, [Validators.required]),
    expForAccessibility:        new FormControl(null, [Validators.required, Validators.min(-1)]),
    quest:                      new FormControl(null, []),
    isRoyal:                    new FormControl(false,[Validators.required]),
  });

  constructor(
    private notificationsService: NotificationsService,
    private renovationListService: RenovationListService,
    public renovationService: RenovationService,
    public mapElementListService: MapElementListService,
    public setListService: SetListService,
    public skinService: SkinService,
    private skinListService: SkinListService,
  ) {}

  ngOnInit(): void {
    if (this.skinService.editableSkin) {
      if (!this.skinForm.dirty) {
        this.skinForm.reset({
          uniqueId: this.skinService.editableSkin.uniqueId,
          name: this.skinService.editableSkin.name,
          mapElementIds: this.skinService.editableSkin.mapElements.map(e => e.id),
          setId: this.skinService.editableSkin.set?.id,
          skinType: this.skinService.editableSkin.type.toString(),
          rareLevel: this.skinService.editableSkin.rareLevel.toString(),
          currency: this.skinService.editableSkin.softCurrency?.toString(),
          price: this.skinService.editableSkin.price,
          expForAccessibility: this.skinService.editableSkin.expForAccessibility,
          quest: this.skinService.editableSkin.quest,
          isRoyal: this.skinService.editableSkin.isRoyal,
        });
        this.skinForm.markAsDirty()
      }
    }
  }

  save() {
    if (this.skinForm.valid) {
      const skin = this.skinService.createSkin(
        this.skinForm.controls['uniqueId'].value,
        this.skinForm.controls['name'].value,
        this.skinForm.controls['mapElementIds'].value,
        this.skinForm.controls['setId'].value,
        this.skinForm.controls['skinType'].value,
        this.skinForm.controls['rareLevel'].value,
        this.skinForm.controls['currency'].value,
        this.skinForm.controls['price'].value,
        this.skinForm.controls['expForAccessibility'].value,
        this.skinForm.controls['quest'].value,
        this.skinForm.controls['isRoyal'].value,
      );

      console.log("SKIN", skin)
      this.skinService.savePendingSkin(skin).subscribe(
        (data) => {
          if (data.status === 201) {
            this.skinListService.addPendingSkin(skin)
            this.reset()
          }
        }, (data) => {
          if (data instanceof HttpErrorResponse) {
            if (data.status < 500) {
              this.notificationsService.error('Помилка!', data.error, {
                timeOut: 5000,
                showProgressBar: true,
                pauseOnHover: true,
                clickToClose: true
              });
            } else {
              this.notificationsService.error('Помилка!', data.error.detail, {
                timeOut: 5000,
                showProgressBar: true,
                pauseOnHover: true,
                clickToClose: true
              });
            }
          } else {
            this.notificationsService.error('Помилка!', "Невдалося додати скін в очікування", {
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            });
          }
        }
      );
    }
  }

  setSkinRoyal(): void {
    this.skinForm.controls['isRoyal'].setValue(!this.skinForm.controls['isRoyal'].value);
    this.onChangeRoyalUpdateMapElements()
  }

  isSkinRoyal(): boolean {
    return this.skinForm.controls['isRoyal'].value
  }

  update(): void {
    if (this.skinService.editableSkin && this.skinForm.valid) {
      const modifiedSkin = {
        ...this.skinService.editableSkin,
        uniqueId: this.skinForm.controls.uniqueId.value,
        name: this.skinForm.controls.name.value,
        mapElementIds: this.skinForm.controls.mapElementIds.value,
        setId: this.skinForm.controls.setId.value,
        type: this.skinForm.controls.skinType.value,
        rareLevel: this.skinForm.controls.rareLevel.value,
        softCurrency: this.skinForm.controls.currency.value,
        price: this.skinForm.controls.price.value,
        expForAccessibility: this.skinForm.controls.expForAccessibility.value,
        quest: this.skinForm.controls.quest.value,
        isRoyal: this.skinForm.controls.isRoyal.value,
      };
      this.skinService.updateSkin(modifiedSkin).subscribe(
        (data) => {
          if (data.status === 200) {
            this.notificationsService.success('Успіх!', 'Скін змінено', {
              timeOut: 1500,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            });
            this.renovationListService.fetchAndLoadSkinRenovations()
            this.reset()
          }
        }, (data) => {
          if (data instanceof HttpErrorResponse) {
            if (data.status < 500) {
              this.notificationsService.error('Помилка!', data.error, {
                timeOut: 5000,
                showProgressBar: true,
                pauseOnHover: true,
                clickToClose: true
              });
            } else {
              this.notificationsService.error('Помилка!', data.error.detail, {
                timeOut: 5000,
                showProgressBar: true,
                pauseOnHover: true,
                clickToClose: true
              });
            }
          } else {
            this.notificationsService.error('Помилка!', "Невдалося оновити скін", {
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            });
          }
        }
      );
    }
  }

  reset() {
    if (this.skinForm.dirty) {
      this.skinService.editableSkin = null
      this.skinForm.reset({
        uniqueId: null,
        name: null,
        mapElementIds: null,
        setId: null,
        skinType: null,
        rareLevel: null,
        currency: null,
        price: null,
        expForAccessibility: null,
        quest: null,
        isRoyal: false,
      });
    }
  }

  getSelectedDropdownMapElements() {
    const mapElements: MapElement[] = []

    for (const id of this.skinForm.controls['mapElementIds'].value) {
      mapElements.push(this.mapElementListService.getMapElementById(id))
    }

    return mapElements;
  }

  removeDropdownMapElement(mapElement: MapElement) {
    this.skinForm.controls['mapElementIds'].setValue(this.skinForm.controls['mapElementIds'].value.filter((pl: string) => pl !== mapElement.id))
  }

  onChangeRoyalUpdateMapElements() {
    const mapElementsIds: string[]|null = this.skinForm.controls['mapElementIds'].value

    if (this.skinForm.controls['isRoyal'].value && mapElementsIds && mapElementsIds.length > 1) {
      this.savedMapElementIds = mapElementsIds;
      this.skinForm.controls['mapElementIds'].setValue([mapElementsIds[0]])

      return
    }

    if (this.skinForm.controls['isRoyal'].value === false && this.savedMapElementIds.length > 0) {
      this.skinForm.controls['mapElementIds'].setValue(this.savedMapElementIds)
      this.savedMapElementIds = []
    }
  }

  onChangeMapElement() {
    if (this.skinForm.controls['isRoyal'].value === true) {
      const mapElementIds: string[]|null = this.skinForm.controls['mapElementIds'].value

      if (mapElementIds && mapElementIds.length > 1) {
        this.skinForm.controls['mapElementIds'].setValue([mapElementIds[1]])
        this.savedMapElementIds = [mapElementIds[1]];
      }
    }
  }

  onChangeSkinTypeOrRareLevel() {
    const skinType = this.skinForm.controls['skinType'].value !== null
      ? Number(this.skinForm.controls['skinType'].value)
      : null

    const rareLevel = this.skinForm.controls['rareLevel'].value !== null
      ? Number(this.skinForm.controls['rareLevel'].value)
      : null

    if (skinType === SkinType.AWARDS
      || skinType === SkinType.EVENT_AWARD
      || (skinType === SkinType.COMMON && rareLevel === RareLevel.ZERO)
    ) {
      this.isActiveCurrencyAndPriceField = false;
    } else {
      this.isActiveCurrencyAndPriceField = true;
    }

    if (skinType === SkinType.BASE) {
      this.skinForm.controls.rareLevel.addValidators(Validators.max(0));
      this.skinForm.controls.rareLevel.updateValueAndValidity();
    } else {
      this.skinForm.controls.rareLevel.clearValidators();
      this.skinForm.controls.rareLevel.addValidators([Validators.required, Validators.min(-1), Validators.max(5)]);
      this.skinForm.controls.rareLevel.updateValueAndValidity();
    }

    if (skinType === SkinType.EVENT_OR_TLE || skinType === SkinType.AWARDS || skinType === SkinType.EVENT_AWARD) {
      this.isActiveExpForAccessibilityField = false;
      this.skinForm.controls.expForAccessibility.clearValidators();
      this.skinForm.controls.expForAccessibility.updateValueAndValidity();
    } else {
      this.isActiveExpForAccessibilityField = true;
      this.skinForm.controls.expForAccessibility.addValidators([Validators.required, Validators.min(-1)]);
      this.skinForm.controls.expForAccessibility.updateValueAndValidity();
    }
  }

  getInputStyles(control: AbstractControl): string {
    if (control.dirty && control.invalid) {
      return 'focus:border-red-300 focus:ring-red-200 bg-red-50';
    }

    if (control.dirty && control.valid) {
      return 'focus:border-green-300 focus:ring-green-200 bg-green-50';
    }

    return 'focus:border-blue-300 focus:ring-blue-200 bg-white';
  }

  getDropdownStyles(control: AbstractControl): string {
    if (control.dirty && control.invalid) {
      return 'custom-select-container focus:border-red-300 focus:ring-red-200 bg-red-50';
    }

    if (control.dirty && control.valid) {
      return 'custom-select-container focus:border-green-300 focus:ring-green-200 bg-green-50';
    }

    return 'custom-select-container focus:border-blue-300 focus:ring-blue-200 bg-white';
  }

  onChangeExpForAccessibility() {
    // const expForAccessibility = this.skinForm.controls['expForAccessibility'].value !== null
    //   ? Number(this.skinForm.controls['expForAccessibility'].value)
    //   : null
    //
    // console.log(expForAccessibility);
    //
    // if (expForAccessibility === -1) {
    //   this.skinForm.controls.quest.addValidators([Validators.required]);
    //   this.skinForm.controls.quest.updateValueAndValidity();
    // } else {
    //   this.skinForm.controls.quest.clearValidators();
    //   this.skinForm.controls.quest.updateValueAndValidity();
    // }
  }
}
