import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService { // TODO Перенести сюда удаление токена и пермишинов

  isAuthenticated: boolean = false;
  authenticatedChange = new Subject<boolean>();

  constructor() {
    this.authenticatedChange.subscribe((value) => {
      this.isAuthenticated = value;
    });
  }

  isStillAuthenticated() {
    this.authenticatedChange.next(!!this.getToken());
  }

  getToken(): string|null {
    return localStorage.getItem('token');
  }

  setToken(token: string): void {
    localStorage.setItem('token', token);
  }

  removeToken() {
    localStorage.removeItem('token');
  }

  getId(): string|null {
    return localStorage.getItem('id');
  }

  setId(id: string): void {
    localStorage.setItem('id', id);
  }

  removeId() {
    localStorage.removeItem('id');
  }
}
