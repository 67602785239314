import {Component, OnInit} from '@angular/core';
import {RenovationService} from "../renovation/renovation.service";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {SetListService} from "../set-list/set-list.service";
import {SetService} from "./set.service";
import {HttpErrorResponse} from "@angular/common/http";
import {NotificationsService} from "angular2-notifications";
import {allowedCharactersValidatorForUniqueId} from "../../../form-validators/validators";
import {RenovationListService} from "../renovation-list/renovation-list.service";

@Component({
  selector: 'app-set',
  templateUrl: './set.component.html',
  styleUrls: ['./set.component.scss']
})
export class SetComponent implements OnInit {
  setForm = new FormGroup({
    uniqueId: new FormControl(null, [Validators.maxLength(128), allowedCharactersValidatorForUniqueId()]),
    name:     new FormControl(null, [Validators.required, Validators.maxLength(128)]),
  });

  constructor(
    private notificationsService: NotificationsService,
    public renovationService: RenovationService,
    public renovationListService: RenovationListService,
    public setListService: SetListService,
    public setService: SetService,
  ) {}

  ngOnInit(): void {
    if (this.setService.editableSet) {
      if (!this.setForm.dirty) {
        this.setForm.reset({
          uniqueId: this.setService.editableSet.uniqueId,
          name: this.setService.editableSet.name,
        });
        this.setForm.markAsDirty()
      }
    }
  }

  save() {
    if (this.setForm.valid) {
      const set = this.setService.createSet(
        this.setForm.controls['uniqueId'].value,
        this.setForm.controls['name'].value,
      );

      this.setService.savePendingSet(set).subscribe(
        (data) => {
          if (data.status === 201) {
            this.setListService.addPendingSet(set)
            this.reset()
          }
        }, (data) => {
          if (data instanceof HttpErrorResponse) {
            if (data.status < 500) {
              this.notificationsService.error('Помилка!', data.error, {
                timeOut: 5000,
                showProgressBar: true,
                pauseOnHover: true,
                clickToClose: true
              });
            } else {
              this.notificationsService.error('Помилка!', data.error.detail, {
                timeOut: 5000,
                showProgressBar: true,
                pauseOnHover: true,
                clickToClose: true
              });
            }
          } else {
            this.notificationsService.error('Помилка!', "Невдалося додати сет в очікування", {
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            });
          }
        }
      );
    }
  }

  update(): void {
    if (this.setService.editableSet && this.setForm.valid) {
      const modifiedSet = {
        ...this.setService.editableSet,
        uniqueId: this.setForm.controls.uniqueId.value,
        name: this.setForm.controls.name.value,
      };
      this.setService.updateSet(modifiedSet).subscribe(
        (data) => {
          if (data.status === 200) {
            this.notificationsService.success('Успіх!', 'Сет змінено', {
              timeOut: 1500,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            });
            this.renovationListService.fetchAndLoadSkinRenovations()
            this.reset()
          }
        }, (data) => {
          if (data instanceof HttpErrorResponse) {
            if (data.status < 500) {
              this.notificationsService.error('Помилка!', data.error, {
                timeOut: 5000,
                showProgressBar: true,
                pauseOnHover: true,
                clickToClose: true
              });
            } else {
              this.notificationsService.error('Помилка!', data.error.detail, {
                timeOut: 5000,
                showProgressBar: true,
                pauseOnHover: true,
                clickToClose: true
              });
            }
          } else {
            this.notificationsService.error('Помилка!', "Невдалося оновити сет", {
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            });
          }
        }
      );
    }
  }

  reset() {
    if (this.setForm.dirty) {
      this.setService.editableSet = null
      this.setForm.reset({
        uniqueId: null,
        name: null,
      });
    }
  }

  // isEditableSetEqualToFormValues(): boolean {
  //   return this.setService.editableSet?.uniqueId === this.setForm.controls.uniqueId.value
  //     && this.setService.editableSet?.name === this.setForm.controls.name.value
  // }

  getInputStyles(control: AbstractControl): string {
    if (control.dirty && control.invalid) {
      return 'focus:border-red-300 focus:ring-red-200 bg-red-50';
    }

    if (control.dirty && control.valid) {
      return 'focus:border-green-300 focus:ring-green-200 bg-green-50';
    }

    return 'focus:border-blue-300 focus:ring-blue-200';
  }
}
