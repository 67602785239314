import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {RenovationListService} from "./renovation-list.service";
import {RenovationStatus} from "../renovation/renovation";
import {MapElementListService} from "../map-element-list/map-element-list.service";
import {ChunkListService} from "../chunk-list/chunk-list.service";
import {SetListService} from "../set-list/set-list.service";
import {HttpErrorResponse} from "@angular/common/http";
import {NotificationsService} from "angular2-notifications";
import {SkinListService} from "../skin-list/skin-list.service";
import {appVersionValidator} from "../../../form-validators/validators";

@Component({
  selector: 'app-renovation-list',
  templateUrl: './renovation-list.component.html',
  styleUrls: ['./renovation-list.component.scss']
})
export class RenovationListComponent implements OnInit {

  public readonly RenovationStatusPending = RenovationStatus.PENDING;
  public readonly RenovationStatusSaved = RenovationStatus.SAVED;
  public readonly RenovationStatusConfirmed = RenovationStatus.CONFIRMED;

  isActivePendingOption: boolean = true;
  isActiveSavedOption: boolean = true;
  isActiveConfirmedOption: boolean = true;

  constructor(
    private notificationsService: NotificationsService,
    public renovationListService: RenovationListService,
    public mapElementListService: MapElementListService,
    public chunkListService: ChunkListService,
    public skinListService: SkinListService,
    public setListService: SetListService,
  ) {}

  ngOnInit(): void {
    this.renovationListService.fetchAndLoadSkinRenovations()
  }

  saveVersionedSkinRenovationForm = new FormGroup({
    appVersion: new FormControl(null, [Validators.required, appVersionValidator]),
  });

  confirmVersionedSkinRenovationForm = new FormGroup({
    gitBranch: new FormControl(null, [Validators.required, Validators.maxLength(256)]),
  });

  saveSkinRenovation() {
    if (this.saveVersionedSkinRenovationForm.valid && this.renovationListService.isCreatedAtLeastOneElement()) {
      const appVersion = this.saveVersionedSkinRenovationForm.controls['appVersion'].value;

      if (!confirm("Ви впевнені що хочете зберегти усі створені сутності під версією: " + appVersion + '?')) {
        return;
      }

      const saveVersionedSkinRenovation = this.renovationListService.saveVersionedRenovationList(appVersion);

      if (saveVersionedSkinRenovation) {
        this.renovationListService.createSkinRenovationStatus(saveVersionedSkinRenovation).subscribe(
          (data) => {
            if (data.status === 201) {
              this.notificationsService.success('Успіх!', 'Збережено', {
                timeOut: 1500,
                showProgressBar: true,
                pauseOnHover: true,
                clickToClose: true
              });
              this.renovationListService.fetchAndLoadSkinRenovations()
            }
          }, (data) => {
            if (data instanceof HttpErrorResponse) {
              console.log(data)
              this.notificationsService.error('Помилка!', data.error.detail, {
                timeOut: 5000,
                showProgressBar: true,
                pauseOnHover: true,
                clickToClose: true
              });
            } else {
              this.notificationsService.error('Помилка!', "Невдалося зберегти версіоновану реновацію скінів", {
                timeOut: 5000,
                showProgressBar: true,
                pauseOnHover: true,
                clickToClose: true
              });
            }
          }
        )
      }
    }
  }

  confirmSkinRenovation(id: number) {
    if (this.confirmVersionedSkinRenovationForm.valid) {
      const gitBranch = this.confirmVersionedSkinRenovationForm.controls['gitBranch'].value

      this.renovationListService.confirmSkinRenovation(id, gitBranch).subscribe(
        (data) => {
          if (data.status === 200) {
            this.notificationsService.info('Успіх!', 'Підтверджено', {
              timeOut: 1500,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            });
            this.renovationListService.fetchAndLoadSkinRenovations()
          }
        }, (data) => {
          if (data instanceof HttpErrorResponse) {
            console.log(data)
            this.notificationsService.error('Помилка!', data.error.detail, {
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            });
          } else {
            this.notificationsService.error('Помилка!', "Невдалося підтвердити версіоновану реновацію скінів", {
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            });
          }
        }
      )
    }
  }

  getInputStyles(control: AbstractControl): string {
    if (control.dirty && control.invalid) {
      return 'focus:border-red-300 focus:ring-red-200 bg-red-50';
    }

    if (control.dirty && control.valid) {
      return 'focus:border-green-300 focus:ring-green-200 bg-green-50';
    }

    return 'focus:border-blue-300 focus:ring-blue-200';
  }
}
