<main *ngIf="dashboard$ | async as dashboard">
  <header class="bg-gray-50 border border-gray-200 overflow-hidden overflow-x-auto rounded-lg з-">
    <div class="grid grid-cols-1 bg-gray-700/10 sm:grid-cols-2 lg:grid-cols-4">
      <div class="py-4 px-4 sm:px-6 lg:px-8">
        <p class="text-sm font-medium leading-6 text-gray-500">Гравців онлайн</p>
        <p class="mt-2 flex items-baseline gap-x-2">
          <span class="text-4xl font-semibold tracking-tight text-gray-500">{{dashboard.playersOnlineCount}}</span>
        </p>
      </div>
      <div class="py-4 px-4 sm:px-6 lg:px-8 sm:border-l">
        <p class="text-sm font-medium leading-6 text-gray-500">Активних деплоїв</p>
        <p class="mt-2 flex items-baseline gap-x-2">
          <span class="text-4xl font-semibold tracking-tight text-gray-500">{{dashboard.deploysActiveCount}}</span>
<!--          <span class="text-sm text-gray-400">* Де state = applied, наповнення менше 100%, активний час</span>-->
        </p>
      </div>
<!--      <div class="border-t border-white/5 py-6 px-4 sm:px-6 lg:px-8 lg:border-l">-->
<!--        <p class="text-sm font-medium leading-6 text-gray-500">Number of servers</p>-->
<!--        <p class="mt-2 flex items-baseline gap-x-2">-->
<!--          <span class="text-4xl font-semibold tracking-tight text-white">3</span>-->
<!--        </p>-->
<!--      </div>-->
<!--      <div class="border-t border-white/5 py-6 px-4 sm:px-6 lg:px-8 sm:border-l">-->
<!--        <p class="text-sm font-medium leading-6 text-gray-500">Success rate</p>-->
<!--        <p class="mt-2 flex items-baseline gap-x-2">-->
<!--          <span class="text-4xl font-semibold tracking-tight text-white">98.5%</span>-->
<!--        </p>-->
<!--      </div>-->
    </div>
  </header>
</main>
