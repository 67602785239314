import { Injectable } from '@angular/core';
import {Observable, Subject} from "rxjs";
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {PaginationService} from "../../common/pagination-service";
import {RemoteConfig} from "../remote-config/remote-config";

@Injectable({
  providedIn: 'root'
})
export class RemoteConfigListService extends PaginationService {
  private _remoteConfigs$ = new Subject<RemoteConfig[]>();

  constructor(private http: HttpClient) {
    super(0, 10000, 10000);
  }

  setRemoteConfigs(remoteConfigs: RemoteConfig[]) {
    this._remoteConfigs$.next(remoteConfigs);
  }

  get remoteConfigs$(): Subject<RemoteConfig[]> {
    return this._remoteConfigs$;
  }

  updateRemoteConfigContent(id: number, content: string): Observable<HttpResponse<any>> {
    return this.http.post<any>('/api/v1/ui/remote-config/' + id + '/update-content', {
      "content": content
    }, {
      observe: 'response',
      withCredentials: true
    });
  }

  deleteRemoteConfig(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>('/api/v1/ui/remote-config/' + id + '/delete', {
      observe: 'response',
      withCredentials: true
    });
  }

  getRemoteConfigList(): Observable<HttpResponse<RemoteConfig[]>> {
    return this.http.get<RemoteConfig[]>('/api/v1/ui/remote-config-list', {
      headers: new HttpHeaders({
        'Range': 'remote-configs=' + this.from + '-' + this.to,
      }),
      params: {
        from: this.from.toString(),
        to: this.to.toString(),
        orderBy: this.orderByColumn,
        orderDirection: this.orderDirection
      },
      observe: 'response',
      withCredentials: true
    });
  }
}
