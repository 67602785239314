export function csvToArray(str: string, delimiter: string = ","): object {
  const headers = str.slice(0, str.indexOf("\n")).split(delimiter);

  const rows = str.slice(str.indexOf("\n") + 1).split("\n");

  return rows.map(function (row) {
    const values = row.split(delimiter);
    return headers.reduce(function (object, header, index) {
      (object as any)[header] = values[index];
      return object;
    }, {});
  });
}
