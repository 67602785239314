<div [formGroup]="cheatSaveForm" (submit)="save()">
  <div>
    <h3 class="mt-7 font-medium text-gray-900 p-2.5">Чит сейв</h3>
    <div>
      <div class="block w-full">
        <input
          formControlName="saveName"
          id="34"
          type="text"
          placeholder="Назва"
          class="input-text block w-full text-sm border-none {{getInputStyles(this.cheatSaveForm.controls.saveName)}} focus:ring focus:ring-opacity-50">
      </div>
      <div class="block w-full">
        <input
          formControlName="appVersion"
          id="44"
          type="text"
          placeholder="App Version"
          class="input-text block w-full text-sm border-none {{getInputStyles(this.cheatSaveForm.controls.appVersion)}} focus:ring focus:ring-opacity-50">
      </div>
      <div class="block w-full">
        <input
          formControlName="playerId"
          id="55"
          type="text"
          placeholder="Взяти сейв по PlayerId"
          class="input-text block w-full text-sm border-none {{getInputStyles(this.cheatSaveForm.controls.playerId)}} focus:ring focus:ring-opacity-50">
      </div>
<!--      <div class="block w-full mb-2">-->
<!--        <input-->
<!--          formControlName="content"-->
<!--          id="66"-->
<!--          type="text"-->
<!--          placeholder="Binary текст"-->
<!--          class="input-text block w-full text-sm border-none {{getInputStyles(this.cheatSaveForm.controls.minVersionBuild)}} focus:ring focus:ring-opacity-50">-->
<!--      </div>-->
<!--      <div class="col-span-full" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">-->
<!--        <div class="mt-2 flex justify-center rounded-lg border border-dashed border-{{getFileInputColor()}}-300 px-6 py-10">-->
<!--          <div class="text-center">-->
<!--            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mx-auto h-12 w-12 text-{{getFileInputColor()}}-300">-->
<!--              <path stroke-linecap="round" stroke-linejoin="round" d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />-->
<!--            </svg>-->
<!--            <div class="mt-4 flex text-sm leading-6 text-gray-600">-->
<!--              <label for="file-upload" class="relative cursor-pointer rounded-md font-semibold text-blue-500 focus-within:outline-none focus-within:ring-1 focus-within:ring-blue-500 focus-within:ring-offset-1 hover:text-blue-500">-->
<!--                <span>Або завантажити Binary файл</span>-->
<!--                <input id="file-upload" name="file-upload" type="file" class="sr-only" (change)="onFileUploadBtn($event)">-->
<!--              </label>-->
<!--            </div>-->
<!--            <p class="text-xs leading-5 text-gray-600">або drag and drop</p>-->
<!--            <div *ngIf="uploadedFile != null">-->
<!--              <br>-->
<!--              <p class="text-sm font-bold leading-5 text-gray-600">{{uploadedFile.name}}</p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
  <div class="flex">
    <button *ngIf="this.cheatSaveForm.valid && this.editingCheatSave !== null && isEditingCheatSaveNotEqualToFormValues()" type="submit" (click)="update()" class="flex-1 mt-2 py-2 px-4 bg-green-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-green-500">Зберегти</button>
    <button *ngIf="this.cheatSaveForm.valid && this.editingCheatSave === null" type="submit" (click)="save()" class="flex-1 mt-2 py-2 px-4 bg-green-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-green-500">Зберегти</button>
    <button *ngIf="this.cheatSaveForm.dirty" type="button" (click)="reset()" class="flex-1 mt-2 ml-3 py-2 px-4 bg-red-300 text-sm text-white font-semibold rounded-lg shadow-md hover:bg-red-500">Відмінити</button>
  </div>
</div>
