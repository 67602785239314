import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {NgSelectConfig} from "@ng-select/ng-select";
import {NotificationsService} from "angular2-notifications";
import {forkJoin, Observable} from "rxjs";
import {OperationType, Profile} from './profile';
import {ProfileService} from "./profile.service";
import {DeployModalService} from "../../deploy/deploy-modal/deploy-modal.service";
import {trans} from "../../../translations";
import {Save} from "../save-list/save";
import {HttpErrorResponse} from "@angular/common/http";
import {PageTypeEnum} from "../../common/page";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  @ViewChild('deploy', { read: ViewContainerRef })
  deploy!: ViewContainerRef;

  isShowingProfileActions = true;
  isShowingProfileDeactivationModal = false;
  isShowingProfileEraseModal = false;
  isShowingProfileAddNoteModal = false;
  isEditModeEnabledForABGroups = false;
  isEditModeEnabledForAnotherGroups = false;
  isShowingAllPlayerDevices = false;

  profile$: Observable<Profile>;

  selectedOperationType: OperationType|null = null;
  selectedSectionsValueId: number|null = null;

  inputType: string = "text";
  inputPlaceholder: string = trans('Change count');

  gameValuesFormDefaults = {
    operationType: trans('Select operation'),
    name: trans('Select item'),
    value: null,
  }

  redeemNameTypeMap = new Map<string, string>([
    ['level', 'int'],
    ['coins', 'int'],
    ['ruby', 'int'],
    ['keys', 'int'],
    ['historyNumber', 'int'],
    ['percent', 'int']
  ]);

  redeemNameIdMap = new Map<string, string>([
    ['level', ''],
    ['coins', 'CURRENCY_COIN'],
    ['ruby', 'CURRENCY_RUBY'],
    ['keys', ''],
    ['historyNumber', 'CURRENCY_HISTORY_RESOURCE'],
    ['percent', '']
  ]);


  // profileValuesForm для редактирования персональных данных

  gameValuesForm = new FormGroup({
    'operationType': new FormControl(this.gameValuesFormDefaults.operationType, []),
    'name':          new FormControl(this.gameValuesFormDefaults.name, []),
    'value':         new FormControl(this.gameValuesFormDefaults.value, []),
  });

  changeStatusForm = new FormGroup({
    'reason': new FormControl('', [Validators.required, Validators.minLength(1)]),
  });

  constructor(
    private profileService: ProfileService,
    private deployModalService: DeployModalService,
    private route: ActivatedRoute,
    private router: Router,
    private config: NgSelectConfig,
    private notificationsService: NotificationsService,
  ) {
    this.profile$ = this.profileService.profile$;
  }

  ngOnInit(): void {
    const playerId = this.route.snapshot.paramMap.get('id');

    if (playerId) {
      forkJoin({
        profile: this.profileService.getProfile(playerId),
        // sectionsKeyNamesList: this.profileVictorianQuestService.getSectionsKeyNamesList(),
        // deployModalList: this.deployModalService.getDeployModalList()
      }).subscribe(results => {

        // if (results.sectionsKeyNamesList.body) {
        //   this.profileVictorianQuestService.setSectionsKeyNamesList(results.sectionsKeyNamesList.body);
        // }

        if (results.profile.body) {
          this.profileService.setProfile(results.profile.body);
        }

        // if (results.deployModalList.body) {
        //   this.deployModalService.setDeployModalList(results.deployModalList.body);
        // }
      });
    }
  }

  fetchPlayer() {
    const playerId = this.route.snapshot.paramMap.get('id');

    if (playerId) {
      this.profileService.getProfile(playerId).subscribe(
        (data) => {
          if (data.body !== null) {
            this.profileService.setProfile(data.body);
          }
        }
      );
    }
  }

  getProfileGameValues(profile: Profile) {
    const lastSave = profile.saves[0];
    const profileGameValues = new Map<string, number>();

    for (const [propertyName, value] of Object.entries(lastSave)) {
      if (this.redeemNameTypeMap.has(propertyName)) {
        profileGameValues.set(propertyName, value);
      }
    }

    return profileGameValues;
  }

  deactivateProfile() {
    const playerId = this.route.snapshot.paramMap.get('id');

    if (this.changeStatusForm.valid && playerId) {
      this.isShowingProfileDeactivationModal = !this.isShowingProfileDeactivationModal;
      this.profileService.changeProfileStatus(
        Number(playerId),
        'blocked',
        this.changeStatusForm.controls['reason'].value
      ).subscribe(
        (data) => {
          if (data.status === 200) {
            this.fetchPlayer();
            this.notificationsService.success('Успешно', 'Пользовать заблокирован', {
              timeOut: 1500,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            });
          }
        }
      );

      this.changeStatusForm.reset();
      return;
    }

    this.focusById('deactivateTextForm');
  }

  eraseProfileWithSaves() {
    const playerId = this.route.snapshot.paramMap.get('id');

    if (this.changeStatusForm.valid && playerId) {
      this.isShowingProfileEraseModal = !this.isShowingProfileEraseModal;

      this.profileService.eraseProfile(playerId, this.changeStatusForm.controls['reason'].value).subscribe(
        (data) => {
          if (data.status === 200) {
            this.notificationsService.success('Успіх', "Профіль та сейв гравця: '" + playerId + "' стерті" , {
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            });
            this.router.navigate([PageTypeEnum.SAVE_LIST])
          }
        }, (data) => {
          if (data instanceof HttpErrorResponse) {
            if (data.status < 500) {
              this.notificationsService.error('Помилка!', data.error, {
                timeOut: 5000,
                showProgressBar: true,
                pauseOnHover: true,
                clickToClose: true
              });
            } else {
              this.notificationsService.error('Помилка!', data.error.detail, {
                timeOut: 5000,
                showProgressBar: true,
                pauseOnHover: true,
                clickToClose: true
              });
            }
          } else {
            this.notificationsService.error('Помилка!', "Невдалося стерти профіль гравця", {
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            });
          }
        }
      );

      this.changeStatusForm.reset();
      return;
    }
    this.focusById('eraseTextForm');
  }

  addNoteToProfile() {
    const playerId = this.route.snapshot.paramMap.get('id');

    if (this.changeStatusForm.valid && playerId) {
      this.isShowingProfileAddNoteModal = !this.isShowingProfileAddNoteModal;
      this.profileService.addNoteToPlayer(
        Number(playerId),
        this.changeStatusForm.controls['reason'].value
      ).subscribe(
        (data) => {
          if (data.status === 201) {
            this.fetchPlayer();
            this.notificationsService.success('Успешно', 'Примечание добавлено', {
              timeOut: 1500,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            });
          }
        }
      );

      this.changeStatusForm.reset();
      return;
    }
    this.focusById('addNoteTextForm');
  }

  focusById(id: string) {
    document.getElementById(id)?.focus();
  }

  // Returned from backend id as string
  isCachedSave(save: Save): boolean {
    return typeof save.id == 'string';
  }
}
