import { Component, OnInit } from '@angular/core';
import {RenovationService} from "./renovation.service";

@Component({
  selector: 'app-renovation',
  templateUrl: './renovation.component.html',
  styleUrls: ['./renovation.component.scss']
})
export class RenovationComponent implements OnInit {

  constructor(
    public renovationService: RenovationService,
  ) {}

  ngOnInit(): void {}
}
