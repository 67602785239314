<div class="relative my-10">
  <div style="left: 85px;" class="absolute inset-0 flex items-center" aria-hidden="true">
    <div class="w-full border-t border-gray-200"></div>
  </div>
  <div class="relative flex justify-start">
    <span (click)="isActivePendingOption = !isActivePendingOption" class="cursor-pointer border border-gray-300 px-4 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">Очікують</span>
  </div>
</div>
<div *ngIf="isActivePendingOption">
  <ul role="list" class="space-y-2">
    <app-set-list [renovationId]="1" [listType]="RenovationStatusPending" [loadedSets]="setListService.pendingSets"></app-set-list>
    <app-chunk-list [renovationId]="1" [listType]="RenovationStatusPending" [loadedChunks]="chunkListService.pendingChunks"></app-chunk-list>
    <app-map-element-list [renovationId]="1" [listType]="RenovationStatusPending" [loadedMapElements]="mapElementListService.pendingMapElements"></app-map-element-list>
    <app-skin-list [renovationId]="1" [listType]="RenovationStatusPending" [loadedSkins]="skinListService.pendingSkins"></app-skin-list>
  </ul>
  <div [formGroup]="saveVersionedSkinRenovationForm" (submit)="saveSkinRenovation()" class="bg-gray-50 flex flex-row gap-4 overflow-hidden mt-10 px-4 py-4 border border-gray-200 rounded-lg з-">
    <div class="block w-40">
      <input
        formControlName="appVersion"
        type="text"
        placeholder="Вкажіть версію"
        class="input-text block w-full text-sm border-none {{getInputStyles(this.saveVersionedSkinRenovationForm.controls.appVersion)}} focus:ring focus:ring-opacity-50">
    </div>
    <button class="py-2 px-4 bg-{{saveVersionedSkinRenovationForm.valid && this.renovationListService.isCreatedAtLeastOneElement() ? 'green' : 'gray'}}-300 {{saveVersionedSkinRenovationForm.valid && this.renovationListService.isCreatedAtLeastOneElement() ? 'hover:bg-green-500' : ''}} text-sm text-white font-semibold rounded-lg" (click)="saveSkinRenovation()">Зберегти</button>
  </div>
</div>



<div class="relative my-10">
  <div style="left: 82px;" class="absolute inset-0 flex items-center" aria-hidden="true">
    <div class="w-full border-t border-gray-200"></div>
  </div>
  <div class="relative flex justify-start">
    <span (click)="isActiveSavedOption = !isActiveSavedOption" class="cursor-pointer border border-gray-300 px-4 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">Збережені</span>
  </div>
</div>
<div *ngIf="isActiveSavedOption">
  <dl *ngFor="let renovationList of renovationListService.savedRenovationLists; let i = index" class="space-y-6 divide-y divide-gray-100 border-gray-200 text-sm leading-6 mb-2.5">
    <div class="sm:flex border-gray-200 border rounded-lg {{renovationListService.hiddenSavedRenovationLists.includes(renovationList.appVersion) ? 'bg-gray-50' : ''}}  ">
      <div (click)="renovationListService.addHiddenSavedRenovationList(renovationList.appVersion)" class="flex font-medium text-gray-900 sm:w-32 sm:flex-none sm:px-4 items-center justify-center {{renovationListService.hiddenSavedRenovationLists.includes(renovationList.appVersion) ? 'cursor-pointer hover:bg-gray-200' : 'cursor-pointer hover:bg-gray-200'}}">
        <p class="text-center text-xl font-semibold text-gray-400 py-1.5">{{renovationList.appVersion}}</p>
      </div>
      <div *ngIf="renovationListService.hiddenSavedRenovationLists.includes(renovationList.appVersion)" [formGroup]="confirmVersionedSkinRenovationForm" (submit)="confirmSkinRenovation(renovationList.id)" class="bg-gray-50 flex flex-row gap-4 overflow-hidden px-4 py-4 rounded-lg з-">
        <div class="block w-80 ">
          <input
            formControlName="gitBranch"
            type="text"
            placeholder="Вкажіть git гілку"
            class="input-text block w-full text-sm border-none {{getInputStyles(this.confirmVersionedSkinRenovationForm.controls.gitBranch)}} focus:ring focus:ring-opacity-50">
        </div>
        <button class="py-2 px-4 bg-{{confirmVersionedSkinRenovationForm.valid ? 'green' : 'gray'}}-300 {{confirmVersionedSkinRenovationForm.valid ? 'hover:bg-green-500' : ''}} text-sm text-white font-semibold rounded-lg" (click)="confirmSkinRenovation(renovationList.id)">Підтвердити</button>
      </div>
<!--      <button *ngIf="renovationListService.hiddenSavedRenovationLists.includes(renovationList.appVersion)" type="button" (click)="confirmSkinRenovation(renovationList.id)" class="mr-4 h-8 mt-1 ml-3 w-42 inline-flex items-center px-3.5 py-1 border border-blue-200 hover:border-blue-400 text-sm leading-4 rounded-md bg-white text-blue-500 hover:text-blue-700 focus:outline-none">Підтвердити</button>-->
      <dd *ngIf="!renovationListService.hiddenSavedRenovationLists.includes(renovationList.appVersion)" class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto ml-4">
        <ul role="list" class="w-full space-y-2 mr-4">
          <app-set-list [renovationId]="renovationList.id" [listType]="RenovationStatusSaved" [loadedSets]="renovationList.sets"></app-set-list>
          <app-chunk-list [renovationId]="renovationList.id" [listType]="RenovationStatusSaved" [loadedChunks]="renovationList.chunks"></app-chunk-list>
          <app-map-element-list [renovationId]="renovationList.id" [listType]="RenovationStatusSaved" [loadedMapElements]="renovationList.mapElements"></app-map-element-list>
          <app-skin-list [renovationId]="renovationList.id" [listType]="RenovationStatusSaved" [loadedSkins]="renovationList.skins"></app-skin-list>
        </ul>
      </dd>
    </div>
  </dl>
</div>



<div class="relative my-10">
  <div style="left: 82px;" class="absolute inset-0 flex items-center" aria-hidden="true">
    <div class="w-full border-t border-gray-200"></div>
  </div>
  <div class="relative flex justify-start">
    <span (click)="isActiveConfirmedOption = !isActiveConfirmedOption" class="cursor-pointer border border-gray-300 px-4 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">Підтвердженні</span>
  </div>
</div>
<div *ngIf="isActiveConfirmedOption">
  <dl *ngFor="let renovationList of renovationListService.confirmedRenovationLists; let i = index" class="space-y-6 divide-y divide-gray-100 border-gray-200 text-sm leading-6 mb-2.5">
    <div class="sm:flex border-gray-200 border rounded-lg {{renovationListService.hiddenConfirmedRenovationLists.includes(renovationList.appVersion) ? 'bg-gray-50' : ''}}  ">
      <div (click)="renovationListService.addHiddenConfirmedRenovationList(renovationList.appVersion)" class="flex px-6 font-medium text-gray-900 sm:flex-none items-center justify-center {{renovationListService.hiddenSavedRenovationLists.includes(renovationList.appVersion) ? 'cursor-pointer hover:bg-gray-200' : 'cursor-pointer hover:bg-gray-200'}}">
        <p *ngIf="renovationList.appVersion !== ''" class="text-center text-xl font-semibold text-gray-400 py-1.5">
          {{renovationList.appVersion}}
        </p>
        <span *ngIf="renovationList.appVersion !== '' && renovationListService.hiddenConfirmedRenovationLists.includes(renovationList.appVersion)" class="text-sm w-full pl-4 text-gray-400">{{renovationList.gitBranch}}</span>
        <svg  *ngIf="renovationList.appVersion === ''" class="w-8 h-8 text-center text-xl font-semibold text-gray-400 my-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" />
        </svg>
      </div>
<!--      <span class="text-gray-500 text-sm py-6 align-middle">{{renovationList.gitBranch }}</span>-->
<!--      <button *ngIf="renovationListService.hiddenConfirmedRenovationLists.includes(renovationList.appVersion)" type="button" (click)="renovationListService.getExportedSkinList(renovationList)" class="mr-4 h-8 mt-1 ml-4 w-42 inline-flex items-center px-3.5 py-1 border border-blue-200 hover:border-blue-400 text-sm leading-4 rounded-md bg-white text-blue-500 hover:text-blue-700 focus:outline-none">Отримати скіни</button>-->
      <dd *ngIf="!renovationListService.hiddenConfirmedRenovationLists.includes(renovationList.appVersion)" class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto ml-4">
        <ul role="list" class="w-full space-y-2 mr-4">
          <app-set-list [renovationId]="renovationList.id" [listType]="RenovationStatusConfirmed" [loadedSets]="renovationList.sets"></app-set-list>
          <app-chunk-list [renovationId]="renovationList.id" [listType]="RenovationStatusConfirmed" [loadedChunks]="renovationList.chunks"></app-chunk-list>
          <app-map-element-list [renovationId]="renovationList.id" [listType]="RenovationStatusConfirmed" [loadedMapElements]="renovationList.mapElements" [renovationOperation]="renovationList.operation"></app-map-element-list>
          <app-skin-list [renovationId]="renovationList.id" [listType]="RenovationStatusConfirmed" [loadedSkins]="renovationList.skins" [renovationOperation]="renovationList.operation"></app-skin-list>
        </ul>
      </dd>
    </div>
  </dl>
</div>
