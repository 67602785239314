import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthorizationService} from "../../security/authorization.service";
import {NotificationsService} from "angular2-notifications";
import {PermissionService} from "../../security/permission.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class UserLoginService {

  constructor(
    private http: HttpClient,
    private authorizationService: AuthorizationService,
    private permissionService: PermissionService,
    private notificationsService: NotificationsService,
    private router: Router,
  ) {}

  login(username: string, password: string): void {
    this.http
      .post('/api/v1/security/login',
        {username: username, password: password},
        {
          headers: new HttpHeaders(
            {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Headers': 'Content-Type',
              'Access-Control-Allow-Credentials': 'true',
              'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
            })
        })
      .subscribe(
        (data: any) => {
          this.authorizationService.setId(data.id);
          this.authorizationService.setToken(data.token);
          this.permissionService.setUserPermissions(data.permissions);
          this.authorizationService.isStillAuthenticated();

          this.notificationsService.info('Авторизація', 'Успішний вхід', {
            timeOut: 1500,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
          });

          setTimeout(() => {
            this.router.navigateByUrl('/save-list');
          }, 1500);
        },
        error => {
          this.notificationsService.error('Помилка!', error.error.detail, {
            timeOut: 1500,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
          });
        }
      );
  }
}
