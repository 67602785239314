import { Injectable } from '@angular/core';
import {Observable, Subject} from "rxjs";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {Deploy} from "./deploy";

@Injectable({
  providedIn: 'root'
})
export class DeployListModalService {

  private _deploys: Deploy[] = [];

  constructor(private http: HttpClient) { }

  createDeploy(redeems: any): Observable<HttpResponse<any>> {
    return this.http.post<any>('/api/v1/ui/deploy-create', redeems, {
      observe: 'response',
      withCredentials: true
    });
  }

  appendDeployRedeems(id: number, redeems: any): Observable<HttpResponse<any>> {
    return this.http.patch<any>('/api/v1/ui/deploy/' + id + '/append-redeems', redeems, {
      observe: 'response',
      withCredentials: true
    });
  }

  getDeployListModal(): Observable<HttpResponse<Deploy[]>> {
    return this.http.get<Deploy[]>('/api/v1/ui/deploy-list-modal', {
      observe: 'response',
      withCredentials: true
    });
  }

  setDeploys(deploys: Deploy[]) {
    this._deploys = deploys;
  }

  get deploys(): Deploy[] {
    return this._deploys;
  }
}
