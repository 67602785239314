import { environment } from './environments/environment';

type TextTranslationsType = {
  [text: string]: {
    [language: string]: string
  }
}

export function trans(text: string): string {
  return textTranslations[text] !== undefined && textTranslations[text][environment.language] !== undefined
    ? textTranslations[text][environment.language]
    : text;
}

const textTranslations: TextTranslationsType = { // TODO Видалити все це
  'Select operation': {
    EN: 'Select operation',
    RU: 'Выбрать операцию',
    UA: 'Вибрати операцію'
  },
  'Select item': {
    EN: 'Select item',
    RU: 'Выбрать предмет',
    UA: 'Вибрати предмет'
  },
  'Change count': {
    EN: 'Change count',
    RU: 'Изменить количество',
    UA: 'Змінити кількість'
  },
  'Set item': {
    EN: 'Set item',
    RU: 'Установить значение',
    UA: 'Встановити значення'
  },
  'Item count': {
    EN: 'Item count',
    RU: 'Количество',
    UA: 'Кількість предметів'
  },
  'Select chunk': {
    EN: 'Select chunk',
    RU: 'Выберите чанк',
    UA: 'Виберіть чанк'
  },
  'Select map element': {
    EN: 'Select map element',
    RU: 'Выберите элемент карты',
    UA: 'Виберіть елемент мапи'
  },
  'Select set': {
    EN: 'Select set',
    RU: 'Выберите сет',
    UA: 'Виберіть сет'
  },
  'Select projection': {
    EN: 'Select projection',
    RU: 'Выберите проекцию',
    UA: 'Виберіть проекцію'
  },
  'Select rare level': {
    EN: 'Select rare level',
    RU: 'Выберите редкостный уровень',
    UA: 'Виберіть рідкісний рівень'
  },
  'Select currency': {
    EN: 'Select currency',
    RU: 'Выберите валюту',
    UA: 'Виберіть валюту'
  },
  'Select skin type': {
    EN: 'Select skin type',
    RU: 'Выберите тип скина',
    UA: 'Виберіть тип скіну'
  },
  'pending': {
    EN: 'pending',
    RU: 'ожидает',
    UA: 'очікує'
  },
  'saved': {
    EN: 'saved',
    RU: 'сохраненный',
    UA: 'збережений'
  },
  'confirmed': {
    EN: 'confirmed',
    RU: 'подтвержденный',
    UA: 'підтверджений'
  }
}
