import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from "@angular/common/http";
import {Observable, Subject} from "rxjs";
import {User} from "../user";

@Injectable({
  providedIn: 'root'
})
export class UserListService {

  private _list$ = new Subject<User[]>();

  constructor(private http: HttpClient) { }

  getList(): Observable<HttpResponse<User[]>> {
    return this.http.get<User[]>('/api/v1/ui/user-list', {
      observe: 'response',
      // params: this.resolveListHttpParams(),
      withCredentials: true
    });
  }

  setList(list: User[]) {
    this._list$.next(list);
  }

  get list$(): Subject<User[]> {
    return this._list$;
  }
}
