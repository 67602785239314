import { Injectable } from '@angular/core';
import {Observable, Subject} from "rxjs";
import {Player} from "../../player";
import {HttpClient, HttpResponse} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ProfileCommonService {
  private _player$ = new Subject<Player>();

  constructor(private http: HttpClient) { }

  getPlayer(id:string|number): Observable<HttpResponse<Player>> {
    return this.http.get<Player>('/api/v1/players/' + id + '/profile', {
      observe: 'response',
      withCredentials: true
    });
  }

  setPlayer(player: Player) {
    this._player$.next(player);
  }

  get player$(): Subject<Player> {
    return this._player$;
  }
}
