import {Component, OnInit} from '@angular/core';
import {Field, FieldMap, QueryBuilderClassNames, QueryBuilderConfig, Rule, RuleSet} from "angular2-query-builder";
import {FormBuilder, FormControl} from "@angular/forms";
import {Observable} from "rxjs";
import {QueryBuilderService} from "./query-builder.service";
import {SaveListService} from "../save-list/save-list.service";
import {NotificationsService} from "angular2-notifications";
import {RuleValidatorResult} from "../deploy/deploy";

@Component({
  selector: 'app-query-builder',
  template: `
    <div *ngIf="list$ | async as player">
      <query-builder class="p-4 text-sm text-gray-500"
                     [formControl]="queryCtrl"
                     [(ngModel)]='query'
                     [config]='currentConfig'
                     [classNames]='classNames'
                     [allowRuleset]='allowRuleset'
                     [allowCollapse]='allowCollapse'
                     [persistValueOnFieldChange]='persistValueOnFieldChange'>
      <ng-container
        *queryButtonGroup="let ruleset; let addRule=addRule; let addRuleSet=addRuleSet; let removeRuleSet=removeRuleSet">
          <div class="flex flex-wrap content-center">
              <button type="button" (click)="addRule()"
                      class="flex flex-wrap content-center pl-1.5 pr-2.5 py-2 bg-white border border-blue-200 rounded-md text-blue-500 hover:border-blue-400 hover:text-blue-700">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                       stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
                  </svg>
                  <span class="pl-1">
                      Умову
                  </span>
              </button>
              <button type="button" *ngIf="addRuleSet" (click)="addRuleSet()"
                      class="ml-1.5 flex flex-wrap content-center pl-1.5 pr-2.5 py-2 bg-white border border-blue-200 rounded-md text-blue-500 hover:border-blue-400 hover:text-blue-700">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                       stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
                  </svg>
                  <span class="pl-1">
                      Групу
                  </span>
              </button>
              <button *ngIf="removeRuleSet" (click)="removeRuleSet()"
                      class="ml-2 p-2 bg-white border border-red-200 rounded-md text-red-400 hover:border-red-400 hover:ring-4 hover:ring-red-400 hover:ring-opacity-50"
                      type="button">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clip-rule="evenodd"/>
                  </svg>
              </button>
          </div>
      </ng-container>
      <ng-container *queryArrowIcon>
          <div class="">
              <icon ngClass="mat-arrow-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd"
                            d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
                            clip-rule="evenodd"/>
                  </svg>
              </icon>
          </div>
      </ng-container>
      <ng-container *queryRemoveButton="let rule; let removeRule=removeRule">
          <button (click)="removeRule(rule)"
                  class="p-2 bg-white border border-red-200 rounded-md text-red-400 float-right hover:border-red-400 hover:ring-4 hover:ring-red-400 hover:ring-opacity-50"
                  type="button">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"/>
              </svg>
          </button>
      </ng-container>
      <ng-container *querySwitchGroup="let ruleset; let onChange=onChange">
          <fieldset *ngIf="ruleset" ngDefaultControl [(ngModel)]="ruleset.condition" (ngModelChange)="onChange($event)"> <!-- TODO возможно не будет работать из-за инпутов которые вложены а не на уровне это дива fieldset      -->
              <div class="flex items-center">
                  <label
                    class="{{ruleset.condition === 'and' ? 'border-green-300 bg-green-50 text-green-700' : 'bg-white border-gray-200'}} select-none w-12 relative p-2 flex items-center justify-center cursor-pointer border rounded-l-md">
                      <input type="radio" name="and" class="sr-only" value="and" (click)="onChange('and')">AND
                  </label>
                  <label
                    class="{{ruleset.condition === 'or' ? 'border-green-300 bg-green-50 text-green-700' : 'bg-white border-gray-200'}} select-none w-12 relative p-2 flex items-center justify-center cursor-pointer rounded-r-md border-t border-b border-r">
                      <input type="radio" name="or" class="sr-only" value="or" (click)="onChange('or')">OR
                  </label>
              </div>
          </fieldset>
      </ng-container>
      <ng-container *queryEntity="let rule; let entities=entities; let onChange=onChange">
          <div class="float-left">
              <select
                class="block w-full pl-3 py-2 text-sm border-gray-200 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md"
                [(ngModel)]="rule.entity" (ngModelChange)="onChange($event, rule)">
                  <option *ngFor="let entity of entities" [value]="entity.value">
                      {{entity.name}}
                  </option>
              </select>
          </div>
      </ng-container>
      <ng-container *queryField="let rule; let fields=fields; let onChange=onChange; let getFields = getFields">
          <div class="float-left">
              <select
                class="block w-full pl-3 py-2 text-sm border-gray-200 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md"
                [(ngModel)]="rule.field" (ngModelChange)="onChange($event, rule)">
                  <option *ngFor="let field of getFields(rule.entity)" [value]="field.value">
                      {{ field.name }}
                  </option>
              </select>
          </div>
      </ng-container>
      <ng-container *queryOperator="let rule; let operators=operators; let onChange=onChange">
          <div class="float-left ml-4" [style.width.px]="90">
              <select
                class="{{getRuleValidatorResult(rule)?.isValidOperator === true || queryBuilderService.isNotQueryContainsErrors ? 'border-gray-200 focus:border-blue-300 focus:ring-blue-200' : 'bg-red-50 border-red-200 focus:border-red-300 focus:ring-red-200'}}  block w-full pl-3 py-2 text-sm focus:ring focus:ring-opacity-50 rounded-md"
                [(ngModel)]="rule.operator" (ngModelChange)="onChange(rule)">
                  <option *ngFor="let value of operators" [value]="value">
                      {{ value }}
                  </option>
              </select>
          </div>
      </ng-container>
      <ng-container *queryInput="let rule; type: 'boolean'; let onChange=onChange">
          <div class="float-left ml-4 mt-2">
              <input type="checkbox"
                     class="block w-full p-2 text-sm border-gray-200 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md"
                     [(ngModel)]="rule.value" (ngModelChange)="onChange()">
          </div>
      </ng-container>
      <ng-container
        *queryInput="let rule; let field=field; let options=options; type: 'category'; let onChange=onChange">
          <div class="float-left ml-4">
              <select
                class="block w-full pl-3 py-2 text-sm border-gray-200 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md"
                [(ngModel)]="rule.value" (ngModelChange)="onChange()">
                  <option *ngFor="let opt of options" [value]="opt.value">
                      {{ opt.name }}
                  </option>
              </select>
          </div>
      </ng-container>
      <ng-container *queryInput="let rule; type: 'date'; let onChange=onChange">
          <div class="float-left ml-4">
              <input type="date"
                     class="block w-full pl-3 py-2 text-sm border-gray-200 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md"
                     [(ngModel)]="rule.value" (ngModelChange)="onChange()">
          </div>
      </ng-container>
      <ng-container *queryInput="let rule; let options=options; type: 'multiselect'; let onChange=onChange">
          <div class="float-left ml-4">
              <select id="location"
                      name="location"
                      class="h-10 block w-full pl-3 pr-10 py-2 text-sm border-gray-200 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 rounded-md"
                      multiple
                      [(ngModel)]="rule.value" (ngModelChange)="onChange()"
              >
                  <option *ngFor="let opt of options" [value]="opt.value">
                      {{ opt.name }}
                  </option>
              </select>
          </div>
      </ng-container>
      <ng-container *queryInput="let rule; let field=field; type: 'number'; let onChange=onChange">
          <div class="float-left ml-4" [style.width.px]="50">
              <input
                class="{{getRuleValidatorResult(rule)?.isValidValue === true || queryBuilderService.isNotQueryContainsErrors ? 'border-gray-200 focus:text-gray-900 focus:border-blue-300 focus:ring-blue-200' : 'bg-red-50 border-red-200 focus:text-red-900 focus:border-red-300 focus:ring-red-200' }} block border rounded-md py-2 pl-4 text-sm focus:ring focus:ring-opacity-50"
                [(ngModel)]="rule.value" type="number" (ngModelChange)="onChange()">
          </div>
      </ng-container>
      <ng-container *queryInput="let rule; let field=field; type: 'string'; let onChange=onChange">
          <div class="float-left ml-4">
              <input
                class="block border border-gray-200 rounded-md py-2 pl-4 text-sm focus:text-gray-900 focus:outline-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                [(ngModel)]="rule.value" (ngModelChange)="onChange()">
          </div>
      </ng-container>
      <ng-container *queryInput="let rule; let field=field; type: 'textarea'; let onChange=onChange">
          <div class="float-left ml-4">
              <textarea style="height: 38px"
                        class="block max-w-3xl resize-x border border-gray-200 rounded-md py-2 pl-4 text-sm focus:text-gray-900 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                        [(ngModel)]="rule.value" (ngModelChange)="onChange()"></textarea>
          </div>
      </ng-container>
      </query-builder>
      <div class="px-4 pb-4">
          <div class="flex flex-row justify-center">
              <div (click)="pasteQueryFromClipboard()"
                   class="mr-4 p-2 text-gray-500 cursor-pointer hover:text-blue-500">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                       stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"/>
                  </svg>
              </div>
              <button (click)="search()"
                      class="w-24 py-2 px-4 bg-blue-300 text-sm text-white font-semibold rounded-lg hover:bg-blue-500">Пошук</button>
              <!--              <div class="ml-4 p-2 text-gray-500 hover:text-green-500">-->
              <!--                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"-->
              <!--                       stroke="currentColor">-->
              <!--                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"-->
              <!--                            d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"/>-->
              <!--                  </svg>-->
              <!--              </div>-->
          </div>
      </div>
    </div>
  `,
  styles: [`
  //.mat-icon-button {
  //  outline: none;
  //}
  `]
})
export class QueryBuilderComponent implements OnInit {

  query: RuleSet = {
    condition: 'and',
    rules: [
      {field: 'coins', operator: '>', value: 123},
    ]
  };

  isNotHighlightQueryErrors = true

  isInt32(value: string): boolean {
    const num = Number(value);
    return Number.isInteger(num) && num >= -2147483648 && num <= 2147483647
  }

  validateRulesInQuery(ruleSet: RuleSet) {
    ruleSet.rules.forEach((rule) => {
      if ("field" in rule) {
        for (const field of this.queryFields) {
          if (field.name === rule.field) {

            if (field.operators === undefined) {
              console.error("Field operators list - undefined. List: ", this.queryFields)
              return
            }

            if (rule.operator === undefined) {
              console.error("Rule operator is undefined or null. Rule operator / Rule: ", rule.operator, rule)
              return
            }

            const ruleValidator: RuleValidatorResult = {
              isValidOperator: field.operators.indexOf(rule.operator) !== -1,
              isValidValue: this.isValidRuleValueByType(rule.value, field.type),
            };

            this.queryBuilderService.queryBuilderRules.set(rule, ruleValidator)
          }
        }
      } else {
        this.validateRulesInQuery(rule);
      }
    });
  }

  isValidRuleValueByType(value: any, type: string): boolean {
    if (value === null) {
      return false;
    }
    // switch (type) {
    //   case 'number':
        return this.isInt32(value)
    // }
  }

  getRuleValidatorResult(rule: Rule): RuleValidatorResult | null {
    const ruleValidatorResult = this.queryBuilderService.queryBuilderRules.get(rule)

    if (ruleValidatorResult === undefined) {
      // console.error("Rule operator was not found in the rules list. Rule: ", rule)
      return null
    }

    return ruleValidatorResult
  }

  // query = {
  //   condition: 'and',
  //   rules: [
  //     {field: 'age', operator: '<=', entity: 'physical'},
  //     {field: 'birthday', operator: '=', value: new Date(), entity: 'nonphysical'},
  //     {
  //       condition: 'or',
  //       rules: [
  //         {field: 'gender', operator: '=', entity: 'physical'},
  //         {field: 'occupation', operator: 'in', entity: 'nonphysical'},
  //         {field: 'school', operator: 'is null', entity: 'nonphysical'},
  //         {field: 'notes', operator: '=', entity: 'nonphysical'}
  //       ]
  //     }
  //   ]
  // };

  // entityConfig: QueryBuilderConfig = {
  //   entities: {
  //     physical: {name: 'Physical Attributes'},
  //     nonphysical: {name: 'Nonphysical Attributes'}
  //   },
  //   fields: {
  //     age: {name: 'Age', type: 'number', entity: 'physical'},
  //     gender: {
  //       name: 'Gender',
  //       entity: 'physical',
  //       type: 'category',
  //       options: [
  //         {name: 'Male', value: 'm'},
  //         {name: 'Female', value: 'f'}
  //       ]
  //     },
  //     name: {name: 'Name', type: 'string', entity: 'nonphysical'},
  //     notes: {name: 'Notes', type: 'textarea', operators: ['=', '!='], entity: 'nonphysical'},
  //     educated: {name: 'College Degree?', type: 'boolean', entity: 'nonphysical'},
  //     birthday: {name: 'Birthday', type: 'date', operators: ['=', '<=', '>'],
  //       defaultValue: (() => new Date()), entity: 'nonphysical'
  //     },
  //     school: {name: 'School', type: 'string', nullable: true, entity: 'nonphysical'},
  //     occupation: {
  //       name: 'Occupation',
  //       entity: 'nonphysical',
  //       type: 'category',
  //       options: [
  //         {name: 'Student', value: 'student'},
  //         {name: 'Teacher', value: 'teacher'},
  //         {name: 'Unemployed', value: 'unemployed'},
  //         {name: 'Scientist', value: 'scientist'}
  //       ]
  //     }
  //   }
  // };

  config: QueryBuilderConfig = {
    fields: {}
  }

  // config: QueryBuilderConfig = {
  //   fields: {
  //     age: {name: 'Age', type: 'number'},
  //     gender: {name: 'Gender', type: 'category', options: [
  //         {name: 'Male', value: 'm'},
  //         {name: 'Female', value: 'f'}
  //       ]
  //     },
  //     name: {name: 'Name', type: 'string'},
  //     notes: {name: 'Notes', type: 'textarea', operators: ['=', '!=']},
  //     educated: {name: 'College Degree?', type: 'boolean'},
  //     birthday: {name: 'Birthday', type: 'date', operators: ['=', '<=', '>'], defaultValue: (() => new Date())},
  //     school: {name: 'School', type: 'string', nullable: true},
  //     occupation: {name: 'Occupation', type: 'category', options: [
  //         {name: 'Student', value: 'student'},
  //         {name: 'Teacher', value: 'teacher'},
  //         {name: 'Unemployed', value: 'unemployed'},
  //         {name: 'Scientist', value: 'scientist'}
  //       ]
  //     }
  //   }
  // }

  classNames: QueryBuilderClassNames = {
    // removeIcon: 'fa fa-minus',
    // addIcon: 'fa fa-plus',
    // arrowIcon: 'px-2',
    // button: 'bg-transparent hover:bg-blue-500 text-blue-700 hover:text-white py-1 px-4 ml-1 border border-blue-500 hover:border-transparent rounded',
    // buttonGroup: 'btn-group',
    // rightAlign: 'order-12 ml-auto',
    // switchRow: 'pl-4',
    // switchGroup: 'd-flex align-items-center text-sm rounded-md border-red-500',
    // switchRadio: 'bg-blue-200',
    // switchLabel: 'bg-green-200',
    // switchControl: 'bg-red-200',
    row: 'p-5 ml-14 mt-4',
    rule: 'border border-gray-200 rounded-md h-20',
    ruleSet: 'border border-blue-200 rounded-md',
    invalidRuleSet: 'bg-red-50 invalid-rule-set',
    // emptyWarning: 'text-danger mx-auto',
    // operatorControl: 'block text-sm rounded-md border-gray-200 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50',
    // operatorControlSize: 'col-auto pr-0',
    // fieldControl: 'block float-left w-full text-sm rounded-md border-gray-200 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50',
    // fieldControlSize: 'col-auto pr-0',
    // entityControl: 'block w-full text-sm rounded-md border-gray-200 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50',
    // entityControlSize: 'col-auto pr-0',
    // inputControl: 'block w-full text-sm rounded-md border-gray-200 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50',
    // inputControlSize: 'col-auto'
  }

  list$: Observable<Field[]>;

  public currentConfig: QueryBuilderConfig;
  public queryCtrl: FormControl;
  public persistValueOnFieldChange: boolean = true;
  public allowRuleset: boolean = true;
  public allowCollapse: boolean = false;

  private queryFields: Field[] = []

  // switchModes(event: Event) {
  //   console.log(event);
  //   this.currentConfig = (<HTMLInputElement>event.target).checked ? this.entityConfig : this.config;
  // }
  //
  // changeDisabled(event: Event) {
  //   console.log(event);
  //   (<HTMLInputElement>event.target).checked ? this.queryCtrl.disable() : this.queryCtrl.enable();
  // }

  constructor(
    private formBuilder: FormBuilder,
    public queryBuilderService: QueryBuilderService,
    private saveListService: SaveListService,
    private notificationsService: NotificationsService
  ) {
    this.currentConfig = this.config;
    this.list$ = this.queryBuilderService.list$;
    this.queryCtrl = this.formBuilder.control(this.query);
    this.queryCtrl.valueChanges.subscribe((value) => {
      this.queryBuilderService.setQueryBuilderQuery(value);
      this.validateRulesInQuery(this.queryBuilderService.queryBuilderQuery)
    });
  }

  ngOnInit(): void {
    this.queryBuilderService.getList().subscribe(
      (data) => {
        if (data.body !== null) {
          this.queryBuilderService.setList(data.body);
          this.queryFields = data.body;

          const arrayToObject = (array: any) =>
            array.reduce((obj: any, item: any) => {
              obj[item.name] = item
              return obj
            }, {})

          this.currentConfig.fields = arrayToObject(this.queryFields) as FieldMap;
          // console.log(this.currentConfig)
        }
      }
    );
  }

  search(): void {
    if (!this.queryBuilderService.isValidQuery()) {
      this.notificationsService.error('Помилка!', 'Запит має не валідні значення', {
        timeOut: 1500,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
      });
      return
    }

    this.saveListService.resetSelectedPageAndPagePlayers();
    this.saveListService.resetPagination();
    this.saveListService.getList({
      'playerIds': [],
      'query': {...this.queryCtrl.value},
    }).subscribe(
      (data) => {
        if (data.body !== null) {
          // this.queryBuilderService.setQueryBuilderQuery({...this.queryCtrl.value});
          this.saveListService.setList(data.body);
          this.saveListService.saveListTotalCount = Number(data.headers.get("x-total-count"));

          const rangeHeader = data.headers.get('Content-Range');
          if (rangeHeader !== null) {
            this.saveListService.parseRangeHeader(rangeHeader)
          }
          // this.saveListService.setAllPlayersNotSelected();
          // this.saveListService.updatePlayerIdList(saves)
        } else {
          this.saveListService.setList([]);
          this.saveListService.saveListTotalCount = 0;
          this.saveListService.updatePlayerIdList([])
        }
      }
    );
  }

  async pasteQueryFromClipboard() {
    const stringQuery = await navigator.clipboard.readText();
    let parsedQuery;
    try {
      parsedQuery = JSON.parse(stringQuery) as RuleSet;
    } catch (e) {
      this.notificationsService.error('Помилка!', 'Запит має бути валідним json', {
        timeOut: 1500,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
      });
      return;
    }

    if (!this.isRuleSet(parsedQuery)) {
      this.notificationsService.error('Помилка!', 'Запит має не валідну структуру', {
        timeOut: 1500,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
      });
      return;
    }

    this.query = parsedQuery;
    this.queryCtrl = this.formBuilder.control(this.query);

    this.notificationsService.info('Запит застосовано!', '', {
      timeOut: 1500,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true
    });
  }

  private isRuleSet(object: unknown): object is RuleSet {
    return Object.prototype.hasOwnProperty.call(object, "condition")
      && Object.prototype.hasOwnProperty.call(object, "rules");
  }
}
