import { Component, OnInit } from '@angular/core';
import {AppService} from "../../app.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {

  constructor(
    private appService: AppService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    const type = this.route.snapshot.data['type'];
    this.appService.setPageType$(type)
  }
}
