import {GameSession, Platform, State} from "./game-session";
import {SectionsBoolValues} from "./sections-bool-values";
import {SectionsStringValues} from "./sections-string-values";
import {SectionsBinaryValues} from "./sections-binary-values";
import {SectionsDatetimeValues} from "./sections-datetime-values";
import {SectionsFloatValues} from "./sections-float-values";
import {SectionsInt64Values} from "./sections-int64-values";
import {SectionsIntValues} from "./sections-int-values";

export interface Player { // TODO думаю тоже стоит разделить на используемы сущности PLayerList / Player
  items: any;
  id: string|number;
  status: PlayerStatus;
  username: string;
  email: string;
  firstSeen: string;
  lastSeen: string;
  usageDays: string;
  usageTime: string;
  sex: string|null;
  age: number|null;
  devices: GameSession[]
  apps: GameSession[]     // TODO grouped by app_name
  gameSessions: GameSession[],
  sectionsBinaryValues: SectionsBinaryValues[]
  sectionsBoolValues: SectionsBoolValues[]
  sectionsDatetimeValues: SectionsDatetimeValues[]
  sectionsFloatValues: SectionsFloatValues[]
  sectionsInt64Values: SectionsInt64Values[]
  sectionsIntValues: SectionsIntValues[]
  sectionsStringValues: SectionsStringValues[]
  logsPlayerStatusChanges: LogPlayerStatusChange[]
}

export interface PlayerList {
  id: number;
  username: string;
  email: string;
  startTime: string;
  deviceId: string;
  deviceName: string;
  platform: string;
  countryId: number
  countryCode: string
}

export interface LogPlayerStatusChange {
  userId: number;
  username: string;
  status: PlayerStatus|null;
  reason: string;
  createdAt: Date;
}

export enum PlayerStatus {
  active = 'active',
  erased = 'erased',
  blocked = 'blocked'
}

export const SYSTEM_PLAYER = 1408;
