import { Injectable } from '@angular/core';
import {Observable, Subject} from "rxjs";
import {Player} from "../../player";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {SectionsKeyNamesList, SectionType} from "../../section-type";

@Injectable({
  providedIn: 'root'
})
export class ProfileVictorianQuestService {
  private _player$ = new Subject<Player>();
  private _sectionsKeyNamesList$ = new Subject<SectionsKeyNamesList>();

  constructor(private http: HttpClient) { }

  getPlayer(id: string|number): Observable<HttpResponse<Player>> {
    return this.http.get<Player>('/api/v1/players/' + id + '/profile', {
      observe: 'response',
      withCredentials: true
    });
  }

  changePlayerStatus(playerId: number, status: string, reason: string): Observable<HttpResponse<Player>> {
    return this.http.post<Player>('/api/v1/change-player-status', {
      'playerId': playerId,
      'status': status,
      'reason': reason
    },{
      observe: 'response',
      withCredentials: true
    });
  }

  addNoteToPlayer(playerId: number, note: string): Observable<HttpResponse<Player>> {
    return this.http.post<Player>('/api/v1/add-note-to-player', {
      'playerId': playerId,
      'note': note
    },{
      observe: 'response',
      withCredentials: true
    });
  }

  getSectionsKeyNamesList(name: string|null = null): Observable<HttpResponse<SectionsKeyNamesList>> {
    return this.http.get<SectionsKeyNamesList>(name
        ? '/api/v1/section-key-names-list/' + name
        : '/api/v1/section-key-names-list', {
      observe: 'response',
      withCredentials: true
    });
  }

  setPlayer(player: Player) {
    this._player$.next(player);
  }

  get player$(): Subject<Player> {
    return this._player$;
  }

  setSectionsKeyNamesList(value: SectionsKeyNamesList) {
    this._sectionsKeyNamesList$.next(value);
  }

  get sectionsKeyNamesList$(): Subject<SectionsKeyNamesList> {
    return this._sectionsKeyNamesList$;
  }

  updateSectionsValues(id: string | number, sectionsValues: any[]): Observable<HttpResponse<any>> {
    return this.http.patch<any>('/api/v1/players/' + id + '/update-sections-values', sectionsValues, {
      observe: 'response',
      withCredentials: true
    });
  }

  getSectionKeyName(id: number, type: SectionType, list: SectionsKeyNamesList): string {
    switch (type) {
      case SectionType.BINARY: return String(list.sectionsBinaryNames.find(item => item.id === id)?.name)
      case SectionType.BOOL: return String(list.sectionsBoolNames.find(item => item.id === id)?.name)
      case SectionType.DATETIME: return String(list.sectionsDatetimeNames.find(item => item.id === id)?.name)
      case SectionType.STRING: return String(list.sectionsStringNames.find(item => item.id === id)?.name)
      case SectionType.INT: return String(list.sectionsIntNames.find(item => item.id === id)?.name)
      case SectionType.INT64: return String(list.sectionsInt64Names.find(item => item.id === id)?.name)
      case SectionType.FLOAT: return String(list.sectionsFloatNames.find(item => item.id === id)?.name)
      case SectionType.NULL: return '';
      default: return '';
    }
  }
}
