import {Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {HttpClient, HttpResponse} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ApplicationLogsListService {

  private _list$ = new Subject<string[]>();

  constructor(private http: HttpClient) {}

  getList(): Observable<HttpResponse<string[]>> {
    return this.http.get<string[]>('/api/v1/security/application-logs-list', {
      observe: 'response',
      withCredentials: true
    });
  }

  setList(list: string[]) {
    this._list$.next(list);
  }

  get list$(): Subject<string[]> {
    return this._list$;
  }
}
