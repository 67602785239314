<!--<div class="flex flex-col" *ngxPermissionsOnly="['canSeeDeployPage']">-->
<div class="flex flex-col">
    <div class="bg-gray-50 overflow-hidden border border-gray-200 rounded-lg з-">
        <table style="width: -webkit-fill-available;" class="w-full table-fixed divide-y divide-gray-200">
            <thead class="bg-gray-50">
            <tr>
                <th (click)="changeOrderBy('id')" scope="col" class="pl-10 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <ng-container *ngIf="orderByColumn === 'id'"
                        [ngTemplateOutlet]="orderDirection == 'DESC' ? orderDirectionDesc : orderDirectionAsc"
                        [ngTemplateOutletContext]="{columnName:'Id'}">
                    </ng-container>
                    {{orderByColumn !== 'id' ? "Id" : ""}}
                </th>
                <th (click)="changeOrderBy('name')" scope="col" class="pl-10 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <ng-container *ngIf="orderByColumn === 'name'"
                                  [ngTemplateOutlet]="orderDirection == 'DESC' ? orderDirectionDesc : orderDirectionAsc"
                                  [ngTemplateOutletContext]="{columnName:'Name'}">
                    </ng-container>
                    {{orderByColumn !== 'name' ? "Name" : ""}}
                </th>
                <th (click)="changeOrderBy('query')" scope="col" class="pl-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <ng-container *ngIf="orderByColumn === 'query'"
                                  [ngTemplateOutlet]="orderDirection == 'DESC' ? orderDirectionDesc : orderDirectionAsc"
                                  [ngTemplateOutletContext]="{columnName:'Query'}">
                    </ng-container>
                    {{orderByColumn !== 'query' ? "Query" : ""}}
                </th>
                <th (click)="changeOrderBy('redeems_count')" scope="col" class="pl-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <ng-container *ngIf="orderByColumn === 'redeems_count'"
                                  [ngTemplateOutlet]="orderDirection == 'DESC' ? orderDirectionDesc : orderDirectionAsc"
                                  [ngTemplateOutletContext]="{columnName:'Redeems'}">
                    </ng-container>
                    {{orderByColumn !== 'redeems_count' ? "Redeems" : ""}}
                </th>
                <th (click)="changeOrderBy('applied_players')" scope="col" class="pl-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <ng-container *ngIf="orderByColumn === 'applied_players'"
                                  [ngTemplateOutlet]="orderDirection == 'DESC' ? orderDirectionDesc : orderDirectionAsc"
                                  [ngTemplateOutletContext]="{columnName:'Applied Players / Ratio'}">
                    </ng-container>
                    {{orderByColumn !== 'applied_players' ? "Applied Players / Ratio" : ""}}
                </th>
                <th (click)="changeOrderBy('total_players')" scope="col" class="pl-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <ng-container *ngIf="orderByColumn === 'total_players'"
                                  [ngTemplateOutlet]="orderDirection == 'DESC' ? orderDirectionDesc : orderDirectionAsc"
                                  [ngTemplateOutletContext]="{columnName:'Total Players'}">
                    </ng-container>
                    {{orderByColumn !== 'total_players' ? "Total Players" : ""}}
                </th>
                <th (click)="changeOrderBy('state')" scope="col" class="pl-9 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <ng-container *ngIf="orderByColumn === 'state'"
                                  [ngTemplateOutlet]="orderDirection == 'DESC' ? orderDirectionDesc : orderDirectionAsc"
                                  [ngTemplateOutletContext]="{columnName:'State'}">
                    </ng-container>
                    {{orderByColumn !== 'state' ? "State" : ""}}
                </th>
                <th (click)="changeOrderBy('is_active_now')" scope="col" class="pl-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <ng-container *ngIf="orderByColumn === 'is_active_now'"
                                  [ngTemplateOutlet]="orderDirection == 'DESC' ? orderDirectionDesc : orderDirectionAsc"
                                  [ngTemplateOutletContext]="{columnName:'Is Active Time'}">
                    </ng-container>
                    {{orderByColumn !== 'is_active_now' ? "Is Active Time" : ""}}
                </th>
                <th (click)="changeOrderBy('created_at')" scope="col" class="pl-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <ng-container *ngIf="orderByColumn === 'created_at'"
                                  [ngTemplateOutlet]="orderDirection == 'DESC' ? orderDirectionDesc : orderDirectionAsc"
                                  [ngTemplateOutletContext]="{columnName:'Created At'}">
                    </ng-container>
                    {{orderByColumn !== 'created_at' ? "Created At" : ""}}
                </th>
                <th (click)="changeOrderBy('updated_at')" scope="col" class="pl-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <ng-container *ngIf="orderByColumn === 'updated_at'"
                                  [ngTemplateOutlet]="orderDirection == 'DESC' ? orderDirectionDesc : orderDirectionAsc"
                                  [ngTemplateOutletContext]="{columnName:'Updated At'}">
                    </ng-container>
                    {{orderByColumn !== 'updated_at' ? "Updated At" : ""}}
                </th>
            </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
            <tr *ngFor="let deploy of deploys$ | async">
                <td class="pl-10 text-sm text-gray-900">
                    <a href="/deploy/{{deploy.id}}" class="text-blue-600 hover:text-blue-900">{{deploy.id}}</a>
                </td>
                <td class="pl-10 text-sm text-gray-900">
                    {{deploy.name}}
                </td>
                <td class="pl-6 text-sm text-gray-900 justify-center">
                    <svg *ngIf="deploy.isQueryExist" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </td>
                <td class="pl-6 text-sm text-gray-900">
                    {{deploy.redeemsCount}}
                </td>
                <td class="pl-6 text-sm text-gray-900 flex justify-between">
                    <span class="text-sm text-gray-900">{{deploy.totalPlayers === null ? '' : deploy.appliedPlayers}}</span>
                    <span class="text-xs text-blue-300">{{getRatioPlayers(deploy)}}</span>
                </td>
                <td class="pl-6 text-sm text-gray-900">
                    <span class="inline-flex leading-5 rounded-full">{{deploy.totalPlayers === null ? '' : deploy.totalPlayers}}</span>
                </td>
                <td class="pl-6 text-sm text-gray-900">
                    <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-{{getBackgroundColorForDeployState(deploy.state)}}-100 text-{{getBackgroundColorForDeployState(deploy.state)}}-800">{{deploy.state}}</span>
                </td>
<!--                <td class="text-sm text-gray-900">-->
<!--                    <span *ngIf="deploy.state === 'applied'" class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-{{deploy.isActiveTimeNow ? 'green' : 'yellow'}}-100 text-{{deploy.isActiveTimeNow ? 'green' : 'yellow'}}-800">{{deploy.isActiveTimeNow ? 'active' : 'pending'}}</span>-->
<!--                </td>-->
                <th style="padding-left: 3.75rem;" class="pl-6 text-sm text-gray-900">
                    <svg *ngIf="deploy.isActiveTimeNow" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </th>
                <td class="pl-6 text-sm text-gray-900">
                    {{deploy.createdAt}}
                </td>
                <td class="pl-6 text-sm text-gray-900">
                    {{deploy.updatedAt}}
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="mt-4">
        <nav class="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
            <div class="-mt-px w-0 flex-1 flex">
                <a [routerLink]="" queryParamsHandling="preserve" (click)="changePage(currentPage - 1)" *ngIf="currentPage > 1" class="cursor-pointer border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                    <!-- Heroicon name: solid/arrow-narrow-left -->
                    <svg class="mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                    </svg>
                    Попередня сторінка
                </a>
            </div>
            <div *ngFor="let page of pagination" class="hidden md:-mt-px md:flex">
                <a *ngIf="page !== '...'" [routerLink]="" queryParamsHandling="preserve" (click)="changePage(page)" class="{{page === currentPage ? 'border-blue-500 text-blue-600' : 'cursor-pointer border-transparent hover:border-gray-300 hover:text-gray-700'}} text-gray-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium">
                    {{page}}
                </a>
                <span *ngIf="page === '...'" class="border-transparent text-gray-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium">
                  {{page}}
                </span>
            </div>
            <div class="-mt-px w-0 flex-1 flex justify-end">
                <a [routerLink]="" queryParamsHandling="preserve" (click)="changePage(currentPage + 1)" *ngIf="totalPage > 1 && currentPage !== totalPage" class="cursor-pointer border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                    Наступна сторінка
                    <!-- Heroicon name: solid/arrow-narrow-right -->
                    <svg class="ml-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                </a>
            </div>
        </nav>
    </div>
</div>

<ng-template #orderDirectionDesc let-columnName="columnName">
  <div class="group inline-flex">
    <span class="relative top-0.5">{{columnName}}</span>
    <span class="ml-2 flex-none rounded bg-green-200 text-green-800" x-description="Active: &quot;bg-gray-200 text-gray-900 group-hover:bg-gray-300&quot;, Not Active: &quot;invisible text-gray-400 group-hover:visible group-focus:visible&quot;">
      <svg class="h-5 w-5" x-description="Heroicon name: mini/chevron-down" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd"></path>
      </svg>
    </span>
  </div>
</ng-template>

<ng-template #orderDirectionAsc let-columnName="columnName">
  <div class="group inline-flex">
    <span class="relative top-0.5">{{columnName}}</span>
    <span class="ml-2 flex-none rounded bg-green-200 text-green-800" x-description="Active: &quot;bg-gray-200 text-gray-900 group-hover:bg-gray-300&quot;, Not Active: &quot;invisible text-gray-400 group-hover:visible group-focus:visible&quot;">
      <svg class="h-5 w-5" x-description="Heroicon name: mini/chevron-down" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" transform="rotate(180)">
        <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd"></path>
      </svg>
    </span>
  </div>
</ng-template>
