import { Injectable } from '@angular/core';
import {Observable, Subject} from "rxjs";
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from "@angular/common/http";
import {Deploy} from "./deploy";

@Injectable({
  providedIn: 'root'
})
export class DeployListService {

  private _deploys$ = new Subject<Deploy[]>();

  constructor(private http: HttpClient) { }

  getDeployList(from: number, to: number, orderBy: string, orderDirection: string): Observable<HttpResponse<Deploy[]>> {
    return this.http.get<Deploy[]>('/api/v1/ui/deploy-list', {
      headers: new HttpHeaders({
        'Range': 'deploys=' + from + '-' + to,
      }),
      params: {
        from: from.toString(),
        to: to.toString(),
        orderBy: orderBy,
        orderDirection: orderDirection
      },
      observe: 'response',
      withCredentials: true
    });
  }

  setDeploys(deploys: Deploy[]) {
    this._deploys$.next(deploys);
  }

  get deploys$(): Subject<Deploy[]> {
    return this._deploys$;
  }
}
