<div [formGroup]="setForm" (submit)="save()">
  <div>
    <h3 class="font-medium text-gray-900 p-2.5">Сет</h3>
    <div>
      <div class="block w-full">
        <input
          formControlName="uniqueId"
          type="text"
          placeholder="Унікальний ID"
          class="input-text block w-full text-sm text-gray-500 border-none {{getInputStyles(this.setForm.controls.uniqueId)}} focus:ring focus:ring-opacity-50">
      </div>
      <div class="block w-full">
        <input
          formControlName="name"
          type="text"
          placeholder="Унікальна назва"
          class="input-text block w-full text-sm text-gray-500 border-none {{getInputStyles(this.setForm.controls.name)}} focus:ring focus:ring-opacity-50">
      </div>
    </div>
  </div>
  <div class="flex">
    <button *ngIf="!setService.editableSet" type="submit" (click)="save()" class="flex-1 mt-2 py-2 px-4 text-sm font-semibold rounded-lg {{this.setForm.valid ? 'bg-yellow-100 text-yellow-700 hover:bg-yellow-200' : 'bg-gray-300 text-white'}}">В очікування</button>
    <button *ngIf="setService.editableSet" type="submit" (click)="update()" class="flex-1 mt-2 py-2 px-4 text-sm font-semibold rounded-lg {{this.setForm.valid ? 'bg-yellow-100 text-yellow-700 hover:bg-yellow-200' : 'bg-gray-300 text-white'}}">Змінити</button>
    <button type="button" (click)="reset()" class="flex-1 mt-2 ml-3 py-2 px-4 text-sm text-white font-semibold rounded-lg {{this.setForm.dirty ? 'bg-red-300 hover:bg-red-400' : 'bg-gray-300'}}">Відмінити</button>
  </div>
</div>
