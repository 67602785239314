import {Component, OnInit} from '@angular/core';
import {NotificationsService} from "angular2-notifications";
import {Router} from "@angular/router";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpErrorResponse} from "@angular/common/http";
import {PageTypeEnum} from "../../common/page";
import {CheatSaveService} from "./cheat-save.service";
import {CheatSave} from "../cheat-save-list-modal/cheat-save";
import {SaveListService} from "../save-list/save-list.service";
import {validate} from "uuid";

@Component({
  selector: 'app-cheat-save',
  templateUrl: './cheat-save.component.html',
  styleUrls: ['./cheat-save.component.scss']
})
export class CheatSaveComponent implements OnInit {
  public uploadedFile: File|null = null;

  constructor(
    private notificationsService: NotificationsService,
    private cheatSaveService: CheatSaveService,
    private saveListService: SaveListService,
    private router: Router,
  ) {
    // this.cheatSaveForm.controls.content.addValidators(this.requiredFileType('msgpack'));
  }

  ngOnInit(): void {
    const currentUrl = this.router.url;

    const match = currentUrl.match(/\/(\d+)\//);
    if (match && match.length > 1) {
      const id = parseInt(match[1], 10);
      this.fetchCheatSave(id);
    } else {
      console.log('No cheat save id found in the URL');
    }

    this.cheatSaveForm.get('playerId')?.valueChanges.subscribe((playerId: string) => {
        const playerSaveAppVersion = this.saveListService.listedPlayerIdAppVersionMap.get(playerId)

        if (playerSaveAppVersion) {
          this.cheatSaveForm.get('appVersion')?.patchValue(playerSaveAppVersion);
        } else if (validate(playerId)) {
          this.saveListService.getPlayerSaveListByDay(playerId).subscribe(
            (data) => {
              if (data.status === 200 || data.status === 204) {
                if (data.body) {
                  if (data.body.cached) {
                    this.cheatSaveForm.get('appVersion')?.patchValue(data.body.cached.appVersion);
                  } else if (data.body.db.length > 0) {
                    this.cheatSaveForm.get('appVersion')?.patchValue(data.body.db[0].appVersion);
                  }
                }
              }
            }
          )
        }
    });
  }

  public editingCheatSave: CheatSave|null = null;

  cheatSaveForm = new FormGroup({
    saveName:   new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(128)]),
    appVersion: new FormControl(null, [Validators.required, Validators.maxLength(32)]),
    playerId:   new FormControl(null, [Validators.required, Validators.minLength(36), Validators.maxLength(36)]),
    // content:    new FormControl(null),
  });

  // onFileUploadBtn($event: any) {
  //   const file = $event.target?.files[0] as File;
  //   this.onFileReceive(file);
  // }
  //
  // onFileReceive(file: File) {
  //   this.uploadedFile = file;
  //   this.cheatSaveForm.controls.content.setValue(this.uploadedFile);
  // }
  //
  // @HostListener('dragover', ['$event'])
  // onDragOver(event: DragEvent) {
  //   event.preventDefault();
  //   event.stopPropagation();
  // }
  //
  // @HostListener('dragleave', ['$event'])
  // onDragLeave(event: DragEvent) {
  //   event.preventDefault();
  //   event.stopPropagation();
  // }
  //
  // @HostListener('drop', ['$event'])
  // onDrop(event: DragEvent) {
  //   event.preventDefault();
  //   event.stopPropagation();
  //
  //   const file = (event.dataTransfer?.files || [])[0];
  //   if (file) {
  //     this.onFileReceive(file);
  //   }
  // }
  //
  // getFileInputColor() {
  //   if (this.cheatSaveForm.controls.content.value == null) {
  //     return 'gray';
  //   }
  //
  //   if (this.cheatSaveForm.controls.content.invalid) {
  //     return 'red';
  //   }
  //
  //   return 'green';
  // }

  getInputStyles(control: AbstractControl): string {
    if (control.dirty && control.invalid) {
      return 'focus:border-red-300 focus:ring-red-200 bg-red-50';
    }

    if (control.dirty && control.valid) {
      return 'focus:border-green-300 focus:ring-green-200 bg-green-50';
    }

    return 'focus:border-blue-300 focus:ring-blue-200';
  }

  // requiredFileType(type: string): ValidatorFn {
  //   return (control: AbstractControl): ValidationErrors | null => {
  //     const file = control.value as File;
  //
  //     if (file && file instanceof File) {
  //       const fileName = file.name.toLowerCase();
  //       const isValidExtension = fileName.endsWith(`.${type.toLowerCase()}`);
  //
  //       if (isValidExtension) {
  //         return null;
  //       } else {
  //         this.notificationsService.error('Помилка!', 'Файл має бути формату .msgpack', {
  //           timeOut: 5000,
  //           showProgressBar: true,
  //           pauseOnHover: true,
  //           clickToClose: true
  //         });
  //
  //         return { invalidFileType: { value: control.value } };
  //       }
  //     }
  //
  //     return null;
  //   };
  // }

  removeRequiredValidatorOnPlayerIdWhenEditing() {
    this.cheatSaveForm.get('playerId')?.setValidators([Validators.minLength(36), Validators.maxLength(36)]);
    this.cheatSaveForm.get('playerId')?.updateValueAndValidity();
  }

  fetchCheatSave(id: number): void {
    this.cheatSaveService.getCheatSave(id).subscribe(
      (data) => {
        if (data.body !== null) {
          const saveData = atob(data.body.saveData) // base64 decode

          this.editingCheatSave = {
            id: data.body.id,
            saveName: data.body.saveName,
            appVersion: data.body.appVersion,
            createdAt: data.body.createdAt,
            updatedAt: data.body.updatedAt,
            saveData: saveData
          } as CheatSave

          this.cheatSaveForm.patchValue({
            saveName: data.body.saveName,
            appVersion: data.body.appVersion,
            createdAt: data.body.createdAt,
            updatedAt: data.body.updatedAt,
            // content: saveData
          })

          this.removeRequiredValidatorOnPlayerIdWhenEditing()
        }
      }, (data) => {
        if (data instanceof HttpErrorResponse) {
          if (data.status < 500) {
            this.router.navigate([PageTypeEnum.CHEAT_SAVE_CREATE])
            this.notificationsService.error('Помилка!', data.error, {
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            });
          } else {
            this.router.navigate([PageTypeEnum.CHEAT_SAVE_CREATE])
            this.notificationsService.error('Помилка!', data.error.detail, {
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            });
          }
        } else {
          this.router.navigate([PageTypeEnum.CHEAT_SAVE_CREATE])
          this.notificationsService.error('Помилка!', "Невдалося отримати чит сейв", {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
          });
        }
      });
  }

  isEditingCheatSaveNotEqualToFormValues(): boolean
  {
    if (this.cheatSaveForm.value.saveName === this.editingCheatSave?.saveName
      && this.cheatSaveForm.value.appVersion === this.editingCheatSave?.appVersion
      && (this.cheatSaveForm.value.playerId === null || this.cheatSaveForm.value.playerId === '')
      // && this.cheatSaveForm.value.content === this.editingCheatSave.saveData
    ) {
      return false
    }

    return true;
  }

  save() {
    if (!confirm('Ви впевнені що хочете зберегти даний чит сейв?')) {
      return;
    }

    if (!this.cheatSaveForm.invalid) {
      this.cheatSaveService.saveCheatSave(
        this.cheatSaveForm.value.saveName,
        this.cheatSaveForm.value.appVersion,
        this.cheatSaveForm.value.playerId,
        // this.cheatSaveForm.value.content,
      ).subscribe(
        (data) => {
          if (data.status === 201) {
            this.notificationsService.success('Успіх!', 'Чит сейв додано', {
              timeOut: 1500,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            });

            if (this.editingCheatSave !== null) {
              this.editingCheatSave = null;
              this.router.navigate([PageTypeEnum.CHEAT_SAVE_CREATE])
            } else {
              window.location.reload();
            }
            this.reset();
          }
        }, (data) => {
          if (data instanceof HttpErrorResponse) {
            if (data.status < 500) {
              this.notificationsService.error('Помилка!', data.error, {
                timeOut: 5000,
                showProgressBar: true,
                pauseOnHover: true,
                clickToClose: true
              });
            } else {
              this.notificationsService.error('Помилка!', data.error.detail, {
                timeOut: 5000,
                showProgressBar: true,
                pauseOnHover: true,
                clickToClose: true
              });
            }
          } else {
            this.notificationsService.error('Помилка!', "Невдалося зберегти чит сейв", {
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            });
          }
        }
      );
    }
  }

  update() {
    if (this.editingCheatSave === null) {
      return;
    }

    if (!confirm('Ви впевнені що хочете оновити даний чит сейв?')) {
      return;
    }

    if (!this.cheatSaveForm.invalid) {
      this.cheatSaveService.updateCheatSave(
        this.editingCheatSave.id,
        this.cheatSaveForm.value.saveName,
        this.cheatSaveForm.value.appVersion,
        this.cheatSaveForm.value.playerId,
        // this.cheatSaveForm.value.content,
      ).subscribe(
        (data) => {
          if (data.status === 200) {
            this.notificationsService.success('Успіх!', 'Чит сейв оновлено', {
              timeOut: 1500,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            });

            if (this.editingCheatSave !== null) {
              this.editingCheatSave = null;
              this.router.navigate([PageTypeEnum.CHEAT_SAVE_CREATE])
            } else {
              window.location.reload();
            }
            this.reset();
          }
        }, (data) => {
          if (data instanceof HttpErrorResponse) {
            if (data.status < 500) {
              this.notificationsService.error('Помилка!', data.error, {
                timeOut: 5000,
                showProgressBar: true,
                pauseOnHover: true,
                clickToClose: true
              });
            } else {
              this.notificationsService.error('Помилка!', data.error.detail, {
                timeOut: 5000,
                showProgressBar: true,
                pauseOnHover: true,
                clickToClose: true
              });
            }
          } else {
            this.notificationsService.error('Помилка!', "Невдалося оновити чит сейв", {
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            });
          }
        }
      );
    }
  }

  reset() {
    this.uploadedFile = null;

    if (this.editingCheatSave) {
      this.cheatSaveForm.reset({
        saveName: this.editingCheatSave.saveName,
        appVersion: this.editingCheatSave.appVersion,
        createdAt: this.editingCheatSave.createdAt,
        updatedAt: this.editingCheatSave.updatedAt,
        // content: this.editingCheatSave.saveData,
      });

      return
    }

    this.cheatSaveForm.reset({
      saveName: null,
      appVersion: null,
      playerId: null,
      // content: null,
    });
  }
}
