import {Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {Deploy, DeployState} from "./deploy";

@Injectable({
  providedIn: 'root'
})
export class DeployService {

  private _deploy$ = new Subject<Deploy>();

  constructor(private http: HttpClient) { }

  getDeploy(id: number): Observable<HttpResponse<Deploy>> {
    return this.http.get<Deploy>('/api/v1/ui/deploy/' + id, {
      observe: 'response',
      withCredentials: true,
      headers: new HttpHeaders(
        {
          'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
          'Pragma': 'no-cache',
          'Expires': '0'
        })
    });
  }

  replaceDeployRedeems(id: number, redeems: any): Observable<HttpResponse<any>> {
    return this.http.put<any>('/api/v1/ui/deploy/' + id + '/replace-redeems', redeems, {
      observe: 'response',
      withCredentials: true
    });
  }

  replaceDeployDetails(id: number, details: any): Observable<HttpResponse<any>> {
    return this.http.put<any>('/api/v1/ui/deploy/' + id + '/replace-details', details, {
      observe: 'response',
      withCredentials: true
    });
  }

  replaceDeployActiveTime(id: number, activeTime: any): Observable<HttpResponse<any>> {
    return this.http.put<any>('/api/v1/ui/deploy/' + id + '/replace-active-time', activeTime, {
      observe: 'response',
      withCredentials: true
    });
  }

  changeDeployState(id: number, state: DeployState): Observable<HttpResponse<any>> {
    return this.http.post<any>('/api/v1/ui/deploy/' + id + '/' + state.toString(), {}, {
      observe: 'response',
      withCredentials: true
    });
  }

  addTestPlayer(deployId: number, playerId: string): Observable<HttpResponse<any>> {
    return this.http.post<any>('/api/v1/ui/deploy/' + deployId  +'/add-test-player/' + playerId, {}, {
      observe: 'response',
      withCredentials: true
    });
  }

  retestPlayer(deployId: number, playerId: string): Observable<HttpResponse<any>> {
    return this.http.post<any>('/api/v1/ui/deploy/' + deployId +'/retest-player/' + playerId, {}, {
      observe: 'response',
      withCredentials: true
    });
  }

  // revertDeploy(id: number, conflictedSectionsToReverting: ConflictedSectionsToReverting[]|null = null): Observable<HttpResponse<any>> {
  //   return this.http.post<any>('/api/v1/deploy/' + id + '/revert', conflictedSectionsToReverting ? conflictedSectionsToReverting : {}, {
  //     observe: 'response',
  //     withCredentials: true
  //   });
  // }

  setDeploy(deploy: Deploy) {
    this._deploy$.next(deploy);
  }

  get deploy$(): Subject<Deploy> {
    return this._deploy$;
  }
}
