<div class="flex flex-col" *ngIf="user$ | async as user">
  <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div class="py-2 align-middle inline-block min-w-full lg:px-8">
      <div class="overflow-hidden border border-gray-200 sm:rounded-lg">
        <table class="table-fixed divide-y divide-gray-200">
          <thead class="bg-gray-50">
          <tr>
            <th scope="col" class="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Лог користувача {{user.username}}
            </th>
          </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
          <tr *ngFor="let log of user.userLogs;">
            <td class="px-6 py-4 text-sm text-gray-900">
              {{log}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
