import {Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {SectionKeyMapping} from "../section-key-mapping";

@Injectable({
  providedIn: 'root'
})
export class SectionsKeyMappingService {
  private _mappedSections$ = new Subject<SectionKeyMapping[]>();

  constructor(private http: HttpClient) {}

  getSectionKeyMappingList(): Observable<HttpResponse<SectionKeyMapping[]>> {
    return this.http.get<SectionKeyMapping[]>('/api/v1/section-key-mapping-list', {
      observe: 'response',
      withCredentials: true
    });
  }

  createSectionKeyMapping(mappedSectionsName: string, sectionsValueId: number, mappedSectionsDescription: string|null = null): Observable<HttpResponse<any>> {
    return this.http.post<any>('/api/v1/section-key-mapping-create', {
      mappedSectionsName: mappedSectionsName,
      sectionsValueId: sectionsValueId,
      mappedSectionsDescription: mappedSectionsDescription,
    }, {
      observe: 'response',
      withCredentials: true
    });
  }

  setMappedSections(mappedSections: SectionKeyMapping[]) {
    this._mappedSections$.next(mappedSections);
  }

  get mappedSections$(): Subject<SectionKeyMapping[]> {
    return this._mappedSections$;
  }
}
