<div class="space-y-6 pt-4 pb-16">
  <div>
    <dl class="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200 text-center">
      <div (click)="renovationService.activateSetForm()" class="{{renovationService.isActiveSetCreationForm ? 'bg-blue-50 text-blue-500' : 'text-gray-500 hover:text-white hover:bg-gray-100'}} py-2.5 text-sm cursor-pointer">
        <dt class="text-gray-500">Додати сет</dt>
      </div>
      <div (click)="renovationService.activateChunkForm()" class="{{renovationService.isActiveChunkCreationForm ? 'bg-blue-50 text-blue-500' : 'text-gray-500 hover:text-white hover:bg-gray-100'}} py-2.5 text-sm cursor-pointer">
        <dt class="text-gray-500">Додати чанк</dt>
      </div>
      <div (click)="renovationService.activateMapElementForm()" class="{{renovationService.isActiveMapElementCreationForm ? 'bg-blue-50 text-blue-500' : 'text-gray-500 hover:text-white hover:bg-gray-100'}} py-2.5 text-sm cursor-pointer">
        <dt class="text-gray-500">Додати елемент мапи</dt>
      </div>
      <div (click)="renovationService.activateSkinForm()" class="{{renovationService.isActiveSkinCreationForm ? 'bg-blue-50 text-blue-500' : 'text-gray-500 hover:text-white hover:bg-gray-100'}} py-2.5 text-sm cursor-pointer">
        <dt class="text-gray-500">Додати скін</dt>
      </div>
    </dl>
  </div>

  <div *ngIf="renovationService.isActiveSetCreationForm">
    <app-set></app-set>
  </div>
  <div *ngIf="renovationService.isActiveChunkCreationForm">
    <app-chunk></app-chunk>
  </div>
  <div *ngIf="renovationService.isActiveMapElementCreationForm">
    <app-map-element></app-map-element>
  </div>
  <div *ngIf="renovationService.isActiveSkinCreationForm">
    <app-skin></app-skin>
  </div>
</div>
