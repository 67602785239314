import {Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {Log} from "../log";

@Injectable({
  providedIn: 'root'
})
export class ApplicationLogService {
  private _logs$ = new Subject<Log[]>();

  constructor(private http: HttpClient) {}

  getLog(filename: string, requestId: string): Observable<HttpResponse<Log[]>> {
    return this.http.get<Log[]>('/api/v1/security/application-logs/' + filename + '/' + requestId, {
      observe: 'response',
      withCredentials: true
    });
  }

  setLog(logs: Log[]) {
    this._logs$.next(logs);
  }

  get logs$(): Subject<Log[]> {
    return this._logs$;
  }
}
