import { Component, OnInit } from '@angular/core';
import {RenovationService} from "../renovation/renovation.service";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {ChunkService} from "./chunk.service";
import {ChunkListService} from "../chunk-list/chunk-list.service";
import {HttpErrorResponse} from "@angular/common/http";
import {NotificationsService} from "angular2-notifications";
import {allowedCharactersValidatorForUniqueId} from "../../../form-validators/validators";
import {RenovationListService} from "../renovation-list/renovation-list.service";

@Component({
  selector: 'app-chunk',
  templateUrl: './chunk.component.html',
  styleUrls: ['./chunk.component.scss']
})
export class ChunkComponent implements OnInit {

  chunkForm = new FormGroup({
    uniqueId:            new FormControl(null, [Validators.required, Validators.maxLength(128), allowedCharactersValidatorForUniqueId()]),
    expForAccessibility: new FormControl(null, [Validators.required, Validators.min(0)]),
  });

  constructor(
    private notificationsService: NotificationsService,
    private renovationListService: RenovationListService,
    private chunkListService: ChunkListService,
    public chunkService: ChunkService,
    public renovationService: RenovationService,
  ) {}

  ngOnInit(): void {
    if (this.chunkService.editableChunk) {
      if (!this.chunkForm.dirty) {
        this.chunkForm.reset({
          uniqueId: this.chunkService.editableChunk.uniqueId,
          expForAccessibility: this.chunkService.editableChunk.expForAccessibility,
        });
        this.chunkForm.markAsDirty()
      }
    }
  }

  save() {
    if (this.chunkForm.valid) {
      const chunks = this.chunkService.createChunk(
        this.chunkForm.controls['uniqueId'].value,
        this.chunkForm.controls['expForAccessibility'].value,
      );

      this.chunkService.savePendingChunk(chunks).subscribe(
        (data) => {
          if (data.status === 201) {
            this.chunkListService.addPendingChunk(chunks)
            this.reset()
          }
        }, (data) => {
          if (data instanceof HttpErrorResponse) {
            if (data.status < 500) {
              this.notificationsService.error('Помилка!', data.error, {
                timeOut: 5000,
                showProgressBar: true,
                pauseOnHover: true,
                clickToClose: true
              });
            } else {
              this.notificationsService.error('Помилка!', data.error.detail, {
                timeOut: 5000,
                showProgressBar: true,
                pauseOnHover: true,
                clickToClose: true
              });
            }
          } else {
            this.notificationsService.error('Помилка!', "Невдалося додати чанк в очікування", {
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            });
          }
        }
      );
    }
  }

  update(): void {
    if (this.chunkService.editableChunk && this.chunkForm.valid) {
      const modifiedChunk = {
        ...this.chunkService.editableChunk,
        uniqueId: this.chunkForm.controls.uniqueId.value,
        expForAccessibility: this.chunkForm.controls.expForAccessibility.value,
      };

      this.chunkService.updateChunk(modifiedChunk).subscribe(
        (data) => {
          if (data.status === 200) {
            this.notificationsService.success('Успіх!', 'Чанк змінено', {
              timeOut: 1500,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            });
            this.renovationListService.fetchAndLoadSkinRenovations()
            this.reset()
          }
        }, (data) => {
          if (data instanceof HttpErrorResponse) {
            if (data.status < 500) {
              this.notificationsService.error('Помилка!', data.error, {
                timeOut: 5000,
                showProgressBar: true,
                pauseOnHover: true,
                clickToClose: true
              });
            } else {
              this.notificationsService.error('Помилка!', data.error.detail, {
                timeOut: 5000,
                showProgressBar: true,
                pauseOnHover: true,
                clickToClose: true
              });
            }
          } else {
            this.notificationsService.error('Помилка!', "Невдалося оновити чанк", {
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            });
          }
        }
      );
    }
  }

  reset() {
    if (this.chunkForm.dirty) {
      this.chunkService.editableChunk = null
      this.chunkForm.reset({
        uniqueId: null,
        expForAccessibility: null,
      });
    }
  }

  getInputStyles(control: AbstractControl): string {
    if (control.dirty && control.invalid) {
      return 'focus:border-red-300 focus:ring-red-200 bg-red-50';
    }

    if (control.dirty && control.valid) {
      return 'focus:border-green-300 focus:ring-green-200 bg-green-50';
    }

    return 'focus:border-blue-300 focus:ring-blue-200';
  }
}
