import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  activeRequests: number = 0;
  countActiveRequests$ = new Subject<number>();

  constructor() {
    this.countActiveRequests$.subscribe((value) => {
      this.activeRequests = value;
    });
  }

  addRequestLoading() {
    this.countActiveRequests$.next(this.activeRequests + 1);
  }

  removeRequestLoading() {
    this.countActiveRequests$.next(this.activeRequests - 1);
  }

  isActiveLoader(): boolean {
    return this.activeRequests > 0
  }
}
